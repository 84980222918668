import classes from './index.module.css';
import React, { useContext, useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import { AuthContext } from '../../../context/AuthContext';
import { LoaderContext } from '../../../context/LoaderContext';

import { FAULTY_STATUS, PRICE_SIGNAL_DROPDOWN, START_LOADER, STOP_LOADER, USER_ROLE } from '../../../constants';
import Bids from './Bids/Bids';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';
import TotalCapacity from './TotalCapacity';

const Bidding = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        customer: queryParams.get('customer') ? JSON.parse(queryParams.get('customer')) : '',
        license: queryParams.get('license') ? JSON.parse(queryParams.get('license')) : '',
        groups: queryParams.get('groups') ? JSON.parse(queryParams.get('groups')) : '',
        filter: queryParams.get('filter') ? JSON.parse(queryParams.get('filter')) : '',
        bidModal: queryParams.get('bidModal') && queryParams.get('bidModal') === 'true' ? Boolean(queryParams.get('bidModal')) : false,
        stateModal: queryParams.get('stateModal') && queryParams.get('stateModal') === 'true' ? Boolean(queryParams.get('stateModal')) : false,
        bidDate: queryParams.get('bidDate') ? new Date(queryParams.get('bidDate')) : '',
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
    });

    const { state } = useContext(AuthContext);
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [editDevice, setEditDevice] = useState({});
    const [refresh, setRefresh] = useState({});
    const [prefillGroup, setPrefillGroup] = useState('');
    const [device, setDevice] = useState({
        name: '',
        uId: '',
    });

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    const handleError = (err) => {
        console.log(err);
    };

    let tabs = [
        {
            name: 'FCR-N Bids',
            component: (
                <>
                    <Bids updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        {
            name: 'Total Capacity',
            component: <TotalCapacity queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={editDevice.uId || prefillGroup ? 3 : 0}
                resetIndex={device.name}
                setEditDevice={setEditDevice}
                setPrefillGroup={setPrefillGroup}
                tabs={tabs}
            />
        </div>
    );
};

export default Bidding;
