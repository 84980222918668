// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
// Internal module imports
import Typography from '../../../../../../components/Typography/Typography';
import classes from '../../../../../../styles/BidModal.module.css';
import myClasses from './index.module.css';
import { Input } from '../../../../../../components/Inputs/Input';
import { MobilepayService } from '../../../../../../services/mobilepayService';
import { useLoader } from '../../../../../../hooks';

const EditModal = ({ setCreateModal, editData, setRefresh }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        let processedData = {
            ...editData.properties,
        };
        const arrayKey = ['restrictions', 'paymentExpiresAt', 'paymentId', 'MerchantId', 'orderId', 'posId'];
        arrayKey.forEach((key) => {
            delete processedData[key];
        });
        setInitialValues(processedData);
    }, [editData]);

    const handleSubmit = (values) => {
        console.log({ editData });
        MobilepayService.Update(editData?._doc?._id, values, startLoader, handleUpdateSuccess, handleCreateError, stopLoader);
    };

    const handleUpdateSuccess = (res) => {
        if (res) {
            setCreateModal({ status: false, data: {} });
            setRefresh && setRefresh();
            toast.success('Transaction Updated sucessfully');
        }
    };
    const handleCreateError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <Typography content={'Update Transaction'} />
            <Formik enableReinitialize initialValues={initialValues || {}} onSubmit={handleSubmit}>
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div className={myClasses.FormConatiner}>
                            {Object.keys(initialValues).map((el, key) => (
                                <div key={key}>
                                    <div className={classes.FieldControl}>
                                        <label for={el}>{el}</label>
                                        <Input name={el} id={el} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditModal;
