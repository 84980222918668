import React, { useState } from 'react';
import Client from './Client/Client';
import ContentManager from './ContentManager/ContentManager';
import TabsComponent from '../../../components/Tabs/Tabs';
import VersionHistory from './VersionHistory';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';
import DataHub from './DataHub/DataHub';

// Internal module imports
import Documents from '../Documents/Documents';
import ShipmentAssets from './ShipmentAssets/ShipmentAssets';

const Index = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        application: queryParams.get('application') ? queryParams.get('application') : '',
        assetModal: queryParams.get('assetModal') && queryParams.get('assetModal') === 'true' ? Boolean(queryParams.get('assetModal')) : false,
        addKeyModal: queryParams.get('addKeyModal') && queryParams.get('addKeyModal') === 'true' ? Boolean(queryParams.get('addKeyModal')) : false,
        showExportModal: queryParams.get('showExportModal') && queryParams.get('showExportModal') === 'true' ? Boolean(queryParams.get('showExportModal')) : false,
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        device: queryParams.get('device') ? JSON.parse(queryParams.get('device')) : '',
    });
    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    let tabs = [
        {
            name: 'Documents',
            component: (
                <>
                    <Documents />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Version Release',
            component: (
                <>
                    <VersionHistory queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Client Logos',
            component: (
                <>
                    <Client />
                </>
            ),
            tabId: 3,
        },
        {
            name: 'Content Manager',
            component: (
                <>
                    <ContentManager />
                </>
            ),
            tabId: 4,
        },
        {
            name: 'Data Hub',
            component: (
                <>
                    <DataHub queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 5,
        },
        {
            name: 'Shipment Assets',
            component: (
                <>
                    <ShipmentAssets queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 6,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Index;
