import React from 'react';
import { useLoader } from '../../../../hooks';
import DMinusOneFCRD from '../../../Bidding/FCR-D/Bids/ModalContent/DMinusOne';
import DMinusOneFCRDDEC from '../../../Bidding/FCR-DMinus/Bids/ModalContent/DMinusOne';

export const CreateD1Bid = ({ market, bid, fetchBids, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    console.log({ bid });
    const renderComponent = () => {
        switch (market) {
            case 'FCR-D-INC':
                return <DMinusOneFCRD bid={bid} startLoader={startLoader} stopLoader={stopLoader} setOpen={setOpen} fetchBids={fetchBids} />;
            case 'FCR-D-DEC':
                return <DMinusOneFCRDDEC bid={bid} startLoader={startLoader} stopLoader={stopLoader} setOpen={setOpen} fetchBids={fetchBids} />;
            default:
                return <>Market Does Not Support D-1 Bidding</>;
        }
    };
    return <div>{renderComponent()}</div>;
};
