// Standard Library Imports
import React, { useEffect, useState } from 'react';

// External Library Imports
import moment from 'moment-timezone';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal Module Imports
import { Input } from '../../../../../components/Inputs/Input';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import Typography from '../../../../../components/Typography/Typography';
import { ForecastService } from '../../../../../services/ForecastService';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { FcrDMinusBidService } from '../../../../../services/FcrDMinusBidService';
import { CreateFcrDMinusBidValidation } from '../../../../../validations/Frequency/CreateBidValidation';

// CSS Imports
import classes from '../../../../../styles/BidModal.module.css';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const getSlotAndPriceInitials = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((_, index) => {
        slotAndPrice[`slot${index + 1}`] = '0.9';
    });
    dummyArray.forEach((_, index) => {
        slotAndPrice[`slot${index + 1}Price`] = '14';
    });
    return slotAndPrice;
};

const BidContent = ({ setOpen, startLoader, stopLoader, fetchBids, customerId, bidDate, group, updateQueryParams }) => {
    const [initialValues, setInitialValues] = useState({
        slotAndPrice: getSlotAndPriceInitials(),
        groupId: group.label,
        reserveBidIdentification: '',
    });
    const [date, setDate] = useState(bidDate ? new Date(moment(bidDate).format('YYYY-MM-DD')) : new Date());
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);

    useEffect(() => {
        updateQueryParams('bidDate', date);
    }, [date]);

    useEffect(() => {
        if (customerId) {
            FcrDMinusBidService.GetLastBid({ customerId, groupId: group.value }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    }, []);

    useEffect(() => {
        if (customerId) {
            fetchMaintenanceHourForADate();
        }
    }, [date]);

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity, date, price, reserveBidIdentification } = data.data;
        if (capacity?.length && price?.length) {
            const slotAndPrice = {};
            capacity.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}`] = item;
            });
            price.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}Price`] = item;
            });
            setInitialValues({
                ...initialValues,
                slotAndPrice,
                reserveBidIdentification,
            });
        } else {
            setInitialValues({
                slotAndPrice: getSlotAndPriceInitials(),
                groupId: group.label,
                reserveBidIdentification: '',
            });
        }
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours() + 25);
        if (!bidDate) {
            setDate(tempDate);
        }
    };

    const fetchProposedPrices = () => {
        FcrDMinusBidService.GetBiddingToolData(startLoader, handleDataSuccess, handleError, stopLoader);
    };

    const handleDataSuccess = ({ data }) => {
        const prices = {};
        data?.data?.data?.forEach((item, index) => {
            prices[`slot${index + 1}Price`] = item.proposed;
        });
        setInitialValues({ ...initialValues, ...prices });
    };

    const handleSubmit = (values) => {
        let params = {
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            customerId,
        };
        values.groupId = group.value;
        let payload = { bid: values };
        FcrDMinusBidService.Create(params, payload, startLoader, (res) => handleCreateSuccess(res), handleError, stopLoader);
    };

    const handleCreateSuccess = (res) => {
        toast.success('Bid Created!');
        const currentDate = moment();
        if (group?.approvalValidity?.endDate) {
            if (moment(group?.approvalValidity?.endDate).diff(currentDate, 'days') <= 30) {
                toast.warn(`Approval expires on ${momentTimeFormater(group.approvalValidity.endDate).format('YYYY-MM-DD')}`);
            }
        }
        fetchBids();
        setOpen(false);
    };

    const GetBidRequestForADate = () => {
        FcrDMinusBidService.GetBidRequestForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            startLoader,
            handlebidRequestSuccess,
            handleError,
            stopLoader
        );
    };

    const fetchMaintenanceHourForADate = () => {
        FcrDMinusBidService.GetMaintenanceHoursForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            () => startLoader('maintenance'),
            handleMaintenanceSuccess,
            handleError,
            () => stopLoader('maintenance')
        );
    };

    const handleMaintenanceSuccess = ({ data }) => {
        if (data.data?.hourSlots) setMaintenanceSlots(data.data?.hourSlots);
        else setMaintenanceSlots([]);
    };

    const handlebidRequestSuccess = ({ data }) => {
        if (data.data) {
            const { price = [], capacity = [] } = data.data;
            const slotAndPrice = {};
            price.forEach((val, index) => (slotAndPrice[`slot${index + 1}Price`] = val));
            capacity.forEach((val, index) => (slotAndPrice[`slot${index + 1}`] = val));

            setInitialValues({
                ...initialValues,
                slotAndPrice,
            });
        } else {
            toast.error('No request found');
        }
    };

    const fetchForecast = () => {
        const tempDate = moment(date).tz('Europe/Berlin').format('YYYY-MM-DD');
        const params = {
            instanceIds: group.value,
            fromDate: momentTimeFormater(tempDate).utc().format(),
            toDate: momentTimeFormater(tempDate).add(23, 'hours').utc().format(),
            percentiles: '50',
        };
        ForecastService.GetForecast(params, startLoader, handleForecastSuccess, handleError, stopLoader);
    };

    const handleForecastSuccess = ({ data }) => {
        const forecastData = data.data?.[group.value]?.forecastData || [];
        if (forecastData.length) {
            const hourlyCapacityArray = Array(24).fill(0);
            const kwTomwConverterValue = 1000;
            forecastData.map((item) => {
                const hour = momentTimeFormater(moment.unix(item.epoch)).get('hour');
                hourlyCapacityArray[hour] = item.percentile50 / kwTomwConverterValue;
            });
            const tempCapacity = {};
            hourlyCapacityArray.forEach((val, index) => (tempCapacity[`slot${index + 1}`] = val));
            const slotAndPrice = { ...initialValues.slotAndPrice, ...tempCapacity };
            console.log(slotAndPrice);
            setInitialValues({
                ...initialValues,
                slotAndPrice,
            });
        } else {
            toast.error('Forecast not available');
        }
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div>
            <Typography content="FCR-D Decreased Bidding" size="16" />
            <div className={classes.Subtitle} style={{ margin: 0 }}>
                Red-bordered hour slots signify customer-requested maintenance
                <span className="required">*</span>
            </div>
            <div className={classes.FormContainer2}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateFcrDMinusBidValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={classes.Subtitle}>Capacity(Mw)</div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '0.5vw',
                                        }}
                                    >
                                        {group.isForecastConfigAvailable && (
                                            <button type="button" className="btn-primary" onClick={fetchForecast}>
                                                Fill ( Forecast )
                                            </button>
                                        )}
                                        <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                            Fill ( Bid Request )
                                        </button>
                                    </div>
                                </div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}`}>
                                                                Slot {index * 4 + subIndex + 1} <span className="required">*</span>
                                                            </label>
                                                            <Input
                                                                name={`slotAndPrice.slot${index * 4 + subIndex + 1}`}
                                                                id={`slot${index * 4 + subIndex + 1}`}
                                                                style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <div
                                    className={classes.Subtitle}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>Price(€)</span>
                                    <div style={{ margin: '0' }} className={classes.ButtonContainer}>
                                        <button type={'button'} onClick={fetchProposedPrices} className="btn-primary" style={{ margin: '0' }}>
                                            Proposed Prices
                                        </button>
                                    </div>
                                </div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}Price`}>
                                                                Slot {index * 4 + subIndex + 1} Price <span className="required">*</span>
                                                            </label>
                                                            <Input
                                                                name={`slotAndPrice.slot${index * 4 + subIndex + 1}Price`}
                                                                id={`slot${index * 4 + subIndex + 1}Price`}
                                                                style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label>
                                            Date <span className="required">*</span>
                                        </label>
                                        <div className="modal-date-picker">
                                            <DatePicker date={date} setDate={setDate} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="reserveBidIdentification">
                                                Reserve Bid Identification
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="groupId">Group</label>
                                            <Input name="groupId" id="groupId" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default BidContent;
