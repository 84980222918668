// Standard library imports
import React, { useEffect, useState } from 'react';

// External module imports
import moment from 'moment-timezone';
import { mean as LodashMean, minBy as LodashMin, maxBy as LodashMax } from 'lodash';
import { UilTrash, UilInfoCircle, UilImport, UilBolt, UilCapture, UilCopy, UilAlignCenterH } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// Css imports
import classes from '../../../../styles/Bids.module.css';
import allDeviceClasses from '../../../../styles/AllDevices.module.css';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import Table from '../../../../components/Table/Table';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import BidContent from './ModalContent/BidContent';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import BiddingInfoContent from './ModalContent/BiddingInfoContent';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import Status from './ModalContent/Status';
import { useLoader } from '../../../../hooks';
import { FcrBidService } from '../../../../services/FcrBidService';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { dkCurrencyFormat, euToUsCurrencyFormat } from '../../../../utils/currencyHelper';
import { getArrayOfDatesForDateRange } from '../../../../utils/dateHelper';
import { Capitalize } from '../../../../utils/stringHelper';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import Dropdown from '../../../../components/Inputs/Dropdown';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import ThreeStateToggle from '../../../../components/Inputs/ThreeStateToggle/ThreeStateToggle';
import { THREE_STATE_TOGGLE_LABELS } from '../../../../constants';
import TabsComponent from '../../../../components/Tabs/Tabs';
import { Input, ToggleButton } from '../../../../components/Inputs/Input';
import { ForceFactorValidation } from '../../../../validations/Frequency/CreateBidValidation';
import { useHistory } from 'react-router-dom';
import CreateAgreement from '../../CreateAgreement';

const Bids = ({ updateQueryParams, queryParamsData = {} }) => {
    const history = useHistory();
    const date = new Date();
    const [startLoader, stopLoader] = useLoader();
    const [statusData, setStatusData] = useState({});
    const [rawCustomers, setRawCustomers] = useState({});
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState(
        queryParamsData?.customer
            ? queryParamsData.customer
            : {
                  value: '',
                  label: '',
              }
    );
    const [customerLicenses, setCustomerLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState(queryParamsData?.license ? queryParamsData.license : {});
    const [licenseGroups, setLicenseGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(queryParamsData?.groups ? queryParamsData.groups : {});

    const [capacityModal, setCapacityModal] = useState(false);
    const [priceModal, setPriceModal] = useState(false);
    const [hourlyModal, setHourlyModal] = useState(false);
    const [biddingInfoModal, setBiddingInfoModal] = useState(false);

    const [bidsModal, setBidsModal] = useState(queryParamsData.bidModal ? queryParamsData.bidModal : false);
    const [alertModal, setAlertModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);

    const [stateModal, setStateModal] = useState(queryParamsData.stateModal ? queryParamsData.stateModal : false);

    const [selectedState, setSelectedState] = useState('center');

    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });

    const [bids, setBids] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    const [createBidDate, setCreateBidDate] = useState(queryParamsData.bidDate ? queryParamsData.bidDate : '');

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: '',
    });

    const [forceFactor, setForceFactor] = useState({
        status: false,
        value: 1,
    });

    const [isAggrement, setAggrement] = useState({
        isAggrement: false,
        modalStatus: false,
        link: '',
    });

    useEffect(() => {
        fetchMarketEnabledGroups();
    }, []);

    useEffect(() => {
        if (selectCustomer.value && Object.keys(rawCustomers).length) {
            const customer = rawCustomers[selectCustomer.value];
            const licenses = customer.licenses;
            const activeLicenses = Object.keys(licenses).map((id) => ({
                label: Capitalize(licenses[id].type),
                value: id,
            }));
            if (activeLicenses.length) {
                setCustomerLicenses(activeLicenses);
                const queryMatched = matchQueryParams(activeLicenses, queryParamsData.license);
                setSelectedLicense(queryMatched ? queryParamsData.license : activeLicenses[0]);
                !queryMatched && updateQueryParams('license', JSON.stringify(activeLicenses[0]));
            } else {
                setCustomerLicenses([]);
                setSelectedLicense({});
            }
        } else {
            setCustomerLicenses([]);
            setSelectedLicense({});
        }
    }, [selectCustomer]);

    useEffect(() => {
        if (selectedLicense.value && selectCustomer.value && Object.keys(rawCustomers).length) {
            const license = rawCustomers[selectCustomer.value].licenses[selectedLicense.value];
            const groups = license.groups.map((g) => ({
                label: g.name,
                value: g._id,
                approvalValidity: g.approvalValidity,
            }));
            if (groups.length) {
                setLicenseGroups(groups);
                const queryMatched = matchQueryParams(groups, queryParamsData.groups);
                setSelectedGroup(queryMatched ? queryParamsData.groups : groups[0]);
                !queryMatched && updateQueryParams('groups', JSON.stringify(groups[0]));
            } else {
                setLicenseGroups([]);
                setSelectedGroup({});
            }
        } else {
            setLicenseGroups([]);
            setSelectedGroup({});
        }
    }, [selectedLicense]);

    useEffect(() => {
        fetchBids();
        getInstanceDetails();
    }, [refresh, selectedGroup]);

    const fetchMarketEnabledGroups = () => {
        FcrBidService.GetMarketEnabledGroups(startLoader, groupSuccess, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const groupSuccess = ({ data }) => {
        const res = data.data;
        setRawCustomers(res);
        const curCustomers = Object.keys(res);
        if (curCustomers.length) {
            const customerOptions = curCustomers.map((id) => ({
                label: res[id].name,
                value: id,
                biddingMarkets: res[id].biddingMarkets,
            }));
            setCustomers(customerOptions);
            if (customerOptions.length) {
                const queryMatched = matchQueryParams(customerOptions, queryParamsData.customer);
                setSelectCustomer(queryMatched ? queryParamsData.customer : customerOptions[0]);
                !queryMatched && updateQueryParams('customer', JSON.stringify(customerOptions[0]));
            }
        } else {
            toast.error('No Instances Enabled!');
            setCustomers([]);
            setSelectCustomer({
                value: '',
                label: '',
            });
        }
    };

    const fetchBids = () => {
        if (selectCustomer.value) {
            let params = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                customerId: selectCustomer.value,
                groupId: selectedGroup.value,
            };
            FcrBidService.fcrBidData(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
        }
    };

    const getEnablerRevenue = (enablers = {}, enablersData = []) => {
        let totalRevenue = 0;
        let tooltip = [];
        enablersData.map((e) => {
            for (const key in enablers) {
                if (key == e._id) {
                    totalRevenue = totalRevenue + Number(enablers[key]);
                    tooltip.push(`${e.name} : ${enablers[key].toLocaleString('da-DK')}`);
                }
            }
        });

        return (
            <CustomTooltip
                content={tooltip.map((e) => (
                    <div>{e}</div>
                ))}
            >
                {totalRevenue.toLocaleString('da-DK')}
            </CustomTooltip>
        );
    };

    const handleGetDataSuccess = (res) => {
        setAggrement({
            isAggrement: res.data.data?.aggrement ? true : false,
            modalStatus: false,
            link: '',
        });
        const { data: bidData, totalRevenue } = res.data.data;
        let processedData = bidData.map((data, index) => {
            const bid = {
                ...data,
                sc: data?.sc === '--' ? data.sc : dkCurrencyFormat(data?.sc),
                customer: data?.customer === '--' ? data?.customer : dkCurrencyFormat(data?.customer),
                partner: data?.partner ? dkCurrencyFormat(data?.partner) : '--',
                enablersAmount: getEnablerRevenue(data?.enablers, data.enablersData),
                avgCapacity: LodashMean(data.capacity).toFixed(2),
                timestamp: data.date,
                actions: getActions(data._id, data),
                revenue: data.revenue ? dkCurrencyFormat(Number(data?.revenue)) : 0,
            };
            if (!data?.bidStatus?.length) {
                bid.style = { background: '#E6E8FA', color: '#4C5AD9' };
            }
            if (data?.reverseBid) {
                bid.style = { background: '#fef6e5', color: '#faa500' };
            }
            return bid;
        });
        let allDates = getArrayOfDatesForDateRange(moment(processedData[processedData.length - 1]?.date).format('YYYY-MM-DD'), moment(processedData[0]?.date).format('YYYY-MM-DD'));
        allDates = allDates.reverse();
        let bidArrayWithEveryDate = [];
        processedData.length &&
            allDates.forEach((date) => {
                const existingBids = processedData.filter((bid) => bid.cetDate === date);
                if (existingBids.length > 0) {
                    bidArrayWithEveryDate = bidArrayWithEveryDate.concat(existingBids);
                } else {
                    bidArrayWithEveryDate.push({
                        dynamicallyCreated: true,
                        cetDate: date,
                        avgCapacity: '--',
                        customer: '--',
                        sc: '--',
                        partner: '--',
                        enablers: {},
                        revenue: '--',
                        status: '--',
                        style: { background: '#FFE3E3', color: '#E25657' },
                        actions: (
                            <div className={classes.ActionContainer}>
                                <CustomTooltip content={'Create Bid'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setCreateBidDate(date);
                                            setBidsModal(true);
                                            updateQueryParams('bidModal', true);
                                            updateQueryParams('bidDate', date);
                                        }}
                                    >
                                        <div>
                                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>
                            </div>
                        ),
                    });
                }
            });
        setTotalRevenue(Math.round(totalRevenue).toLocaleString('da-DK'));
        setBids(bidArrayWithEveryDate);
    };

    const getActions = (id, data) => {
        const { capacity, price, bidStatus } = data;
        return (
            <div className={classes.ActionContainer}>
                <CustomTooltip content={'Bidding Info'}>
                    <div
                        className={classes.Action}
                        onClick={() => {
                            bidStatus?.length
                                ? setHourlyModal({
                                      bidStatus,
                                      bidId: id,
                                      reverseReason: data.reverseReason,
                                      reverseBid: data?.reverseBid,
                                  })
                                : setAlertModal('SETTLEMENTS');
                            price?.length ? setPriceModal(price) : setAlertModal('Price');
                            capacity?.length ? setCapacityModal(capacity) : setAlertModal('Capacity');
                            setBiddingInfoModal(true);
                            // setDownloadHourData(data);W
                        }}
                    >
                        <div>
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </div>
                </CustomTooltip>
                {!data?.reverseBid ? (
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <CustomTooltip content={'Download'}>
                            <div className={classes.Action} onClick={() => handleDownloadHourData(data)}>
                                <div>
                                    <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Bid'}>
                            <div className={classes.Action} onClick={() => handleExternalBid(id, data)}>
                                <div>
                                    <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Fetch Status'}>
                            <div className={classes.Action} onClick={() => handleCheckStatus(id)}>
                                <div>
                                    <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    </div>
                ) : (
                    ''
                )}

                <CustomTooltip content={'Delete'}>
                    <div className={classes.Action} onClick={() => setDeleteModal({ status: true, bidId: id })}>
                        <div>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </div>
                </CustomTooltip>
            </div>
        );
    };

    const handleCheckStatus = (id) => {
        FcrBidService.checkBidStatus(id, startLoader, handleCheckSuccess, handleError, stopLoader);
    };

    const handleCheckSuccess = ({ data }) => {
        setRefresh(Math.random() * 3);
        setStatusData(data.data);
        setStatusModal(true);
    };

    const handleExternalBid = (id, data) => {
        const { date, price, capacity } = data;
        FcrBidService.PostBid(
            id,
            {},
            { bid: { price, capacity } },
            () => startLoader('postExternal'),
            handlePostSuccess,
            handleError,
            () => stopLoader('postExternal')
        );
    };

    const handlePostSuccess = ({ data }) => {
        fetchBids();
        setStatusData(data.data);
        setStatusModal(true);
    };

    const handleDelete = (id) => {
        FcrBidService.Delete(deleteModal.bidId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        fetchBids();
        toast.success('Bid Deleted');
    };

    const minMaxValues = {
        min: LodashMin(bids, 'avgCapacity') && LodashMin(bids, 'avgCapacity').avgCapacity,
        max: LodashMax(bids, 'avgCapacity') && LodashMax(bids, 'avgCapacity').avgCapacity,
    };
    const averageArray = (nums) => {
        return nums.reduce((a, b) => a + b) / nums.length;
    };
    const handleDownloadExcel = () => {
        // let processedData = LodashMap(bids, (item) => LodashPick(item, ['timestamp', 'avgCapacity', 'revenue', 'status','bidStatus']));
        let data = [];
        let totalRevenue = 0;
        let totalSC = 0;
        let totalCustomer = 0;
        let totalPartner = 0;
        let totalEnabler = 0;
        bids.map((item) => {
            if (!item.dynamicallyCreated) {
                const r = euToUsCurrencyFormat(item?.revenue);
                const s = euToUsCurrencyFormat(item?.sc);
                const c = euToUsCurrencyFormat(item?.customer);
                const p = euToUsCurrencyFormat(item?.partner);
                let e = 0;
                for (const key in item?.enablers) {
                    e += item?.enablers[key];
                }
                if (!isNaN(r)) {
                    totalRevenue += Number(r);
                }
                if (!isNaN(s)) {
                    totalSC += Number(s);
                }
                if (!isNaN(c)) {
                    totalCustomer += Number(c);
                }
                if (!isNaN(p)) {
                    totalPartner += Number(p);
                }
                if (!isNaN(e)) {
                    totalEnabler += Number(e);
                }
                data.push({
                    date: momentTimeFormater(item?.date).format('YYYY-MM-DD'),
                    capacity: Number(averageArray(item.capacity).toFixed(3)),
                    status: item.status,
                    revenue: item.revenue === null ? '--' : Number(r),
                    sc: s === '--' ? '--' : Number(s),
                    customer: c === '--' ? '--' : Number(c),
                    partner: p === '--' ? '--' : Number(p),
                    enabler: Number(e),
                });
            }
        });

        data.push({
            date: null,
            capacity: null,
            status: 'TOTAL',
            revenue: totalRevenue,
            sc: totalSC,
            customer: totalCustomer,
            partner: totalPartner,
            enablers: totalEnabler,
        });

        DownloadAsExcel(data, 'bids', ['Date (CET)', 'Capacity (MW)', 'Status (TSO)', 'Revenue (€)', 'SC (€)', 'Customer split (€)', 'Partner split (€)', 'Enablers split (€)']);
    };

    const handleDownloadHourData = (item) => {
        let data = item.capacity.map((i, index) => ({
            hour: index,
            capacity: i,
            price: item.price[index],
            hourcost: item?.bidStatus.length <= 0 ? '--' : index === 0 ? item?.bidStatus[index]?.price : (index + 1) % 4 === 0 ? item?.bidStatus[(index + 1) / 4]?.price : '--',
        }));
        DownloadAsExcel(data, `FCR_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const onChangeForceState = (value) => {
        let state = 'DEFAULT';
        switch (value) {
            case 'left': {
                state = 'DEACTIVATE';
                break;
            }
            case 'center': {
                state = 'DEFAULT';
                break;
            }
            case 'right': {
                state = 'ACTIVATE';
                break;
            }
        }

        const params = {
            forceAcivationStatus: state,
            id: selectedGroup.value,
            market: 'FCR',
        };

        FcrBidService.ForceActivation(params, startLoader, handleForceStatueSuccess, handleError, stopLoader);
    };

    const handleForceStatueSuccess = () => {
        toast.success('Activation state updated!');
        getInstanceDetails();
    };

    const getInstanceDetails = () => {
        if (selectedGroup?.value) {
            FcrBidService.GetInstanceDetails(selectedGroup.value, startLoader, handleInstanceDetaikSuccess, handleError, stopLoader);
        }
    };

    const handleInstanceDetaikSuccess = ({ data }) => {
        let state = 'center';

        if (data?.data?.forceFactorStatus?.FCR) {
            setForceFactor({
                ...data?.data?.forceFactorStatus?.FCR,
                status: data?.data?.forceFactorStatus?.FCR.status === 'DEFAULT' ? false : true,
            });
        } else {
            setForceFactor({
                status: false,
                value: 1,
            });
        }
        switch (data?.data?.forceAcivationStatus?.FCR) {
            case 'DEACTIVATE': {
                state = 'left';
                break;
            }
            case 'DEFAULT': {
                state = 'center';
                break;
            }
            case 'ACTIVATE': {
                state = 'right';
                break;
            }
        }
        setSelectedState(state);
    };

    const updateForceFactor = (values) => {
        const payload = {
            forceFactorStatus: {
                status: values.status ? 'CUSTOM' : 'DEFAULT',
                value: Number(values.value || 1),
            },
            id: selectedGroup.value,
            market: 'FCR',
        };
        FcrBidService.UpdateForceFactor(payload, startLoader, handleForceFactorSuccess, handleError, stopLoader);
    };

    const handleForceFactorSuccess = () => {
        toast.success('Factor state updated!');
    };

    const tabs = [
        {
            name: 'Force Activation',
            component: (
                <div
                    style={{
                        width: '25vw',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3vh',
                    }}
                >
                    <Typography size={'14'} content={`Instance: ${selectedGroup.label}`} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <ThreeStateToggle labels={THREE_STATE_TOGGLE_LABELS} onChange={onChangeForceState} defaultValue={selectedState} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <button
                            onClick={() => {
                                setStateModal(false);
                                updateQueryParams('stateModal', false);
                            }}
                            style={{ width: '7vw', margin: '0' }}
                            className="btn-secondary"
                        >
                            Close
                        </button>
                    </div>
                </div>
            ),
        },
        {
            name: 'Force Factor',
            component: (
                <div
                    style={{
                        width: '25vw',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3vh',
                    }}
                >
                    <Typography size={'14'} content={`Instance: ${selectedGroup.label}`} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Formik initialValues={forceFactor} enableReinitialize validationSchema={ForceFactorValidation} onSubmit={updateForceFactor}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '0.5vw',
                                            alignItems: 'center',
                                            fontSize: '0.9vw',
                                            fontWeight: '500',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <label>Default</label>
                                        <ToggleButton label="" name="status" style={{ marginTop: '0', gridColumnGap: '0' }} values={values} />
                                        <label>Custom</label>
                                    </div>
                                    {values.status && (
                                        <div className={allDeviceClasses.FieldControl2}>
                                            <label>
                                                Capacity Factor <span className="required">*</span>
                                            </label>
                                            <Input name="value" type="number" style={{ marginTop: '0' }} />
                                        </div>
                                    )}
                                    <div className={allDeviceClasses.ButtonContainer}>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    setStateModal(false);
                                                    updateQueryParams('stateModal', false);
                                                }}
                                                className="btn-secondary"
                                                type="button"
                                            >
                                                Close
                                            </button>
                                        </div>

                                        <div>
                                            <button className="btn-primary" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            ),
        },
    ];

    const redirectToRevenue = () => {
        const link = `/?tab=3&showRevenueSplit=${JSON.stringify({
            status: true,
            data: {
                _id: selectCustomer?.value,
                biddingMarkets: selectCustomer?.biddingMarkets || [],
            },
        })}`;
        return link;
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header}>
                <div>
                    <Typography content="FCR Bids" />
                    <div className={classes.TableCount}>
                        Total Revenue :
                        <span>
                            <Typography size="14" content={totalRevenue + ' €'} />
                        </span>
                    </div>
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={bids.length || 0} />
                        </span>
                    </div>
                    <Formik initialValues={{ customerId: '' }}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form style={{ display: 'flex', gridColumnGap: '0.3vw' }}>
                                {customers.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            defaultValue={queryParamsData?.customer ? queryParamsData.customer : selectCustomer}
                                            onChange={(e) => {
                                                setSelectCustomer(e);
                                                updateQueryParams('customer', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                                {customerLicenses.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="licenseId"
                                            options={customerLicenses}
                                            defaultValue={selectedLicense}
                                            onChange={(e) => {
                                                setSelectedLicense(e);
                                                updateQueryParams('license', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                                {licenseGroups.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="groupId"
                                            options={licenseGroups}
                                            defaultValue={selectedGroup}
                                            onChange={(e) => {
                                                if (e.value !== selectedGroup?.value) {
                                                    setSelectedGroup(e);
                                                }
                                                updateQueryParams('groups', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={classes.ButtonsContainer}>
                    <div>
                        <button
                            onClick={() => {
                                if (isAggrement.isAggrement) {
                                    setBidsModal(true);
                                    updateQueryParams('bidModal', true);
                                } else {
                                    toast.error('No Agreement Found.');
                                    setAggrement({
                                        ...isAggrement,
                                        modalStatus: true,
                                        link: redirectToRevenue(),
                                    });
                                }
                            }}
                            style={{ width: '7vw' }}
                            className="btn-primary"
                        >
                            Create Bid
                        </button>
                    </div>
                    <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />

                    {/* <UilAlignCenterH
            size={"2vw"}
            style={{
              color: "var(--color-primary)",
              marginTop: "1vw",
              cursor: "pointer",
            }}
            onClick={() => {
              setStateModal(true);
              updateQueryParams("stateModal", true);
            }}
          /> */}
                </div>
            </div>
            <div className={classes.SubHeader}>
                <div className={classes.Badges}>
                    <div className={classes.BadgeContainer}>
                        <Typography content="Max Capacity" />
                        <div className={classes.TableCount}>
                            <Typography size="14" content={minMaxValues.max} />
                        </div>
                    </div>
                    <div className={classes.BadgeContainer}>
                        <Typography content="Min Capacity" />
                        <div className={classes.TableCount}>
                            <Typography size="14" content={minMaxValues.min} />
                        </div>
                    </div>
                </div>
                <div className={classes.DatePickers}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels}>Start Date</div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) => {
                                setDateRange({
                                    startDate: new Date(date.startDate),
                                    endDate: new Date(date.endDate),
                                });
                                updateQueryParams('dateRange', JSON.stringify(date));
                            }}
                        />
                    </div>
                    <div className={classes.SubmitButton} onClick={() => fetchBids()}>
                        <button className="btn-primary">Submit</button>
                    </div>
                </div>
            </div>
            <Table
                head={['Date', 'Avg Capacity', 'Customer', 'Partner', 'S.C', 'Enablers', 'Revenue', 'Status', 'Action']}
                keys={['cetDate', 'avgCapacity', 'customer', 'partner', 'sc', 'enablersAmount', 'revenue', 'status', 'actions']}
                data={bids}
            />
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={isAggrement.modalStatus}
                setOpen={() => {
                    setAggrement({ ...isAggrement, modalStatus: false });
                }}
            >
                <CreateAgreement link={isAggrement.link} setOpen={setAggrement} />
            </ModalComponent>
            {/* <ModalComponent isOpen={capacityModal} setOpen={setCapacityModal}>
        <CapacityContent capacity={capacityModal} setOpen={setCapacityModal} />
      </ModalComponent>
      <ModalComponent isOpen={priceModal} setOpen={setPriceModal}>
        <PriceContent data={priceModal} setOpen={setPriceModal} />
      </ModalComponent>
      <ModalComponent isOpen={hourlyModal} setOpen={setHourlyModal}>
        <HourlyCost data={hourlyModal} setOpen={setHourlyModal} />
      </ModalComponent> */}
            <ModalComponent
                isOpen={biddingInfoModal}
                setOpen={(status) => {
                    setBiddingInfoModal(status);
                    if (!status) {
                        setHourlyModal([]);
                        setPriceModal([]);
                        setCapacityModal([]);
                    }
                }}
            >
                <BiddingInfoContent
                    setOpen={setBiddingInfoModal}
                    hourlyModalData={hourlyModal}
                    priceModalData={priceModal}
                    capacityModalData={capacityModal}
                    fetchBids={fetchBids}
                />
            </ModalComponent>
            <ModalComponent
                isOpen={bidsModal}
                setOpen={(status) => {
                    setBidsModal(status);
                    updateQueryParams('bidModal', status);
                    setCreateBidDate('');
                    updateQueryParams('bidDate', '');
                }}
            >
                <BidContent
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    fetchBids={fetchBids}
                    setOpen={(status) => {
                        setBidsModal(status);
                        updateQueryParams('bidModal', status);
                    }}
                    customerId={selectCustomer.value}
                    bidDate={queryParamsData.bidDate ? queryParamsData.bidDate : createBidDate}
                    group={selectedGroup}
                    updateQueryParams={updateQueryParams}
                />
            </ModalComponent>
            <ModalComponent
                isOpen={stateModal}
                setOpen={(status) => {
                    setStateModal(status);
                    updateQueryParams('stateModal', status);
                }}
            >
                <TabsComponent tabs={tabs} />
            </ModalComponent>
            <ModalComponent isOpen={statusModal} setOpen={setStatusModal}>
                <Status data={statusData} startLoader={startLoader} stopLoader={stopLoader} setOpen={setStatusModal} />
            </ModalComponent>
            <AlertModal title={alertModal} isOpen={alertModal} content={'No Data Found!'} setOpen={setAlertModal} />
        </div>
    );
};

export default Bids;
