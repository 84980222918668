import classes from '../../../../../styles/CapacityContent.module.css';
import React from 'react';

const CapacityContent = ({ capacity, setOpen, title }) => {
    return (
        <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto' }}>
            <div className={classes.Title}></div>
            <div className={classes.Content}>
                <div className={classes.ContainerStart}>
                    {[0, 1, 2].map((n) => (
                        <table>
                            <tr>
                                <th>Hour</th>
                                <th>Capacity</th>
                            </tr>
                            {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                key <= 23 ? (
                                    <tr>
                                        <td>{key}</td>
                                        <td>{capacity[key]}</td>
                                    </tr>
                                ) : null
                            )}
                        </table>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CapacityContent;
