// Standard library imports
import React, { useEffect, useState } from 'react';
// External Library imports
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
// Internal module imports
import classes from '../../../../styles/BidModal.module.css';
import { Input } from '../../../../components/Inputs/Input';
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks';
import { BiddingService } from '../../../../services/BiddingService';
import { editBidValidation } from '../../../../validations/Root/biddingValidator';
import { arrayConverter24 } from '../../../../utils/arrayHelper';
import { CreateBidValidation } from '../../../../validations/Frequency/CreateBidValidation';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const getSlotAndPriceInitials = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = '0.9';
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = '14';
    });
    return slotAndPrice;
};

const fcrInitialValues = {
    slot1: '0.9',
    slot2: '0.9',
    slot3: '0.9',
    slot4: '0.9',
    slot5: '0.9',
    slot6: '0.9',
    slot1Price: '14',
    slot2Price: '14',
    slot3Price: '14',
    slot4Price: '14',
    slot5Price: '14',
    slot6Price: '14',
};

const EditBidContent = ({ setOpen, bid, date, market, editFunc }) => {
    const [initialValues, setInitialValues] = useState({
        slotAndPrice: market === 'FCR' ? market : getSlotAndPriceInitials(),
        reserveBidIdentification: market !== 'FCR' ? bid.reserveBidIdentification : '',
    });
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);
    const [startLoader, stopLoader] = useLoader();

    useEffect(() => {
        if (bid) {
            updateFromBid();
            fetchMaintenanceHourForADate();
        }
    }, []);

    const updateFromBid = () => {
        if (market !== 'FCR') {
            const { capacity, price, reserveBidIdentification } = bid;
            if (capacity?.length && price?.length) {
                const slotAndPrice = {};
                capacity.forEach((item, index) => {
                    slotAndPrice[`slot${index + 1}`] = item;
                });
                price.forEach((item, index) => {
                    slotAndPrice[`slot${index + 1}Price`] = item;
                });
                setInitialValues({
                    ...initialValues,
                    slotAndPrice,
                    reserveBidIdentification,
                });
            } else {
                setInitialValues({
                    slotAndPrice: getSlotAndPriceInitials(),
                    reserveBidIdentification: '',
                });
            }
        } else {
            const { capacity, price } = bid;
            if (capacity) {
                setInitialValues({
                    slot1: capacity[0],
                    slot2: capacity[4],
                    slot3: capacity[8],
                    slot4: capacity[12],
                    slot5: capacity[16],
                    slot6: capacity[20],
                    slot1Price: price[0],
                    slot2Price: price[4],
                    slot3Price: price[8],
                    slot4Price: price[12],
                    slot5Price: price[16],
                    slot6Price: price[20],
                });
            } else {
                setInitialValues({
                    slot1: '0.9',
                    slot2: '0.9',
                    slot3: '0.9',
                    slot4: '0.9',
                    slot5: '0.9',
                    slot6: '0.9',
                    slot1Price: '14',
                    slot2Price: '14',
                    slot3Price: '14',
                    slot4Price: '14',
                    slot5Price: '14',
                    slot6Price: '14',
                });
            }
        }
    };

    const handleSubmit = (values) => {
        let capacity = [];
        let price = [];
        if (market !== 'FCR') {
            Array(24)
                .fill(null)
                .map((item, index) => {
                    capacity.push(Number(values.slotAndPrice[`slot${index + 1}`]));
                    price.push(Number(values.slotAndPrice[`slot${index + 1}Price`]));
                });
        } else {
            capacity = arrayConverter24(values.slot1, values.slot2, values.slot3, values.slot4, values.slot5, values.slot6, capacity);
            price = arrayConverter24(values.slot1Price, values.slot2Price, values.slot3Price, values.slot4Price, values.slot5Price, values.slot6Price, price);
        }

        editFunc({
            ...(market !== 'FCR' ? { reserveBidIdentification: values.reserveBidIdentification } : {}),
            capacity,
            price,
            _id: bid._id,
        });
        setOpen(false);
    };

    const GetBidRequestForADate = () => {
        if (bid?.instanceId) {
            BiddingService.GetBidRequestForADate(
                {
                    date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                },
                bid.instanceId,
                market,
                startLoader,
                handlebidRequestSuccess,
                handleError,
                stopLoader
            );
        }
    };

    const fetchMaintenanceHourForADate = () => {
        if (bid?.instanceId) {
            BiddingService.GetMaintenanceHoursForADate(
                {
                    date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                },
                bid.instanceId,
                market,
                () => startLoader('maintenance'),
                handleMaintenanceSuccess,
                handleError,
                () => stopLoader('maintenance')
            );
        }
    };

    const handleMaintenanceSuccess = ({ data }) => {
        if (data.data?.hourSlots) setMaintenanceSlots(data.data?.hourSlots);
        else setMaintenanceSlots([]);
    };

    const handlebidRequestSuccess = ({ data }) => {
        if (market !== 'FCR') {
            if (data.data) {
                const { price = [], capacity = [] } = data.data;
                const slotAndPrice = {};
                price.forEach((val, index) => (slotAndPrice[`slot${index + 1}Price`] = val));
                capacity.forEach((val, index) => (slotAndPrice[`slot${index + 1}`] = val));
                setInitialValues({
                    ...initialValues,
                    slotAndPrice,
                });
            } else {
                toast.error('No request found');
            }
        } else {
            if (data.data) {
                setInitialValues({
                    slot1: data.data.capacity[0],
                    slot2: data.data.capacity[1],
                    slot3: data.data.capacity[2],
                    slot4: data.data.capacity[3],
                    slot5: data.data.capacity[4],
                    slot6: data.data.capacity[5],
                    slot1Price: data.data.price[0],
                    slot2Price: data.data.price[1],
                    slot3Price: data.data.price[2],
                    slot4Price: data.data.price[3],
                    slot5Price: data.data.price[4],
                    slot6Price: data.data.price[5],
                });
            } else {
                toast.error('No request found');
            }
        }
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const fetchProposedPrices = () => {
        const tempMarket = market === 'FCR-D-INC' ? 'fcr-d' : 'fcr-d-minus';

        BiddingService.GetBiddingToolData(tempMarket, startLoader, handleDataSuccess, handleError, stopLoader);
    };

    const handleDataSuccess = ({ data }) => {
        const prices = {};
        data?.data?.data?.forEach((item, index) => {
            prices[`slot${index + 1}Price`] = item.proposed;
        });
        setInitialValues({ ...initialValues, ...prices });
    };
    return (
        <div>
            <Typography content="Edit Bid" size="16" />
            <div className={classes.Subtitle} style={{ margin: 0 }}>
                Red-bordered hour slots signify customer-requested maintenance
                <span className="required">*</span>
            </div>
            <div className={classes.FormContainer2}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={market === 'FCR' ? CreateBidValidation : editBidValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            {market !== 'FCR' ? (
                                <div>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className={classes.Subtitle}>Capacity(Mw)</div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    gap: '0.5vw',
                                                }}
                                            >
                                                <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                                    Fill ( Bid Request )
                                                </button>
                                            </div>
                                        </div>
                                        {Array(6)
                                            .fill(null)
                                            .map((item, index) => (
                                                <div className={classes.InputContainer}>
                                                    {Array(4)
                                                        .fill(null)
                                                        .map((subItem, subIndex) => (
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label for={`slot${index * 4 + subIndex + 1}`}>
                                                                        Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex}-{index * 4 + subIndex + 1}
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    <Input
                                                                        name={`slotAndPrice.slot${index * 4 + subIndex + 1}`}
                                                                        id={`slot${index * 4 + subIndex + 1}`}
                                                                        style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                                        type="number"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                    </div>
                                    <div>
                                        <div
                                            className={classes.Subtitle}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <span>Price(€)</span>
                                            {['FCR-D-INC', 'FCR-D-DEC'].includes(market) ? (
                                                <div style={{ margin: '0' }} className={classes.ButtonContainer}>
                                                    <button type={'button'} onClick={fetchProposedPrices} className="btn-primary" style={{ margin: '0' }}>
                                                        Proposed Prices
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {Array(6)
                                            .fill(null)
                                            .map((item, index) => (
                                                <div className={classes.InputContainer}>
                                                    {Array(4)
                                                        .fill(null)
                                                        .map((subItem, subIndex) => (
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label for={`slot${index * 4 + subIndex + 1}Price`}>
                                                                        Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex}-{index * 4 + subIndex + 1} Price
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    <Input
                                                                        name={`slotAndPrice.slot${index * 4 + subIndex + 1}Price`}
                                                                        id={`slot${index * 4 + subIndex + 1}Price`}
                                                                        style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                                        type="number"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                        <div className={classes.InputContainer}>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="reserveBidIdentification">
                                                        Reserve Bid Identification
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className={classes.Subtitle}>Capacity(Mw)</div>
                                        <div>
                                            <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                                Fill from bid request
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot1">
                                                        Slot 1 / Hour 0-4<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot1" id="slot1" style={maintenanceSlots.includes(1) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot2">
                                                        Slot 2 / Hour 4-8<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot2" id="slot2" style={maintenanceSlots.includes(2) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot3">
                                                        Slot 3 / Hour 8-12<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot3" id="slot3" style={maintenanceSlots.includes(3) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot4">
                                                        Slot 4 / Hour 12-16<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot4" id="slot4" style={maintenanceSlots.includes(4) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot5">
                                                        Slot 5 / Hour 16-20<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot5" id="slot5" style={maintenanceSlots.includes(5) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot6">
                                                        Slot 6 / Hour 20-24<span className="required">*</span>
                                                    </label>
                                                    <Input name="slot6" id="slot6" style={maintenanceSlots.includes(6) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.Subtitle}>Price(€)</div>
                                        <div className={classes.InputContainer}>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot1Price">
                                                        Slot 1 / Hour 0-4 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot1Price" id="slot1Price" style={maintenanceSlots.includes(1) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot2Price">
                                                        Slot 2 / Hour 4-8 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot2Price" id="slot2Price" style={maintenanceSlots.includes(2) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot3Price">
                                                        Slot 3 / Hour 8-12 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot3Price" id="slot3Price" style={maintenanceSlots.includes(3) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot4Price">
                                                        Slot 4 / Hour 12-16 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot4Price" id="slot4Price" style={maintenanceSlots.includes(4) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot5Price">
                                                        Slot 5 / Hour 16-20 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot5Price" id="slot5Price" style={maintenanceSlots.includes(5) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label for="slot6Price">
                                                        Slot 6 / Hour 20-24 Price
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="slot6Price" id="slot6Price" style={maintenanceSlots.includes(6) ? maintenanceSlotCss : {}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen({ status: false, bid: {} })}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditBidContent;
