// Standard library imports
import React from 'react';

// External library imports
import { Form, Formik } from 'formik';

import Typography from '../../../components/Typography/Typography';

// CSS Imports
import classes from '../../../styles/CapacityContent.module.css';

const BidInfoModal = ({ data, setOpen }) => {
    return (
        <div>
            <Typography content={'Hourly Bid Status'} />
            <Formik initialValues={{ data: data || '' }}>
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto' }}>
                            <div className={classes.Content}>
                                <div className={classes.ContainerStart}>
                                    {[0, 1, 2].map((n) => (
                                        <table>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>Status</th>
                                            </tr>
                                            {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                                key <= 23 ? (
                                                    <tr>
                                                        <td style={{ display: 'flex', gap: '0.5vw', justifyContent: 'center' }}>{`Hour__${key}`}</td>
                                                        <td>{data?.[key] > 0 ? 1 : 0}</td>
                                                    </tr>
                                                ) : null
                                            )}
                                        </table>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className={classes.ButtonContainer} style={{ marginRight: '1vw' }}>
                <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                    close
                </button>
            </div>
        </div>
    );
};

export default BidInfoModal;
