// External library imports
import * as yup from 'yup';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

const checkMultiples = (sum) => {
    const remainder = sum % 0.1;
    const tolerance = 0.00001;
    return remainder <= tolerance || Math.abs(sum - Math.round(sum * 10) / 10) <= tolerance;
};

const capacity = (pairs) => {
    let temp = {};
    pairs.map((e) => {
        temp[e.instanceId] = yup.array().of(
            yup
                .number()
                .typeError(ERRORS.number)
                .min(0)
                .required(ERRORS.required)
                .test('modulo-test', 'Sum of capacity will be multiple of 0.1', function (value, context) {
                    const capacity = context?.from[0].value;
                    const i = context?.options.index;
                    let sum = 0;
                    for (let key in capacity) {
                        sum += Number(capacity[key][i]);
                    }
                    return checkMultiples(sum);
                })
        );
    });
    return temp;
};

export const CreateBundleBidValidation = (pairs) => {
    return yup.object().shape({
        capacity: yup.object().shape(capacity(pairs)),
        price: yup.array().of(yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required)),
        reserveBidIdentification: yup.string().required(ERRORS.required),
    });
};

const capacityDMinus1 = (pairs) => {
    let temp = {};
    pairs.map((e) => {
        temp[e.instanceId] = yup.array().of(
            yup
                .mixed()
                .test({
                    name: 'isNotNull',
                    message: ERRORS.required,
                    test: (value) => {
                        if (value === null || value >= 0) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                })
                .test('modulo-test', 'Sum of capacity will be multiple of 0.1', function (value, context) {
                    const capacity = context?.from[0].value;
                    const i = context?.options.index;
                    let sum = 0;
                    for (let key in capacity) {
                        sum += Number(capacity[key][i]);
                    }
                    return checkMultiples(sum);
                })
        );
    });
    return temp;
};

export const CreateDMinusBundleBidValidation = (instances) => {
    return yup.object().shape({
        capacity: yup.object().shape(capacityDMinus1(instances)),
        price: yup.array().of(
            yup.mixed().test({
                name: 'isNotNull',
                message: ERRORS.required,
                test: (value) => {
                    if (value === null || value >= 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
            })
        ),
    });
};

export const ResultMessageValidator = (dMinus2BidNotAvailable) => {
    const wrapper = {
        reserveBidIdentification: yup.string().required(ERRORS.required),
        resultMessageIdDMinus1: yup.number().typeError(ERRORS.number),
    };
    wrapper.resultMessageId = dMinus2BidNotAvailable ? yup.number().typeError(ERRORS.number) : yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);

    return yup.object().shape(wrapper);
};
