import { HttpClient } from "../utils/httpClient";

const PATH = {
  read_all: "/root/mobilePay",
  capturePayment: "/root/mobilePay/capture",
  cancelPayment: "/root/mobilePay/cancel",
  update: "/root/mobilePay",
};
//Hit for getting all version history info from API
const ReadAll = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.read_all, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};
//Post call for creating a version history
const CapturePayment = (params, payload, start, callback, error, next) => {
  start();
  return HttpClient.post(PATH.capturePayment, payload, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const CancelPayment = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.cancelPayment, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

//Patch request for updating a version history (id as a param)
const Update = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.update}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const MobilepayService = {
  CancelPayment,
  ReadAll,
  Update,
  CapturePayment,
};
