import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);

const slotAndPrice = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = validateNumber();
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = validateNumber();
    });
    return slotAndPrice;
};

export const editBidValidation = yup.object().shape({
    slotAndPrice: yup.object().shape(slotAndPrice()),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});
