import { USER_ROLE } from '../../../../constants';
import { get as LodashGet } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import classes from '../../../../styles/Bids.module.css';
import DeleteIcon from '../../../../assets/delete.png';
import EditIcon from '../../../../assets/edit.png';
import EyeIcon from '../../../../assets/eye.png';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks/use-loader.hook';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import RequestContent from './ModalComponent/RequestContent';
import { FcrBidService } from '../../../../services/FcrBidService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import DeleteModalComponent from '../../../../components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import RequestOverview from './ModalComponent/RequestOverview';
import { UilCheckCircle } from '@iconscout/react-unicons';
import { BidService } from '../../../../services/bidRequestService';
import { Capitalize } from '../../../../utils/stringHelper';

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const BidRequests = ({ groups = [], licenseId }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [startLoader, stopLoader] = useLoader();
    const groupHashMap = getGroupHashMap(groups);
    const [requests, setRequests] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [editRequest, setEditRequest] = useState({});
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [overviewModal, setoverviewModal] = useState({
        status: false,
        request: {},
    });

    useEffect(() => {
        fetchRequests();
    }, [refresh]);

    const fetchRequests = () => {
        FcrBidService.GetBidRequests(startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const openEditModal = (vals) => {
        setEditRequest(vals);
        setOpenRequestModal(true);
    };

    const closeCreateEditModal = (status) => {
        setOpenRequestModal(status);
        setEditRequest({});
    };

    const closeOverviewModal = (status) => {
        setoverviewModal({ status, request: {} });
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const processed = data.data.map((i) => ({
                ...i,
                startDate: momentTimeFormater(i.startDate).format('YYYY-MM-DD'),
                endDate: momentTimeFormater(i.endDate).format('YYYY-MM-DD'),
                createdAt: momentTimeFormater(i.createdAt).format('YYYY-MM-DD'),
                updatedAt: momentTimeFormater(i.updatedAt).format('YYYY-MM-DD'),
                noBid: i.excludedDays?.length,
                instance: i.groupId?.name,
                customer: i.licenseId?.customerId?.name,
                status: i?.status ? Capitalize(i?.status) : '--',
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <UilCheckCircle onClick={() => handleBidStatusUpdate(i._id)} size="1.5vw" />
                        </div>
                        <div onClick={() => setoverviewModal({ status: true, request: i })}>
                            <img className={classes.ActionIcon} src={EyeIcon} alt="edit" />
                        </div>
                        {/* <div onClick={() => openEditModal(i)}>
              <img className={classes.ActionIcon} src={EditIcon} alt="edit" />
            </div> */}
                        <div onClick={() => setDeleteModal({ status: true, id: i._id })}>
                            <img className={classes.ActionIcon} src={DeleteIcon} alt="delete" />
                        </div>
                    </div>
                ),
            }));
            setRequests(processed);
        } else {
            setRequests([]);
        }
    };

    const handleDelete = (id) => {
        FcrBidService.DeleteBidRequest(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Bid request deleted successfully!');
        setRefresh(Math.random() * 1000);
    };

    const handleError = (error) => {
        console.log(error);
    };

    const handleStatusUpdateSuccess = () => {
        toast.success('Bid status updated!');
        fetchRequests();
    };

    const handleBidStatusUpdate = (id) => {
        BidService.UpdateBidStatus(id, startLoader, handleStatusUpdateSuccess, handleError, stopLoader);
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Request Bid" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={requests.length} />
                        </span>
                    </div>
                </div>
                <div className={classes.ButtonsContainer}>
                    <div>
                        {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                            <button
                                onClick={() => {
                                    setOpenRequestModal(true);
                                }}
                                style={{ width: '7vw', margin: 0 }}
                                className="btn-primary"
                            >
                                Request
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            <Table
                head={['Start Date', 'End Date', 'No Bid Count', 'Created At', 'Updated At', 'Customer', 'Instance', 'Market', 'Status', 'Action']}
                keys={['startDate', 'endDate', 'noBid', 'createdAt', 'updatedAt', 'customer', 'instance', 'biddingMarket', 'status', 'action']}
                data={requests}
            />
            <ModalComponent isOpen={openRequestModal} setOpen={closeCreateEditModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestContent groups={groups} setOpen={closeCreateEditModal} setRefresh={setRefresh} licenseId={licenseId} editRequest={editRequest} />
            </ModalComponent>
            <ModalComponent isOpen={overviewModal.status} setOpen={closeOverviewModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestOverview request={overviewModal.request} setOpen={closeOverviewModal} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default BidRequests;
