// Standard library imports
import React, { useEffect, useRef, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

// Internal module imports
import { Input, InputWithOutState } from '../../../../../components/Inputs/Input';
import Typography from '../../../../../components/Typography/Typography';
import { CreateBidValidationDynamically } from '../../../../../validations/Frequency/CreateBidValidation';
import { MfrrBidService } from '../../../../../services/MfrrBidService';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import { generateHourlyTimestamps } from '../../../../../utils/dateHelper';

// Css imports
import classes from '../../../../../styles/BidModal.module.css';
import _ from 'lodash';

const getInitialValues = (date, capacity = [], price = []) => {
    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const hourlyData = {};
    timestampsForWholeDay.forEach(
        (hour, index) =>
            (hourlyData[hour] = {
                capacity: capacity.length ? capacity[index] : 0,
                price: price.length ? price[index] : 0,
            })
    );
    return { hourlyData };
};

const BidContent = ({ setOpen, startLoader, stopLoader, fetchBids, customerId, bidDate, group, updateQueryParams }) => {
    const defaultDateValue = momentTimeFormater(momentTimeFormater().format('YYYY-MM-DD'));
    const [date, setDate] = useState(defaultDateValue);
    const [capacity, setCapacity] = useState([]);
    const [price, setPrice] = useState([]);
    const [initialValues, setInitialValues] = useState(getInitialValues(date, capacity, price));
    const formikRef = useRef();

    useEffect(() => {
        updateQueryParams('bidDate', date);
    }, [date]);

    useEffect(() => {
        if (customerId) {
            MfrrBidService.GetLastBid({ customerId, groupId: group.value }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    }, []);

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity, date, price } = data.data;
        if (capacity?.length && price?.length) {
            setCapacity(capacity);
            setPrice(price);
            const slotAndPrice = getInitialValues(date, capacity, price);
            const { hourlyData } = slotAndPrice;

            setInitialValues({
                ...initialValues,
                hourlyData,
            });
        } else {
            setInitialValues(getInitialValues(date, capacity, price));
        }
        const tempDate = new Date(date);
        tempDate.setDate(tempDate.getDate() + 1);
        if (!bidDate) {
            setDate(momentTimeFormater(momentTimeFormater(tempDate).format('YYYY-MM-DD')));
        }
    };

    useEffect(() => {
        setInitialValues(getInitialValues(date, capacity, price));
    }, [date]);

    const handleSubmit = (values) => {
        const params = {
            groupId: group.value,
            customerId,
            date: date.format(),
        };
        const payload = { hourlyData: values.hourlyData };
        MfrrBidService.Create(params, payload, startLoader, createSuccess, handleError, stopLoader);
    };

    const createSuccess = () => {
        toast.success('Bid Created!');
        const currentDate = moment();
        if (group?.approvalValidity?.endDate) {
            if (moment(group?.approvalValidity?.endDate).diff(currentDate, 'days') <= 30) {
                toast.warn(`Approval expires on ${momentTimeFormater(group.approvalValidity.endDate).format('YYYY-MM-DD')}`);
            }
        }
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div>
            <Typography content="Mfrr Bidding" size="16" />
            <div className={classes.FormWrapper}>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={CreateBidValidationDynamically(date)} onSubmit={handleSubmit} innerRef={formikRef}>
                    {({ values }) => (
                        <Form>
                            {[
                                {
                                    label: 'Capacity (Mw)',
                                    value: 'capacity',
                                },
                                {
                                    label: 'Price (€)',
                                    value: 'price',
                                },
                            ].map((type) => (
                                <div>
                                    <div className={classes.Subtitle}>{type.label}</div>
                                    {_.chunk(Object.keys(values.hourlyData), 6).map((chunk, chunkIndex) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '1vw',
                                            }}
                                        >
                                            {chunk.map((key, index) => (
                                                <div>
                                                    <div className={classes.FieldControl}>
                                                        <label for={`hourlyData.${key}.${type.value}`}>
                                                            Slot {6 * chunkIndex + index + 1} {' / Hour '}
                                                            {momentTimeFormater(key).hour()}-{momentTimeFormater(key).add(1, 'h').hour()}
                                                            {type.value === 'price' ? ' Price' : ' '}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <Input name={`hourlyData.${key}.${type.value}`} id={`hourlyData.${key}.${type.value}`} type={'number'} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Form>
                    )}
                </Formik>
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.FieldControl}>
                    <label>
                        Date <span className="required">*</span>
                    </label>
                    <div className="modal-date-picker">
                        <DatePicker date={date.toDate()} setDate={(d) => setDate(momentTimeFormater(momentTimeFormater(d).format('YYYY-MM-DD')))} />
                    </div>
                </div>
                <div>
                    <div className={classes.FieldControl}>
                        <label for="groupId">Group</label>
                        <InputWithOutState name="groupId" id="groupId" value={group.label} />
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
                <div>
                    <button type="submit" className="btn-primary" onClick={() => formikRef.current.submitForm()}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BidContent;
