// Standard library imports
import React from 'react';

// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import PriceContent from './Price';
import CapacityContent from './Capacity';
import HourlyCost from './HourlyCost';

const BiddingInfoContent = ({ data, getBundleBids, setOpen }) => {
    let tabs = [
        {
            name: 'Settlement',
            component: (
                <>
                    <HourlyCost data={data.hourlyCost} getBundleBids={getBundleBids} setOpen={setOpen} />
                </>
            ),
        },
        {
            name: 'Bidding Price',
            component: (
                <>
                    <PriceContent data={data.price} />
                </>
            ),
        },
        {
            name: 'Bidding Capacity',
            component: (
                <>
                    <CapacityContent capacity={data.capacity} />
                </>
            ),
        },
    ];

    return (
        <div className={'ventilation'} style={{ display: 'flex' }}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default BiddingInfoContent;
