// Standard Library Imports
import React from 'react';

// External library imports
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';

// // Internal library imports
import { useLoader } from '../../../hooks';
import Dropdown from '../../../components/Inputs/Dropdown';
import { alertsValidation } from '../../../validations/Edge/alertsValidation';
import { EdgeService } from '../../../services/EdgeService';
import { Input, ToggleButton } from '../../../components/Inputs/Input';
import PhoneNumberComponent from '../../../components/Inputs/PhoneNumberComponent';

// Css imports
import classes from '../../../styles/AllDevices.module.css';

function AlertsFormModal({ setAlertModal, edgeDeviceId }) {
    const [startLoader, stopLoader] = useLoader();

    const initialEdgeAlerts = {
        data: {
            resource: '',
            threshold: '',
            notificationType: [
                {
                    label: 'SMS',
                    value: [{ code: '', number: '' }],
                    labelValue: 'Enter Number',
                    countryCode: '',
                },
                {
                    label: 'MAIL',
                    value: [''],
                    labelValue: 'Enter Mail-Id',
                },
                {
                    label: 'SLACK',
                    value: false,
                },
            ],
        },
    };

    const resourceTypes = [
        {
            label: 'CPU',
            value: 'CPU',
        },
        {
            label: 'RAM',
            value: 'RAM',
        },
        {
            label: 'Disk',
            value: 'Disk',
        },
        {
            label: 'Network',
            value: 'Network',
        },
    ];

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleValidationError = (message) => {
        toast.error(message);
    };

    const handleSuccess = (toastData) => {
        toast.success(toastData);
        setAlertModal(false);
    };

    const handleAlertSubmit = (values) => {
        const notification = [];
        values.notificationType.forEach((type) => {
            if (type.label === 'SLACK' && type.value === true) {
                notification.push({ label: type.label });
            }

            if (type.label === 'SMS') {
                const value = [];
                type.value.forEach((sms) => sms.number && value.push(`${sms.code}${sms.number}`));
                if (value.length)
                    notification.push({
                        label: type.label,
                        value,
                    });
            }

            if (type.label === 'MAIL') {
                const value = [];
                type.value.forEach((mail) => mail && value.push(mail));
                if (value.length)
                    notification.push({
                        label: type.label,
                        value,
                    });
            }
        });
        if (notification.length === 0) {
            handleValidationError('Any one notification type should be selected.');
            return;
        }
        let payload = {
            ...values,
            edgeDeviceId,
            notificationType: notification,
        };
        EdgeService.CreateAlert(
            payload,
            () => startLoader('updatedType'),
            () => handleSuccess('Alert created sucessfully'),
            handleError,
            () => stopLoader('updatedType')
        );
        return;
    };

    return (
        <div style={{ maxWidth: '60vw', maxHeight: '70vh', overflowY: 'scroll', overflowX: 'hidden' }}>
            <div className={classes.SupportNoteForm} style={{ minWidth: '28vw' }}>
                <Formik
                    initialValues={initialEdgeAlerts.data}
                    enableReinitialize={true}
                    validationSchema={alertsValidation}
                    onSubmit={(val) => {
                        handleAlertSubmit(val);
                    }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="resource">
                                                        Resource <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Dropdown name="resource" id="resource" options={resourceTypes} defaultValue={resourceTypes[0]} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="threshold">
                                                        Threshold
                                                        {values.resource === 'Network' ? ' GB ' : ' % '}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Input name="threshold" id="threshold" style={{ marginTop: 0 }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="notificationType">
                                                        Notifation Type <span className="required">*</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label>Slack</label>
                                                    <ToggleButton
                                                        label=""
                                                        name="notificationType[2].value"
                                                        style={{ marginTop: '0', gridColumnGap: '0' }}
                                                        values={values.notificationType[2]}
                                                    />
                                                </div>
                                            </div>
                                            <FieldArray name="notificationType[1].value">
                                                {({ push, remove, form }) => {
                                                    const notificationTypeMails = form.values.notificationType[1].value;
                                                    return (
                                                        <div>
                                                            <div>
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl2}>
                                                                        <label>Mail</label>
                                                                        {notificationTypeMails.map((notification, idx) => (
                                                                            <div className={classes.InputContainer} style={{ marginBottom: '0.5vh' }}>
                                                                                <div className={classes.FieldControl2}>
                                                                                    <Input
                                                                                        name={`notificationType[1].value[${idx}]`}
                                                                                        label={values.notificationType[1].labelValue}
                                                                                        style={{ marginTop: 0 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={classes.addInputActionWrapper}
                                                                style={{ display: 'flex', flexDirection: 'row-reverse', gap: '0.5vw', paddingTop: '1%' }}
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        push('');
                                                                    }}
                                                                >
                                                                    <UilPlusCircle
                                                                        size={'1.5vw'}
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                            cursor: 'pointer',
                                                                            zIndex: '1',
                                                                        }}
                                                                    />
                                                                </span>
                                                                {notificationTypeMails.length > 1 && (
                                                                    <span onClick={() => remove(notificationTypeMails.length - 1)}>
                                                                        <UilMinusCircle
                                                                            size={'1.5vw'}
                                                                            style={{
                                                                                color: 'var(--color-primary)',
                                                                                cursor: 'pointer',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </FieldArray>
                                            <FieldArray name="notificationType[0].value">
                                                {({ push, remove, form }) => {
                                                    const notificationTypeSms = form.values.notificationType[0].value;
                                                    return (
                                                        <div>
                                                            <div>
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl2}>
                                                                        <label>SMS</label>
                                                                    </div>
                                                                </div>
                                                                {notificationTypeSms.map((notification, idx) => (
                                                                    <div className={classes.InputContainer} style={{ marginBottom: '0.5vh' }}>
                                                                        <div className={classes.FieldControl2}>
                                                                            <PhoneNumberComponent
                                                                                inputName={`notificationType[0].value[${idx}].number`}
                                                                                countryCodeName={`notificationType[0].value[${idx}].code`}
                                                                                inputValues={values}
                                                                                defaultCountryCode={{ value: values.notificationType[0].countryCode }}
                                                                                label={values.notificationType[0].labelValue}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div
                                                                className={classes.addInputActionWrapper}
                                                                style={{ display: 'flex', flexDirection: 'row-reverse', gap: '0.5vw', paddingTop: '1%' }}
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        push({ code: '', number: '' });
                                                                    }}
                                                                >
                                                                    <UilPlusCircle
                                                                        size={'1.5vw'}
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                            cursor: 'pointer',
                                                                            zIndex: '1',
                                                                        }}
                                                                    />
                                                                </span>
                                                                {notificationTypeSms.length > 1 && (
                                                                    <span onClick={() => remove(notificationTypeSms.length - 1)}>
                                                                        <UilMinusCircle
                                                                            size={'1.5vw'}
                                                                            style={{
                                                                                color: 'var(--color-primary)',
                                                                                cursor: 'pointer',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAlertModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default AlertsFormModal;
