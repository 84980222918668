import { get as LodashGet } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import classes from '../../../../styles/Bids.module.css';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks/use-loader.hook';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import RequestContent from './ModalComponent/RequestContent';
import { FcrDBidService } from '../../../../services/FcrDBidService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import DeleteModalComponent from '../../../../components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import RequestOverview from './ModalComponent/RequestOverview';
import { UilCheckCircle, UilEye, UilTrash } from '@iconscout/react-unicons';
import { BidService } from '../../../../services/bidRequestService';
import { Capitalize } from '../../../../utils/stringHelper';
import { DropdownComponent } from '../../../../components/Inputs/Input';

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const LIMIT = 15;

const BidRequests = ({ groups = [], licenseId, customers = [{}], updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [startLoader, stopLoader] = useLoader();
    const groupHashMap = getGroupHashMap(groups);
    const [requests, setRequests] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [editRequest, setEditRequest] = useState({});
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [overviewModal, setoverviewModal] = useState({
        status: false,
        request: {},
    });
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customer, setCustomer] = useState(queryParamsData.customer ? queryParamsData.customer : {});
    const [filterType, setFilterType] = useState({});

    useEffect(() => {
        const queryMatched = matchQueryParams(customers, queryParamsData.customer);
        !queryMatched && setCustomer(customers[0]);
    }, [customers]);

    useEffect(() => {
        fetchRequests();
    }, [refresh, skip, filterType, customer]);

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const fetchRequests = () => {
        if (customer?.value) {
            const params = {
                limit: LIMIT,
                skip: skip,
                customerId: customer?.value,
                filterType: filterType?.value,
            };
            FcrDBidService.GetBidRequests(params, startLoader, handleFetchSuccess, handleError, stopLoader);
        }
    };

    const closeCreateEditModal = (status) => {
        setOpenRequestModal(status);
        setEditRequest({});
    };

    const closeOverviewModal = (status) => {
        setoverviewModal({ status, request: {} });
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data?.data)) {
            const processed = data.data.data.map((i) => ({
                ...i,
                startDate: momentTimeFormater(i.startDate).format('YYYY-MM-DD'),
                endDate: momentTimeFormater(i.endDate).format('YYYY-MM-DD'),
                createdAt: momentTimeFormater(i.createdAt).format('YYYY-MM-DD'),
                updatedAt: momentTimeFormater(i.updatedAt).format('YYYY-MM-DD'),
                noBid: i.excludedDays?.length,
                instance: i.groupId?.name,
                customer: i?.customerName,
                status: i?.status ? Capitalize(i?.status) : '--',
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '0.5vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <UilCheckCircle onClick={() => handleBidStatusUpdate(i._id)} size="1.2vw" />
                        </div>
                        <div>
                            <UilEye onClick={() => setoverviewModal({ status: true, request: i })} size="1.2vw" />
                        </div>
                        <div>
                            <UilTrash onClick={() => setDeleteModal({ status: true, id: i._id })} size="1.2vw" />
                        </div>
                    </div>
                ),
            }));
            setRequests(processed);
        } else {
            setRequests([]);
        }
        setTotalCount(data.data?.count);
    };

    const handleDelete = (id) => {
        FcrDBidService.DeleteBidRequest(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Bid request deleted successfully!');
        setRefresh(Math.random() * 1000);
    };

    const handleError = (error) => {
        console.log(error);
    };

    const handleStatusUpdateSuccess = () => {
        toast.success('Bid status updated!');
        fetchRequests();
    };

    const handleBidStatusUpdate = (id) => {
        BidService.UpdateBidStatus(id, startLoader, handleStatusUpdateSuccess, handleError, stopLoader);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Bid Requests" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={requests.length} />
                        </span>
                    </div>
                    <DropdownComponent
                        name="customerId"
                        options={customers}
                        defaultValue={queryParamsData.customer ? queryParamsData.customer : customer}
                        onChange={(e) => {
                            setCustomer(e);
                            updateQueryParams('customer', JSON.stringify(e));
                        }}
                    />
                </div>
                <div>
                    <DropdownComponent
                        name="type"
                        options={[
                            { label: 'Current', value: 'current' },
                            { label: 'Past', value: 'past' },
                        ]}
                        defaultValue={queryParamsData.filter ? queryParamsData.filter : { label: 'Current', value: 'current' }}
                        onChange={(e) => {
                            setFilterType(e);
                            updateQueryParams('filter', JSON.stringify(e));
                        }}
                    />
                </div>
            </div>
            <Table
                head={['Start Date', 'End Date', 'No Bid Count', 'Created At', 'Updated At', 'Customer', 'Instance', 'Market', 'Status', 'Action']}
                keys={['startDate', 'endDate', 'noBid', 'createdAt', 'updatedAt', 'customer', 'instance', 'biddingMarket', 'status', 'action']}
                data={requests}
                page={page}
                Pagination={true}
                limit={LIMIT}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
            <ModalComponent isOpen={openRequestModal} setOpen={closeCreateEditModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestContent groups={groups} setOpen={closeCreateEditModal} setRefresh={setRefresh} licenseId={licenseId} editRequest={editRequest} />
            </ModalComponent>
            <ModalComponent isOpen={overviewModal.status} setOpen={closeOverviewModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestOverview request={overviewModal.request} setOpen={closeOverviewModal} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default BidRequests;
