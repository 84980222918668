import classes from '../../../../../styles/CapacityContent.module.css';
import React from 'react';
import Table from '../../../../../components/Table/Table';

const Status = ({ data, setOpen }) => {
    return (
        <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto' }}>
            <div className={classes.Title}>Status</div>
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Response Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['name', 'value']} data={data?.bidResponse || []}></Table>
                    </div>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Bid Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['productName', 'status']} data={data?.bidStatus || []}></Table>
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div className="btn-primary" onClick={() => setOpen(false)} style={{ minWidth: 'auto' }}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default Status;
