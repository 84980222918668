// icons
import BidIcon from '../assets/sidebar/bid.svg';
import DashboardIcon from '../assets/sidebar/Dashboard.svg';
import systemIcon from '../assets/sidebar/systemTool.svg';
import cfmIcon from '../assets/sidebar/Fleet_Management.svg';
import EdgeIcon from '../assets/Edge.svg';
import AdminIcon from '../assets/Admin.svg';
import triggerLogsIcon from '../assets/triggerLogsIcon.svg';
import globalConfigIcon from '../assets/globalConfigIcon.svg';
import EconomicsIcon from '../assets/sidebar/economic.svg';
import DocumentsIcon from '../assets/sidebar/documents.svg';
import BusinessWebsiteIcon from '../assets/sidebar/business website.svg';
import OverviewIcon from '../assets/sidebar/Overview.svg';
import MonitoringIcon from '../assets/Monitoring.svg';

// admin
import Home from '../containers/Home/Home';
import FCR from '../containers/Bidding/FCR';
import FFR from '../containers/Bidding/FFR';
import FCRD from '../containers/Bidding/FCR-D';
import FCRDMinus from '../containers/Bidding/FCR-DMinus';
import FCRN from '../containers/Bidding/FCR-N';
import DCPlanner from '../containers/Bidding/DCPlanner';
import AFRR from '../containers/Bidding/AFRR';
import MFRR from '../containers/Bidding/MFRR';
import SystemTools from '../containers/SystemTools';
import Edge from '../containers/Edge';
import Economics from '../containers/Economics/Economics';
import AdminDocuments from '../containers/Documents';

// root
import Admin from '../containers/Root/Admin/Admin';
import SystemToolsForRoot from '../containers/Root/SystemTools';
import GlobalConfig from '../containers/Root/GlobalConfig';
import RootEconomics from '../containers/Root/Economics/Economics';
import SystemLogs from '../containers/Root/SystemLogs/SystemLogs';
import Approval from '../containers/Bidding/ApprovalOverview';
import Documents from '../containers/Root/Documents';
import Content from '../containers/Root/Content';
import Monitoring from '../containers/Root/Monitoring/Monitoring';

// enabler
import EnablerEconomics from '../containers/Enabler/Economics/Economics';
import Bidding from '../containers/Root/Bidding';

export const PROTECTED_ROUTES = [
    {
        name: 'Customer',
        id: 'customer',
        url: '/',
        component: Home,
        icon: DashboardIcon,
    },
    {
        name: 'Edge',
        id: 'edge',
        url: '/edge',
        component: Edge,
        icon: EdgeIcon,
    },
    {
        name: 'Bidding',
        id: 'bidding',
        url: '/bidding',
        icon: BidIcon,
        childRoutes: [
            {
                name: 'Appr. Overview',
                id: 'approvalOverview',
                url: '/approval-overview',
                component: Approval,
                icon: OverviewIcon,
            },
            {
                name: 'FCR',
                id: 'fcr',
                url: '/fcr',
                component: FCR,
                icon: BidIcon,
            },
            {
                name: 'FFR',
                id: 'ffr',
                url: '/ffr',
                component: FFR,
                icon: BidIcon,
            },
            {
                name: 'FCR-D-INC',
                id: 'fcr-d-inc',
                url: '/fcr-d-inc',
                component: FCRD,
                icon: BidIcon,
            },
            {
                name: 'FCR-D-DEC',
                id: 'fcr-d-dec',
                url: '/fcr-d-minus',
                component: FCRDMinus,
                icon: BidIcon,
            },
            {
                name: 'FCR-N',
                id: 'fcr-n',
                url: '/fcr-n',
                component: FCRN,
                icon: BidIcon,
            },
            {
                name: 'dcPlanner',
                id: 'dcPlanner',
                url: '/dc-planner',
                component: DCPlanner,
                icon: BidIcon,
            },
            {
                name: 'aFRR',
                id: 'aFRR',
                url: '/afrr',
                component: AFRR,
                icon: BidIcon,
            },
            {
                name: 'mFRR',
                id: 'mFRR',
                url: '/mfrr',
                component: MFRR,
                icon: BidIcon,
            },
        ],
    },
    {
        name: 'System Tools',
        id: 'systemtool',
        url: '/systemtool',
        component: SystemTools,
        icon: systemIcon,
    },
    {
        name: 'eConomics',
        id: 'eConomics',
        url: '/eConomics',
        component: Economics,
        icon: EconomicsIcon,
    },
    {
        name: 'Documents',
        id: 'documents',
        url: '/documents',
        component: AdminDocuments,
        icon: DocumentsIcon,
    },
];

export const PROTECTED_ROUTES_ROOT = [
    {
        name: 'Accounts',
        id: 'admin',
        url: '/',
        component: Admin,
        icon: AdminIcon,
    },
    {
        name: 'Monitoring',
        id: 'monitoring',
        url: '/monitoring',
        component: Monitoring,
        icon: MonitoringIcon,
    },
    {
        name: 'System Tools',
        id: 'systemtool',
        url: '/systemtool',
        component: SystemToolsForRoot,
        icon: systemIcon,
    },
    {
        name: 'Bidding',
        id: 'bidding',
        url: '/bidding',
        icon: BidIcon,
        component: Bidding,
    },
    {
        name: 'System Logs',
        id: 'systemLogs',
        url: '/systemLogs',
        component: SystemLogs,
        icon: triggerLogsIcon,
    },
    {
        name: 'Global Config',
        id: 'globalConfig',
        url: '/globalConfig',
        component: GlobalConfig,
        icon: globalConfigIcon,
    },
    {
        name: 'eConomics',
        id: 'eConomics',
        url: '/eConomics',
        component: RootEconomics,
        icon: EconomicsIcon,
    },
    {
        name: 'Content',
        id: 'content',
        url: '/content',
        component: Content,
        icon: BusinessWebsiteIcon,
    },
];

export const PROTECTED_ROUTES_ENABLER = [
    {
        name: 'eConomics',
        id: 'eConomics',
        url: '/',
        component: EnablerEconomics,
        icon: EconomicsIcon,
    },
];
