import React from 'react';
import StatusFCRD from '../../../Bidding/FCR-D/Bids/ModalContent/Status';
import StatusFCRDDEC from '../../../Bidding/FCR-DMinus/Bids/ModalContent/Status';
import StatusFFR from '../../../Bidding/FFR/Bids/ModalContent/Status';
import StatusFCR from '../../../Bidding/FCR/Bids/ModalContent/Status';

export const StatusData = ({ market, data, setOpen }) => {
    const renderComponent = () => {
        switch (market) {
            case 'FCR-D-INC':
                return <StatusFCRD data={data} setOpen={setOpen} />;
            case 'FCR-D-DEC':
                return <StatusFCRDDEC data={data} setOpen={setOpen} />;
            case 'FFR':
                return <StatusFFR data={data} setOpen={setOpen} />;

            case 'FCR':
                return <StatusFCR data={data} setOpen={setOpen} />;

            default:
                return <>Status Not Found</>;
        }
    };
    return <div>{renderComponent()}</div>;
};
