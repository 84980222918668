import React from 'react';
import { useLoader } from '../../../../hooks';
import ResultIdFCRD from '../../../Bidding/FCR-D/Bids/ModalContent/ResultId';
import ResultIdFFR from '../../../Bidding/FFR/Bids/ModalContent/ResultId';
import ResultIdFCRDDEC from '../../../Bidding/FCR-DMinus/Bids/ModalContent/ResultId';

export const ResultId = ({ market, bid, fetchBids, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const renderComponent = () => {
        switch (market) {
            case 'FCR-D-INC':
                return <ResultIdFCRD bid={bid} setModal={setOpen} startLoader={startLoader} stopLoader={stopLoader} fetchBids={fetchBids} />;
            case 'FCR-D-DEC':
                return <ResultIdFCRDDEC bid={bid} setModal={setOpen} startLoader={startLoader} stopLoader={stopLoader} fetchBids={fetchBids} />;
            case 'FFR':
                return <ResultIdFFR bid={bid} setModal={setOpen} startLoader={startLoader} stopLoader={stopLoader} fetchBids={fetchBids} />;

            default:
                return <>Market Does Not Require Result ID to Fetch Results</>;
        }
    };
    return <div>{renderComponent()}</div>;
};
