import classes from '../../../styles/CreateDevice.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import { ToggleButton } from '../../../components/Inputs/Input';
import { toast } from 'react-toastify';
import { useLoader } from '../../../hooks';
import { CustomerService } from '../../../services/CustomerService';
import { LicenseService } from '../../../services/LicenseService';
import 'react-toggle/style.css'; // for ES6 modules
import MultiSelectDropdown from '../../../components/Inputs/MultiSelectDropdown';
import { LicenseTypeServiceAdmin } from '../../../services/licenseTypeService';
import _ from 'lodash';
import TabsComponent from '../../../components/Tabs/Tabs';
const CreateLicense = ({ setRefresh = null, customer, setOpen }) => {
    const [initialLicenseValues] = useState(customer.license);
    const [startLoader, stopLoader] = useLoader();
    const [allowedTypes, setAllowedTypes] = useState([]);

    useEffect(() => {
        getLicenseTypes();
    }, []);

    const getLicenseTypes = () => {
        LicenseTypeServiceAdmin.RealAll(startLoader, handleLicenseTypesSuccess, handleError, stopLoader);
    };

    const handleLicenseTypesSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = data.data.map((type) => ({ label: _.startCase(type.name), value: type.name })).filter((type) => !initialLicenseValues[type.value]?.id);
            setAllowedTypes(options);
        } else {
            setAllowedTypes([]);
        }
    };

    const handleSubmit = (values, resetForm) => {
        const types = [];
        values.licenseTypes &&
            values.licenseTypes.map((item) => {
                types.push(item.value);
            });
        if (types.length && customer._id) {
            let payload = {
                license: {
                    types,
                    enabled: true,
                    customerId: customer._id,
                },
            };
            LicenseService.Create(
                payload,
                () => startLoader('cl'),
                (data) => handleCreateSuccess(data, resetForm),
                handleError,
                () => stopLoader('cl')
            );
        }
    };

    const handleCreateSuccess = (data, resetForm) => {
        toast.success('License created!');
        resetForm();
        setRefresh && setRefresh(Math.random() * 3);
        setOpen(false);
    };

    const handleUpdate = (values) => {
        if (customer?._id != null) {
            CustomerService.UpdateLicense(customer._id, { customer: { license: values } }, startLoader, handleLicenseSuccess, handleError, stopLoader);
        }
    };

    const handleLicenseSuccess = (res) => {
        toast.success('License Updated!');
        setOpen(false);
        setRefresh && setRefresh(Date.now());
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const tabs = [
        {
            name: 'Manage License',
            component: (
                <div>
                    {Boolean(Object.keys(customer?.license || {}).filter((license) => customer?.license[license]?.id).length) && (
                        <>
                            <Typography content={'Available License'} />
                            <div className={classes.FormContent}>
                                <Formik initialValues={initialLicenseValues} onSubmit={handleUpdate}>
                                    {({ errors, touched, values, isValidating, ...props }) => {
                                        return (
                                            <Form>
                                                <div className="flex-box">
                                                    {Object.keys(customer?.license || {}).map((license, key) => (
                                                        <>
                                                            {customer?.license[license]?.id && (
                                                                <>
                                                                    <ToggleButton values={values} label={license} name={`${license}.enabled`} />
                                                                    {license === 'CFM' && <ToggleButton values={values} label={'Mobile Pay'} name={`${license}.mobilepay`} />}
                                                                </>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                                <div className={classes.ButtonContainer}>
                                                    <div>
                                                        <button type="submit" className="btn-primary">
                                                            Update
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </>
                    )}
                </div>
            ),
        },
        {
            name: 'Create Licence',
            component: (
                <div>
                    <Typography content={`License (${customer.name})`} />
                    <div className={classes.FormContent} style={{ padding: '0' }}>
                        <Formik enableReinitialize initialValues={{ licenseTypes: [null] }} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2} style={{ width: '20vw' }}>
                                                <label for="licenseTypes">License Type</label>
                                                <MultiSelectDropdown name="licenseTypes" options={allowedTypes} />
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className={classes.FormContainer} style={{ height: 'auto', overflow: 'initial' }}>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default CreateLicense;
