// Standard Library Imports
import React from 'react';

// External Library Imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal Module Imports
import { Input } from '../../../../../components/Inputs/Input';
import { FcrDMinusBidService } from '../../../../../services/FcrDMinusBidService';
import Typography from '../../../../../components/Typography/Typography';
import { FcrDMinusResultMessageValidator } from '../../../../../validations/Frequency/CreateBidValidation';

// CSS Imports
import classes from '../../../../../styles/BidModal.module.css';

const ResultId = ({ bid, setModal, startLoader, stopLoader, fetchBids }) => {
    const initialValues = {
        resultMessageId: bid?.resultMessageId ? bid.resultMessageId : '',
        resultMessageIdDMinus1: bid?.resultMessageIdDMinus1 ? bid.resultMessageIdDMinus1 : '',
        reserveBidIdentification: bid?.reserveBidIdentification ? bid.reserveBidIdentification : '',
    };

    const handleSubmit = (values) => {
        FcrDMinusBidService.AddResultId(bid._id, values, startLoader, handleSubmitSucess, handleError, stopLoader);
    };

    const handleSubmitSucess = () => {
        fetchBids();
        setModal(false);
        toast.success('Result Message ID updated successfully');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <Typography content="Result Message ID" size="16" />
            <div className={classes.FormContainer} style={{ width: '20vw' }}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={FcrDMinusResultMessageValidator(bid.dMinus2BidNotAvailable)}>
                    <Form>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Result Message ID (D-2)</label>
                            <Input name="resultMessageId" id="resultMessageId" type="number" />
                        </div>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageIdDMinus1">Result Message ID (D-1)</label>
                            <Input name="resultMessageIdDMinus1" id="resultMessageIdDMinus1" type="number" />
                        </div>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Reserve Bid Identification</label>
                            <Input name="reserveBidIdentification" id="reserveBidIdentification" type="text" />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="button" className="btn-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ResultId;
