import classes from '../../../styles/CreateDevice.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import { FieldArray, Form, Formik } from 'formik';
import { Input, InputFile, ToggleButton } from '../../../components/Inputs/Input';
import 'react-toggle/style.css'; // for ES6 modules
import { toast } from 'react-toastify';
import { useLoader } from '../../../hooks';
import { CreateCustomerValidator } from '../../../validations/Customer/CreateCustomer';
import { CustomerService } from '../../../services/CustomerService';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import { signalsServiceAdmin } from '../../../services/signalsService';
import { regulationsServiceAdmin } from '../../../services/regulationsService';
import { areaServiceAdmin } from '../../../services/areaService';
import { camelCase } from 'lodash';
import { metaDataServiceForAdmin } from '../../../services/metaDataServiceForAdmin';

import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { GrCircleInformation } from 'react-icons/gr';
import moment from 'moment';
import Dropdown from '.././../../components/Inputs/Dropdown';
import PhoneNumberComponent from '../../../components/Inputs/PhoneNumberComponent';
import FileUpload from '../../../components/Inputs/FileUpload/FileUpload';
import { LOCALES } from '../../../constants';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';

const getInitialValues = (customerdata = {}) => {
    if (customerdata?._id) {
        const biddingMarkets = {};
        const areas = {};
        const frequency = {};
        customerdata?.biddingMarkets?.forEach((market) => (biddingMarkets[market] = true));
        customerdata?.areas?.forEach((area) => (areas[area] = true));
        customerdata?.frequency?.forEach((freq) => (frequency[freq] = true));
        return {
            _id: customerdata._id,
            address: {
                // street: values.address.street,
                zip: customerdata?.address?.zip,
                city: customerdata?.address?.city,
                contact: customerdata?.address?.contact,
                address1: customerdata?.address?.address1,
                address2: customerdata?.address?.address2,
                primaryContactName: customerdata?.address?.primaryContactName,
                primaryContactEmail: customerdata?.address?.primaryContactEmail,
                billingEmail: customerdata?.address?.billingEmail,
                countryCode: customerdata?.address?.countryCode,
                emergencyContact: customerdata?.address?.emergencyContact || [{
                    name: '',
                    email: '',
                    contactNumber: '',
                    countryCode: '',
                }]
            },
            companyName: customerdata?.companyName,
            dataMonitoring: customerdata?.dataMonitoring,
            name: customerdata?.name,
            vat: customerdata?.vat,
            areas,
            frequency,
            biddingMarkets,
            trueGreen: {
                enabled: customerdata?.trueGreen?.enabled,
            },
            theme: {
                primaryColor: customerdata?.theme?.primaryColor,
            },
            removeLogo: false,
            timezone: customerdata?.timezone,
            locales: customerdata?.locales,
            isActive: customerdata?.isActive,
            customerCode: customerdata?.customerCode,
        };
    } else {
        return {
            companyName: '',
            address: {
                zip: '',
                city: '',
                contact: '',
                address1: '',
                address2: '',
                primaryContactName: '',
                primaryContactEmail: '',
                billingEmail: '',
                countryCode: '',
                emergencyContact: customerdata?.address?.emergencyContact || [{
                    name: '',
                    email: '',
                    contactNumber: '',
                    countryCode: '',
                }]
            },
            dataMonitoring: false,
            name: '',
            vat: '',
            areas: {},
            biddingMarkets: {},
            trueGreen: {
                enabled: false,
            },
            theme: {
                primaryColor: '#0f123f',
            },
            timezone: '',
            locales: '',
            isActive: true,
        };
    }
};

const getTimezonesOptions = () => {
    const timezone = moment.tz.names();
    let temp = [];
    timezone.forEach((element) => {
        temp.push({
            value: element,
            label: element,
        });
    });
    return temp;
};

const CreateCustomer = ({ customerdata, setRefresh = null, setSelectedTab }) => {
    const [startLoader, stopLoader] = useLoader();
    const [regualtions, setRegulations] = useState([]);
    const [areas, setAreas] = useState([]);
    const [logo, setLogo] = useState([]);
    const [signalMap, setSignalMap] = useState({});
    const timezones = getTimezonesOptions();

    const [initialValues, setInitialValues] = useState(getInitialValues(customerdata));

    useEffect(() => {
        signalsServiceAdmin.ReadAllSignalsAndCustomerSignal(customerdata._id, startLoader, handleSignalSuccess, handleError, stopLoader);
        let params = {
            type: 'priceSignal',
        };
        metaDataServiceForAdmin.readAllSignal(params, startLoader, handleSignalSuccess, handleError, stopLoader);
        regulationsServiceAdmin.RealAll(startLoader, handleRegulationSuccess, handleError, stopLoader);
        let frequencyparams = {
            type: 'frequency',
        };
        metaDataServiceForAdmin.readAllSignal(frequencyparams, startLoader, handleRegulationSuccess, handleError, stopLoader);
        areaServiceAdmin.RealAll(startLoader, handleAreaSuccess, handleError, stopLoader);
    }, []);

    const handleAreaSuccess = ({ data }) => {
        const areaCur = [];
        const areas = {};
        data?.data?.forEach((ar) => {
            areaCur.push(ar.name);
            areas[ar.name] = false;
        });
        setAreas(areaCur);
        setInitialValues((prev) => ({
            ...prev,
            areas: {
                ...areas,
                ...prev.areas,
            },
        }));
    };

    const handleRegulationSuccess = ({ data }) => {
        const regulationsCur = [];
        const biddingMarkets = {};
        data?.data?.forEach((reg) => {
            regulationsCur.push(reg.name);
            biddingMarkets[reg.name] = false;
        });
        setRegulations(regulationsCur);
        setInitialValues((prev) => ({
            ...prev,
            biddingMarkets: {
                ...biddingMarkets,
                ...prev.biddingMarkets,
            },
        }));
    };

    const handleSignalSuccess = ({ data }) => {
        const map = {};
        data?.data?.forEach((signal) => {
            if (map[signal.type]) {
                map[signal.type].push(signal);
            } else {
                map[signal.type] = [signal];
            }
        });
        // setSignals(signalsArray);
        setSignalMap(map);
        setInitialValues((prev) => {
            const deMapping = {};
            Object.keys(map).forEach((type) => {
                const key = camelCase(type);
                const curMap = {};
                const selectedFeilds = customerdata?._id ? customerdata[key] || [] : [];
                map[type].forEach((signal) => {
                    if (selectedFeilds.includes(signal._id)) curMap[signal._id] = true;
                    else curMap[signal._id] = false;
                });
                deMapping[key] = curMap;
            });
            return {
                ...prev,
                ...deMapping,
            };
        });
    };

    const handleSubmit = (values, resetForm) => {
        const biddingMarkets = Object.keys(values?.biddingMarkets || {}).filter((key) => values?.biddingMarkets[key]);

        const areas = Object.keys(values?.areas || {}).filter((key) => values?.areas[key]);

        const signalMapValues = {};
        Object.keys(signalMap).forEach((type) => {
            const key = camelCase(type);
            signalMapValues[key] = Object.keys(values[key]).filter((signal) => values[key][signal]);
        });

        const customerData = {
            ...signalMapValues,
            address: {
                // street: values.address.street,
                zip: values?.address?.zip?.length > 0 ? values?.address?.zip : undefined,
                city: values?.address?.city?.length > 0 ? values?.address?.city : undefined,
                contact: values?.address?.contact?.length > 0 ? values?.address?.contact : undefined,
                address1: values?.address?.address1?.length > 0 ? values?.address?.address1 : undefined,
                address2: values?.address?.address2?.length > 0 ? values?.address?.address2 : undefined,
                primaryContactName: values?.address?.primaryContactName?.length > 0 ? values?.address?.primaryContactName : undefined,
                primaryContactEmail: values?.address?.primaryContactEmail?.length > 0 ? values?.address?.primaryContactEmail : undefined,
                billingEmail: values?.address?.billingEmail?.length > 0 ? values?.address?.billingEmail : undefined,
                countryCode: values?.address?.countryCode?.length > 0 ? values?.address?.countryCode : undefined,

                emergencyContact: values?.address?.emergencyContact.length > 0 ? values?.address?.emergencyContact : undefined
            },
            companyName: values?.companyName,
            name: values?.name,
            vat: values?.vat,
            dataMonitoring: values?.dataMonitoring,
            areas,
            biddingMarkets,
            trueGreen: {
                enabled: values?.trueGreen?.enabled,
            },
            theme: {
                primaryColor: values?.theme?.primaryColor,
            },
            timezone: values?.timezone,
            locales: values?.locales,
            isActive: values?.isActive,
            customerCode: values?.customerCode,
        };

        const formData = new FormData();
        const isLogoImageValid = /(jpg|png|peg|svg)$/.test(logo?.name?.slice(-4));
        if (logo.name && !isLogoImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('logo', logo);
        formData.append('customer', JSON.stringify(customerData));
        formData.append('removeLogo', values.removeLogo);

        if (customerdata?._id) {
            CustomerService.Update(customerdata._id, formData, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            CustomerService.Create(
                formData,
                startLoader,
                (data) => {
                    handleCreateSuccess(data, resetForm);
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleCreateSuccess = (data, resetForm) => {
        setRefresh(Math.random() * 3);
        toast.success('Customer created!');
        setSelectedTab(0);
        resetForm();
    };

    const handleUpdateSuccess = (data) => {
        setRefresh(Math.random() * 3);
        toast.success('Customer updated!');
        setSelectedTab(0);
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setLogo(file);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div className={classes.FormContainer}>
            <Typography content={!customerdata?._id ? 'Create Customer' : 'Edit Customer'} />
            <div className={classes.FormContent}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={CreateCustomerValidator(signalMap, areas)}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="companyName">
                                            Company Name <span className="required">*</span>
                                        </label>
                                        <Input name="companyName" id="companyName" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="customerCode">
                                            Customer Code <span className="required">*</span>
                                        </label>
                                        <Input
                                            name="customerCode"
                                            id="customerCode"
                                            //disabled={customerdata?._id ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className={classes.Title}>Address</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="address1">Address1</label>
                                        <Input name="address.address1" id="address1" type="text" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="address2">Address2</label>
                                        <Input name="address.address2" id="address2" type="text" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="City">City</label>
                                        <Input name="address.city" id="City" type="text" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="zip">Zip</label>
                                        <Input name="address.zip" id="zip" type="text" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="contact">Contact</label>
                                        <div>
                                            <PhoneNumberComponent
                                                inputName="address.contact"
                                                countryCodeName="address.countryCode"
                                                inputValues={values}
                                                defaultCountryCode={{
                                                    value: initialValues?.address?.countryCode,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="primaryContactName">Primary Contact Name</label>
                                        <Input name="address.primaryContactName" id="primaryContactName" type="text" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="primaryContactEmail">Primary Contact Email</label>
                                        <Input name="address.primaryContactEmail" id="primaryContactEmail" type="text" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="billingEmail">Billing Email</label>
                                        <Input name="address.billingEmail" id="billingEmail" type="text" />
                                    </div>
                                </div>

                                <FieldArray name="address.emergencyContact">
                                    {({ push, remove, form }) => {
                                        const emergencyContacts = form.values.address?.emergencyContact;
                                        return (
                                            <div>
                                                <div>
                                                    <div className={classes.InputContainer}>
                                                        <div className={classes.FieldControl2}>
                                                            <div className={classes.Title}>Emergency Contact</div>
                                                            {emergencyContacts.map((emergencyContact, index) => (
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="name">Emergency Contact Name <span className="required">*</span></label>
                                                                        <Input name={`address.emergencyContact[${index}].name`} id="name" type="text" />
                                                                    </div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="contact">Emergency Contact Number <span className="required">*</span></label>
                                                                        <div>
                                                                            {console.log("log", values)}
                                                                            <PhoneNumberComponent
                                                                                inputName={`address.emergencyContact[${index}].contactNumber`}
                                                                                countryCodeName={`address.emergencyContact[${index}].countryCode`}
                                                                                inputValues={values}
                                                                                defaultCountryCode={{
                                                                                    value: values?.address?.emergencyContact?.[index]?.countryCode || '',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="emergencyContactEmail">Emergency Contact Email </label>
                                                                        <Input
                                                                            name={`address.emergencyContact[${index}].email`}
                                                                            id="email" type="text" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classes.addInputActionWrapper}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row-reverse',
                                                        gap: '0.5vw',
                                                        paddingTop: '1%',
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            push('');
                                                        }}
                                                    >
                                                        <UilPlusCircle
                                                            size={'1.5vw'}
                                                            style={{
                                                                color: 'var(--color-primary)',
                                                                cursor: 'pointer',
                                                                zIndex: '1',
                                                            }}
                                                        />
                                                    </span>
                                                    {emergencyContacts.length > 1 && (
                                                        <span onClick={() => remove(emergencyContacts.length - 1)}>
                                                            <UilMinusCircle
                                                                size={'1.5vw'}
                                                                style={{
                                                                    color: 'var(--color-primary)',
                                                                    cursor: 'pointer',
                                                                    zIndex: '1',
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>

                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="vat">
                                            Vat <span className="required">*</span>
                                        </label>
                                        <Input name="vat" id="vat" type="text" />
                                    </div>
                                </div>
                                <div className={classes.Title}>Region Config</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="timezone">
                                            Timezone <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="timezone"
                                            options={timezones}
                                            defaultValue={{
                                                label: customerdata.timezone,
                                                value: customerdata.timezone,
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="locales">
                                            Locales <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="locales"
                                            options={LOCALES}
                                            defaultValue={{
                                                label: customerdata.locales,
                                                value: customerdata.locales,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className={classes.Title}>Bidding Markets</div>
                                <div className={classes.InputContainer}>
                                    {regualtions.map((regulation, key) => (
                                        <div className={classes.FieldControl} key={key}>
                                            <ToggleButton values={values} label={regulation} name={`biddingMarkets.${regulation}`} />
                                        </div>
                                    ))}
                                </div>
                                <div className={classes.Title}>Area</div>
                                <div className={classes.InputContainer}>
                                    {areas.map((signal, key) => (
                                        <div className={classes.FieldControl} key={key}>
                                            <ToggleButton values={values} label={signal} name={`areas.${signal}`} />
                                        </div>
                                    ))}
                                </div>
                                {Object.keys(signalMap).map((type) => (
                                    <>
                                        <div className={classes.Title}>{type}</div>
                                        <div className={classes.InputContainer}>
                                            {signalMap[type].map((signal, key) => (
                                                <div className={classes.FieldControl} key={key}>
                                                    <ToggleButton values={values} label={`${signal.name}`} name={`${camelCase(type)}.${signal._id}`} />
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ))}
                                <div className={classes.Title}>Alert</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <ToggleButton values={values} label={'Data Monitoring'} name={`dataMonitoring`} />
                                    </div>
                                </div>
                                <div className={classes.Title}>True Green</div>
                                <ToggleButton values={values} label={'True Green'} name={`trueGreen.enabled`} />
                                <div className={classes.Title}>Theme</div>
                                <ColorPicker name={'theme.primaryColor'} title={'Primary Color'} />
                                <div className={classes.Title}>Settings</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <ToggleButton values={values} label={'Is Active'} name={`isActive`} />
                                    </div>
                                </div>
                                <div>&#8205;</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label for="profileImage" style={{ marginRight: '0.4rem', marginLeft: '0.2rem' }}>
                                                Logo
                                            </label>
                                            <CustomTooltip content={'Try uploading Logo of size 468 x 280 - 2560 x 1440'}>
                                                <GrCircleInformation size={13} />
                                            </CustomTooltip>
                                        </div>
                                        <FileUpload name="logo" id="profileImage" accept="image/*" onChange={handleUploadClick} />
                                    </div>
                                    <div className={classes.FieldControl}></div>
                                </div>
                                <div className={classes.RMLogoWrapper}>
                                    {customerdata?.logo && (
                                        <>
                                            <ToggleButton values={values} label={'Remove Logo'} name={`removeLogo`} />
                                            <div className={classes.LogoWrapper}>
                                                <img src={customerdata?.logo} height={50} />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateCustomer;
