import React from 'react';
import BiddingInfoContentFCRD from '../../../Bidding/FCR-D/Bids/ModalContent/BiddingInfoContent';
import BiddingInfoContentFCRDDEC from '../../../Bidding/FCR-DMinus/Bids/ModalContent/BiddingInfoContent';
import BiddingInfoContentFFR from '../../../Bidding/FFR/Bids/ModalContent/BiddingInfoContent';
import BiddingInfoContentFCR from '../../../Bidding/FCR/Bids/ModalContent/BiddingInfoContent';

export const BidInfo = ({ market, bid, fetchBids, setOpen, openReverseBid = false }) => {
    const renderComponent = () => {
        const hourlyModalData = {
            bidStatus: bid.bidStatus || [],
            bidId: bid._id,
            reverseReason: bid.reverseReason,
            reverseBid: bid.reverseBid,
            openReverseBid,
        };
        const priceModalData = bid.price || [];
        const capacityModalData = bid.capacity || [];
        switch (market) {
            case 'FCR-D-INC':
                return (
                    <BiddingInfoContentFCRD
                        hourlyModalData={hourlyModalData}
                        priceModalData={priceModalData}
                        capacityModalData={capacityModalData}
                        setOpen={setOpen}
                        fetchBids={fetchBids}
                    />
                );
            case 'FCR-D-DEC':
                return (
                    <BiddingInfoContentFCRDDEC
                        hourlyModalData={hourlyModalData}
                        priceModalData={priceModalData}
                        capacityModalData={capacityModalData}
                        setOpen={setOpen}
                        fetchBids={fetchBids}
                    />
                );
            case 'FFR':
                return (
                    <BiddingInfoContentFFR
                        hourlyModalData={hourlyModalData}
                        priceModalData={priceModalData}
                        capacityModalData={capacityModalData}
                        setOpen={setOpen}
                        fetchBids={fetchBids}
                    />
                );

            case 'FCR':
                return (
                    <BiddingInfoContentFCR
                        hourlyModalData={hourlyModalData}
                        priceModalData={priceModalData}
                        capacityModalData={capacityModalData}
                        setOpen={setOpen}
                        fetchBids={fetchBids}
                    />
                );

            default:
                return <>Bid Info Not Found</>;
        }
    };
    return <div>{renderComponent()}</div>;
};
