import classes from '../../../../../styles/BidModal.module.css';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Input } from '../../../../../components/Inputs/Input';
import Typography from '../../../../../components/Typography/Typography';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { CreateBidValidation } from '../../../../../validations/Frequency/CreateBidValidation';
import { FcrBidService } from '../../../../../services/FcrBidService';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { momentTimeFormater } from '../../../../../utils/dateHelper';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const BidContent = ({ setOpen, startLoader, stopLoader, fetchBids, customerId, bidDate, group, updateQueryParams }) => {
    const [initialValues, setInitialValues] = useState({
        slot1: '0.9',
        slot2: '0.9',
        slot3: '0.9',
        slot4: '0.9',
        slot5: '0.9',
        slot6: '0.9',
        slot1Price: '14',
        slot2Price: '14',
        slot3Price: '14',
        slot4Price: '14',
        slot5Price: '14',
        slot6Price: '14',
        groupId: group.label,
    });

    const [date, setDate] = useState(bidDate ? new Date(moment(bidDate).format('YYYY-MM-DD')) : new Date());
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);

    useEffect(() => {
        updateQueryParams('bidDate', date);
    }, [date]);

    useEffect(() => {
        if (customerId) {
            FcrBidService.getLastFcrBid({ customerId, groupId: group.value }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    }, []);

    useEffect(() => {
        if (customerId) {
            fetchMaintenanceHourForADate();
        }
    }, [date]);

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity, date, price } = data.data;
        if (capacity) {
            setInitialValues({
                slot1: capacity[0],
                slot2: capacity[4],
                slot3: capacity[8],
                slot4: capacity[12],
                slot5: capacity[16],
                slot6: capacity[20],
                slot1Price: price[0],
                slot2Price: price[4],
                slot3Price: price[8],
                slot4Price: price[12],
                slot5Price: price[16],
                slot6Price: price[20],
            });
        } else {
            setInitialValues({
                slot1: '0.9',
                slot2: '0.9',
                slot3: '0.9',
                slot4: '0.9',
                slot5: '0.9',
                slot6: '0.9',
                slot1Price: '14',
                slot2Price: '14',
                slot3Price: '14',
                slot4Price: '14',
                slot5Price: '14',
                slot6Price: '14',
            });
        }
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours() + 25);
        if (!bidDate) {
            setDate(tempDate);
        }
    };

    const handleSubmit = (values) => {
        let params = {
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            customerId,
        };
        values.groupId = group.value;
        let payload = { fcrBid: values };
        FcrBidService.Create(params, payload, startLoader, (res) => handleCreateSuccess(res), handleError, stopLoader);
    };

    const handleCreateSuccess = (res) => {
        toast.success('Bid Created!');
        const currentDate = moment();
        if (group?.approvalValidity?.endDate) {
            if (moment(group?.approvalValidity?.endDate).diff(currentDate, 'days') <= 30) {
                toast.warn(`Approval expires on ${momentTimeFormater(group.approvalValidity.endDate).format('YYYY-MM-DD')}`);
            }
        }
        fetchBids();
        setOpen(false);
    };

    const GetBidRequestForADate = () => {
        FcrBidService.GetBidRequestForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            startLoader,
            handlebidRequestSuccess,
            handleError,
            stopLoader
        );
    };

    const fetchMaintenanceHourForADate = () => {
        FcrBidService.GetMaintenanceHoursForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            () => startLoader('maintenance'),
            handleMaintenanceSuccess,
            handleError,
            () => stopLoader('maintenance')
        );
    };

    const handleMaintenanceSuccess = ({ data }) => {
        if (data.data?.hourSlots) setMaintenanceSlots(data.data?.hourSlots);
        else setMaintenanceSlots([]);
    };

    const handlebidRequestSuccess = ({ data }) => {
        if (data.data) {
            setInitialValues({
                slot1: data.data.capacity[0],
                slot2: data.data.capacity[1],
                slot3: data.data.capacity[2],
                slot4: data.data.capacity[3],
                slot5: data.data.capacity[4],
                slot6: data.data.capacity[5],
                slot1Price: data.data.price[0],
                slot2Price: data.data.price[1],
                slot3Price: data.data.price[2],
                slot4Price: data.data.price[3],
                slot5Price: data.data.price[4],
                slot6Price: data.data.price[5],
            });
        } else {
            toast.error('No request found');
        }
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div>
            <Typography content="FCR Bidding" size="16" />
            <div className={classes.Subtitle} style={{ margin: 0 }}>
                Red-bordered hour slots signify customer-requested maintenance
                <span className="required">*</span>
            </div>
            <div className={classes.FormContainer}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateBidValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.Subtitle}>Capacity(Mw)</div>
                                <div>
                                    <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                        Fill from bid request
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot1">
                                                Slot 1 / Hour 0-4<span className="required">*</span>
                                            </label>
                                            <Input name="slot1" id="slot1" style={maintenanceSlots.includes(1) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot2">
                                                Slot 2 / Hour 4-8<span className="required">*</span>
                                            </label>
                                            <Input name="slot2" id="slot2" style={maintenanceSlots.includes(2) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot3">
                                                Slot 3 / Hour 8-12<span className="required">*</span>
                                            </label>
                                            <Input name="slot3" id="slot3" style={maintenanceSlots.includes(3) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot4">
                                                Slot 4 / Hour 12-16<span className="required">*</span>
                                            </label>
                                            <Input name="slot4" id="slot4" style={maintenanceSlots.includes(4) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot5">
                                                Slot 5 / Hour 16-20<span className="required">*</span>
                                            </label>
                                            <Input name="slot5" id="slot5" style={maintenanceSlots.includes(5) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot6">
                                                Slot 6 / Hour 20-24<span className="required">*</span>
                                            </label>
                                            <Input name="slot6" id="slot6" style={maintenanceSlots.includes(6) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Price(€)</div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot1Price">
                                                Slot 1 / Hour 0-4 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot1Price" id="slot1Price" style={maintenanceSlots.includes(1) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot2Price">
                                                Slot 2 / Hour 4-8 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot2Price" id="slot2Price" style={maintenanceSlots.includes(2) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot3Price">
                                                Slot 3 / Hour 8-12 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot3Price" id="slot3Price" style={maintenanceSlots.includes(3) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot4Price">
                                                Slot 4 / Hour 12-16 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot4Price" id="slot4Price" style={maintenanceSlots.includes(4) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot5Price">
                                                Slot 5 / Hour 16-20 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot5Price" id="slot5Price" style={maintenanceSlots.includes(5) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot6Price">
                                                Slot 6 / Hour 20-24 Price
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="slot6Price" id="slot6Price" style={maintenanceSlots.includes(6) ? maintenanceSlotCss : {}} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label>
                                            Date <span className="required">*</span>
                                        </label>
                                        <div className="modal-date-picker">
                                            <DatePicker date={date} setDate={setDate} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="groupId">Group</label>
                                            <Input name="groupId" id="groupId" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default BidContent;
