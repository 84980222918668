import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/AllDevices.module.css';
import { DropdownComponent, ToggleButton } from '../../../../components/Inputs/Input';
import { toast } from 'react-toastify';
import bidClasses from '../../../../styles/BidModal.module.css';
import { CustomerService } from '../../../../services/CustomerService';
import '../index.module.css';
import Dropdown from '../../../../components/Inputs/Dropdown';

const getInitialValues = (customer) => {
    if (customer?.cfmDashboardConfig) {
        const { cfmDashboardConfig } = customer;
        const data = { ...cfmDashboardConfig };
        return data;
    } else {
        return {
            enableFirstRow: true,
            r1c1: { value: true },
            r1c2: { value: true },
            r1c3: { value: true },
            enableGridConnection: true,
            enablePowerCost: true,
            enableChargeConnectError: true,
        };
    }
};

const CfmDashboardConfig = ({ customer, startLoader, stopLoader, setRefresh, setOpen }) => {
    const [initialValues] = useState(getInitialValues(customer));
    const [r1c1Options] = useState([
        { label: 'Hide', value: false },
        { label: 'Show', value: true },
    ]);
    const [r1c2Options] = useState([
        { label: 'Hide', value: false },
        { label: 'Show', value: true },
    ]);
    const [r1c3Options] = useState([
        { label: 'Hide', value: false },
        { label: 'Show', value: true },
    ]);
    const handleSubmit = (values) => {
        const cfmDashboardConfig = {
            enableFirstRow: values.enableFirstRow,
            r1c1: values.r1c1,
            r1c2: values.r1c2,
            r1c3: values.r1c3,
            enableGridConnection: values.enableGridConnection,
            enablePowerCost: values.enablePowerCost,
            enableChargeConnectError: values.enableChargeConnectError,
        };
        CustomerService.UpdateCfmDashboardConfig(customer._id, cfmDashboardConfig, startLoader, handleSuccess, handleError, stopLoader);
    };
    const handleSuccess = (res) => {
        toast.success('Config Updated!');
        setRefresh && setRefresh(Date.now());
        setOpen(false);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div style={{ minWidth: '40vw' }}>
            <Typography content={`CFM Dashbaord Config (${customer.name})`} />
            <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} enableReinitialize>
                {({ errors, touched, values, isValidating, ...props }) => {
                    return (
                        <Form>
                            <div>
                                <ToggleButton values={values} label={'Enable First Row'} name={`enableFirstRow`} />
                                {values.enableFirstRow && (
                                    <div>
                                        <div className={bidClasses.InputContainer}>
                                            <div className={bidClasses.FieldControl2}>
                                                <label>Frequency Card</label>
                                                <Dropdown
                                                    name="r1c1.value"
                                                    options={r1c1Options}
                                                    defaultValue={customer?.cfmDashboardConfig?.r1c1?.value === false ? r1c1Options[0] : r1c1Options[1]}
                                                />
                                            </div>
                                            <div className={bidClasses.FieldControl2}>
                                                <label>Power Management Card</label>
                                                <Dropdown
                                                    name="r1c2.value"
                                                    options={r1c2Options}
                                                    defaultValue={customer?.cfmDashboardConfig?.r1c2?.value === false ? r1c2Options[0] : r1c2Options[1]}
                                                />
                                            </div>
                                            <div className={bidClasses.FieldControl2}>
                                                <label>Load Card</label>
                                                <Dropdown
                                                    name="r1c3.value"
                                                    options={r1c3Options}
                                                    defaultValue={customer?.cfmDashboardConfig?.r1c3?.value === false ? r1c3Options[0] : r1c3Options[1]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <ToggleButton values={values} label={'Enable Grid Connection'} name={`enableGridConnection`} />

                                <ToggleButton values={values} label={'Enable Power Cost'} name={`enablePowerCost`} />
                                <ToggleButton values={values} label={'Enable (Charge, Connect, Error)'} name={`enableChargeConnectError`} />
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CfmDashboardConfig;
