// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import classes from './index.module.css';
function DeatailsModal({ data, setOpen, text = '' }) {
    return (
        <div className={classes.DeatailsModal}>
            <div className={classes.Heading}>{text}</div>
            <div className={classes.KeyValueContainer}>
                {Object.keys(data).map((item) => (
                    <div className={classes.keyVal}>
                        <div className={classes.key}>
                            <div>{item} :</div>
                        </div>
                        <div className={classes.value}>
                            <div>{data[item]}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={classes.ButtonContainer}>
                <div></div>
                <div
                    className={'btn-primary'}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </div>
            </div>
        </div>
    );
}

export default DeatailsModal;
