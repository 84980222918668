import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
// import { CFMServices } from '../../../services/CfmServices';
import moment from 'moment-timezone';
import { LogsService } from '../../../../services/LogsService';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../hooks/use-loader.hook';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { UilInfoCircle } from '@iconscout/react-unicons';

const ErrorLogsTable = () => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        fetchData();
    }, [skip, limit]);

    const fetchData = () => {
        let params = {
            limit,
            skip,
        };

        LogsService.Error(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data.data.map((item, index) => ({
            ...item,
            createdAt: moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
            path: <CustomTooltip content={item.path || 'Empty path'}>{item.path?.slice(-50)}</CustomTooltip>,
            action: (
                <CustomTooltip content={item.stack || 'Stack is empty'}>
                    <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                </CustomTooltip>
            ),
        }));
        setTotalCount(data.data.count);
        setData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content={'Error Logs'} />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount} />
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['Message', 'Path', 'Type', 'Created At', 'Action']}
                    keys={['message', 'path', 'type', 'createdAt', 'action']}
                    data={data}
                    page={page}
                    Pagination={true}
                    limit={limit}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default ErrorLogsTable;
