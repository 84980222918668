import React from 'react';
import Typography from '../../../../../components/Typography/Typography';
import classes from '../../../../../styles/BidModal.module.css';
import { Form, Formik } from 'formik';
import { FfrResultMessageValidator } from '../../../../../validations/Frequency/CreateBidValidation';
import { Input } from '../../../../../components/Inputs/Input';
import { FfrBidService } from '../../../../../services/FfrBidService';
import { toast } from 'react-toastify';

const ResultId = ({ bid, setModal, startLoader, stopLoader, fetchBids }) => {
    const initialValues = {
        resultMessageId: bid?.resultMessageId ? bid.resultMessageId : '',
        reserveBidIdentification: bid?.reserveBidIdentification ? bid.reserveBidIdentification : '',
    };

    const handleSubmit = (values) => {
        FfrBidService.AddResultId(bid._id, values, startLoader, handleSubmitSucess, handleError, stopLoader);
    };

    const handleSubmitSucess = ({ res }) => {
        fetchBids();
        setModal(false);
        toast.success('Result Message ID updated successfully');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <Typography content="Result Message ID" size="16" />
            <div className={classes.FormContainer} style={{ width: '20vw' }}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={FfrResultMessageValidator}>
                    <Form>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Result Message ID</label>
                            <Input name="resultMessageId" id="resultMessageId" type="number" />
                        </div>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Reserve Bid Identification</label>
                            <Input name="reserveBidIdentification" id="reserveBidIdentification" type="text" />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="button" className="btn-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ResultId;
