// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilPen } from '@iconscout/react-unicons';
import moment from 'moment';
import _ from 'lodash';
// Internal module imports
import { useLoader } from '../../../../hooks';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../../styles/Bids.module.css';
import { BiddingService } from '../../../../services/BiddingService';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import Checkbox from '../../../../components/Inputs/Checkbox/Checkbox';
import mainBiddingClasses from '../index.module.css';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import EditBidContent from './EditBid';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';

const BulkBid = ({ adminId, customerId, market, bidDate, setBulkBidModal }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(moment(bidDate).toDate());
    const [bidsData, setBidsData] = useState([]);
    const [isBids, setIsBids] = useState([]);
    const [editBidModal, setEditBidModal] = useState({ status: false, bid: {} });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (adminId && customerId && market) {
            const params = {
                market: market,
                bidDate: moment(date).format('YYYY-MM-DD'),
                adminId: adminId,
                customerId: customerId,
            };

            BiddingService.GetAllBids(
                params,
                () => startLoader('get bids'),
                handleBidsSuccess,
                handleError,
                () => stopLoader('get bids')
            );
        }
    };

    const getCheckedBid = (e, bid) => {
        setBidsData((prev) =>
            prev.map((item) =>
                item._id === bid._id
                    ? {
                          ...item,
                          isChecked: e,
                          select: (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Checkbox onChange={(e) => getCheckedBid(e, { ...item, isChecked: e })} checked={e} />
                              </div>
                          ),
                      }
                    : item
            )
        );
    };

    const handleBidsSuccess = ({ data }) => {
        const processedData = data?.data?.map((item) => {
            return {
                ...item,
                select: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox onChange={(e) => getCheckedBid(e, item)} checked={item?.isChecked} />
                    </div>
                ),
                customerName: item.customerData.name,
                license: item?.licenseType.name,
                instance: item?.instanceData.name,
                averageCapacity: item.capacity ? _.mean(item.capacity).toFixed(2) : '--',
                action: (
                    <div>
                        <CustomTooltip content={'Edit'}>
                            <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setEditBidModal({ status: true, bid: item })} />
                        </CustomTooltip>
                    </div>
                ),
            };
        });
        setBidsData(processedData);
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handleSubmit = () => {
        const temp = [];
        bidsData.map((i) => {
            if (i.isChecked) {
                temp.push({
                    customerId: i.customerId,
                    instanceId: i.instanceId,
                    capacity: i.capacity,
                    price: i.price,
                    ...(market !== 'FCR' ? { reserveBidIdentification: i.reserveBidIdentification } : {}),
                });
            }
        });

        const payload = {
            market: market,
            bidDate: moment(date).format('YYYY-MM-DD'),
            bidsData: temp,
        };

        BiddingService.CreateBulkBids(payload, startLoader, handleCreateBidsSuccess, handleError, stopLoader);
    };

    const handleCreateBidsSuccess = ({ data }) => {
        if (data?.data?.message === 'Bids already created') {
            toast.error('Bids already created');
            setIsBids(data?.data?.isBids);
        } else {
            toast.success('Bids created');
            setBulkBidModal({ status: false });
        }
    };

    const editBid = (updatedBid) => {
        setBidsData((prev) =>
            prev.map((item) =>
                item._id === updatedBid._id
                    ? {
                          ...item,
                          capacity: updatedBid.capacity,
                          price: updatedBid.price,
                          ...(market !== 'FCR' ? { reserveBidIdentification: updatedBid.reserveBidIdentification } : {}),
                          action: (
                              <div>
                                  <CustomTooltip content={'Edit'}>
                                      <UilPen
                                          size={'1.2vw'}
                                          style={{ color: 'var(--color-primary)' }}
                                          onClick={() =>
                                              setEditBidModal({
                                                  status: true,
                                                  bid: {
                                                      ...item,
                                                      capacity: updatedBid.capacity,
                                                      price: updatedBid.price,
                                                      ...(market !== 'FCR' ? { reserveBidIdentification: updatedBid.reserveBidIdentification } : {}),
                                                  },
                                              })
                                          }
                                      />
                                  </CustomTooltip>
                              </div>
                          ),
                      }
                    : item
            )
        );
    };

    return (
        <div className={mainBiddingClasses.MainModalContainer}>
            <ModalComponent
                isOpen={editBidModal.status}
                setOpen={() => {
                    setEditBidModal({ status: false, bid: {} });
                }}
            >
                {editBidModal.bid && <EditBidContent bid={editBidModal.bid} date={date} setOpen={setEditBidModal} market={market} editFunc={editBid} />}
            </ModalComponent>
            <div className={mainBiddingClasses.DateContainer}>
                <Typography content={`Create Bulk Bid (${market})`} />
                <div className="modal-date-picker" style={{ width: '8vw' }}>
                    <DatePicker
                        date={date}
                        setDate={(d) => {
                            setDate(d);
                        }}
                    />
                </div>
            </div>
            <div className={classes.Bids}>
                <Table
                    head={['Select', 'Customer Name', 'License', 'Instance', 'Avg Capacity', 'Action']}
                    keys={['select', 'customerName', 'license', 'instance', 'averageCapacity', 'action']}
                    data={bidsData}
                />
            </div>
            <div>
                {isBids.length ? (
                    <div>
                        <Typography content="Bid is already created for these instances" size={'14'} />
                        <div style={{ display: 'flex', gap: '0.5vw' }}>
                            {isBids.map((bid) => (
                                <div className={mainBiddingClasses.InstanceName}>{bid.name}, </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>

            <div>
                <div className={classes.ButtonContainer}>
                    <div onClick={() => setBulkBidModal({ status: false })}>
                        <button type="button" className="btn-secondary">
                            Cancel
                        </button>
                    </div>
                    <div onClick={handleSubmit}>
                        <button type="submit" className="btn-primary">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkBid;
