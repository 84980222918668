// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import classes from './index.module.css';

function DeatailsModal({ data, setOpen, text = '' }) {
    console.log(data);
    return (
        <>
            <div className={classes.Heading}>{text}</div>
            <div className={classes.DeatailsModal}>
                <div className={classes.KeyValueContainer}>
                    {Object.keys(data?.properties).map((item) => (
                        <div className={classes.keyVal}>
                            <div className={classes.key}>
                                <div>{item} :</div>
                            </div>
                            <div className={classes.value}>
                                <div>{data?.properties[item]}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.ButtonContainer}>
                    <div></div>
                    <div
                        className={'btn-primary'}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Close
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeatailsModal;
