import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';

const PATH = {
    data: '/admin/data/afrr',
    getAfrrBid: '/afrr',
    getLastAfrrBid: '/admin/data/afrr/last',
    postBid: '/admin/data/afrr/createBid',
    checkBidStatus: '/admin/data/afrr/checkbid',
    createReverseBid: '/admin/data/afrr/reverse',
    marketEnabledGroups: 'admin/data/aFRR/market-enabled-groups',
    forceActivation: '/admin/data/force-activation',
    instanceDetails: '/admin/data/intance-details',
    totalCapacity: '/admin/data/bids/total-capacity',
};

export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};
export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.data}`, payload, { params }).then(callback).catch(error).finally(next);
};

const PostBid = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
const afrrBidData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.data, { params }).then(callback).catch(error).finally(next);
};

const getLastAfrrBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastAfrrBid}`, { params }).then(callback).catch(error).finally(next);
};
const checkBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (_id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.data}?_id=${_id}`).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}`).then(callback).catch(error).finally(next);
};

const ForceActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.forceActivation}`, params).then(callback).catch(error).finally(next);
};

const GetInstanceDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceDetails}/${id}`).then(callback).catch(error).finally(next);
};

const CreateReverseBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createReverseBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const BidCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.totalCapacity}`, { params }).then(callback).catch(error).finally(next);
};

export const AfrrBidService = {
    afrrBidData,
    Create,
    Delete,
    getLastAfrrBid,
    PostBid,
    checkBidStatus,
    GetMarketEnabledGroups,
    ForceActivation,
    GetInstanceDetails,
    CreateReverseBid,
    BidCapacity,
};
