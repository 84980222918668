// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { UilTrash, UilCopy, UilEye, UilPen } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Form, Formik } from 'formik';

// Internal module imports
import classes from '../../../styles/AllDevices.module.css';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { ApikeyService } from '../../../services/apiKeyService';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { Input, ToggleButton, ToggleButtonWithState } from '../../../components/Inputs/Input';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CreateApiValidation } from '../../../validations/Home/externalApiValidation';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import DropdownComponent from '../../../components/Inputs/Dropdown';
import { API_KEY_ACCESS } from '../../../constants';
import { Capitalize } from '../../../utils/stringHelper';

function ExternalApis({ customersData, customerId }) {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([]);
    const [customersApi, setCustomersApi] = useState([]);
    const [addkey, setAddKey] = useState({
        status: false,
        isEdit: false,
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        keyId: '',
    });
    const [apiKeyModal, setApiKeyModal] = useState({
        status: false,
        key: {},
    });

    const [initialValues, setInitialValues] = useState({
        name: '',
        customerId: customerId,
        isActive: false,
        access: API_KEY_ACCESS,
    });

    useEffect(() => {
        if (customerId) {
            getdata();
        }
    }, []);

    const getdata = () => {
        ApikeyService.GetAllApiKeyForCustomer(customerId, startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const handleToggleChange = (item, id, value) => {
        let payLoad = {
            data: {
                isActive: value,
            },
        };
        ApikeyService.Update(item ? id : '', payLoad, startLoader, handleUpdateSuccess, handleError, stopLoader);
    };

    const handleUpdateSuccess = (res) => {
        if (res) {
            toast.success('Status updated!');
            getdata();
        }
    };

    const handleDelete = () => {
        ApikeyService.Delete(deleteModal?.keyId, startLoader, handleVersionDeleteSuccess, handleError, stopLoader);
    };

    const handleVersionDeleteSuccess = (res) => {
        if (res) {
            toast.success('Keys deleted successfully!');
            getdata();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleFetchSucess = ({ data }) => {
        console.log({ data });
        let newData = data?.data.map((item, index) => ({
            ...item,
            's.no': index + 1,
            name: item?.name,
            customerName: item?.customerName || '--',
            action: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div className={classes.ToggleContainer}>
                        <ToggleButtonWithState disabled={false} value={item.isActive} onChange={(e) => handleToggleChange(item, item._id, e.target.checked)} />
                    </div>
                    <div>
                        <CustomTooltip content={'View'}>
                            <UilEye
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)', marginRight: '0.5vw' }}
                                onClick={() => {
                                    setApiKeyModal({ status: true, key: item });
                                }}
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <CustomTooltip content={'Edit'}>
                            <UilPen
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setAddKey({
                                        status: true,
                                        isEdit: true,
                                        _id: item._id,
                                    });
                                    setInitialValues({
                                        name: item?.name,
                                        customerId: item.customerId,
                                        access: item.access,
                                        isActive: item?.isActive,
                                    });
                                }}
                            />
                        </CustomTooltip>
                    </div>

                    <div style={{ marginLeft: '0.3vw' }}>
                        <CustomTooltip content={'Delete'}>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, keyId: item._id })} />
                        </CustomTooltip>
                    </div>
                </div>
            ),
        }));
        setCustomers(newData);
    };

    useEffect(() => {
        let temp = [];
        customersData.forEach((element) => {
            temp.push({ value: element._id, label: element.name });
        });
        setCustomersApi(temp);
    }, [JSON.stringify(customersData)]);

    const handleApiCreate = (values) => {
        let payload = {
            data: {
                customerId: values.customerId,
                isActive: values.isActive,
                name: values?.name,
                access: values.access,
            },
        };
        if (addkey.isEdit) {
            ApikeyService.Update(addkey?._id, payload, startLoader, handleAccessUpdateSuccess, handleError, stopLoader);
        } else {
            ApikeyService.Create(payload, startLoader, handleCreateSuccess, handleError, stopLoader);
        }
    };

    const handleCreateSuccess = (res) => {
        if (res) {
            toast.success('API Key Created');
            setAddKey({
                status: false,
                isEdit: false,
            });
            setInitialValues({
                name: '',
                customerId: customerId,
                isActive: false,
                access: API_KEY_ACCESS,
            });
            getdata();
        }
    };
    const handleAccessUpdateSuccess = (res) => {
        if (res) {
            toast.success('API Key Access Updated');
            setAddKey({
                status: false,
                isEdit: false,
            });
            setInitialValues({
                name: '',
                customerId: customerId,
                isActive: false,
                access: API_KEY_ACCESS,
            });
            getdata();
        }
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={apiKeyModal.status} setOpen={(status) => setApiKeyModal({ ...apiKeyModal, status })}>
                <Typography content={'API Key'} />
                <div style={{ minWidth: '25vw' }}>
                    <CustomTooltip content={'Click to copy'}>
                        <CopyToClipboard text={apiKeyModal?.key?.key} onCopy={() => toast.success('Copied!')}>
                            <div className={classes.copyToClipBoardWrapper}>
                                <span className={classes.copyToClipBoardKey}>{apiKeyModal?.key?.key}</span>

                                <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </CopyToClipboard>
                    </CustomTooltip>
                </div>
                <div className={classes.ButtonContainer}>
                    <div>
                        <button type="submit" className="btn-primary" onClick={() => setApiKeyModal({ status: false, key: {} })}>
                            Close
                        </button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={addkey.status}
                setOpen={(e) => {
                    setAddKey({ status: e, isEdit: false });
                    setInitialValues({
                        name: '',
                        customerId: customerId,
                        isActive: false,
                        access: API_KEY_ACCESS,
                    });
                }}
                style={{ overflow: 'initial' }}
            >
                <Typography content={addkey.isEdit ? 'Edit API Access' : 'Add API Key'} />
                <Formik initialValues={initialValues} validationSchema={CreateApiValidation} onSubmit={handleApiCreate} enableReinitialize>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl2}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input
                                            name="name"
                                            id="name"
                                            type="text"
                                            disabled={addkey.isEdit ? true : false}
                                            style={{
                                                marginTop: '0',
                                                padding: '0.1vw 0 0.1vw 0.5vw',
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="customerId">
                                            Customer Id <span className="required">*</span>
                                        </label>
                                        <DropdownComponent
                                            name="customerId"
                                            id="customerId"
                                            options={customersApi}
                                            defaultValue={{ value: initialValues.customerId }}
                                            disabled={addkey.isEdit ? true : false || customerId ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className={classes.FieldControl2}>
                                    <label for="name">Access</label>
                                    <div className={`flex-box`} style={{ marginTop: '0' }}>
                                        {Object.keys(API_KEY_ACCESS).map((item) => (
                                            <div>
                                                <ToggleButton values={values} label={Capitalize(item)} name={`access[${item}]`} style={{ marginTop: '0' }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={classes.FieldControl}>
                                    <label for="name">Active </label>
                                    <ToggleButton values={values} label={'Active'} name={`isActive`} style={{ marginTop: '0' }} />
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="External Api Keys" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={customers?.length || 0} />
                        </span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-primary"
                            style={{ marginTop: '0' }}
                            onClick={() =>
                                setAddKey({
                                    status: true,
                                    isEdit: false,
                                })
                            }
                        >
                            Add Key
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Customer Name', 'Name', 'Created At', 'Action']} keys={['s.no', 'customerName', 'name', 'createdAt', 'action']} data={customers} />
            </div>
        </div>
    );
}

export default ExternalApis;
