// Internal module imports
import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';

const PATH = {
    data: '/admin/data/mfrr',
    getLastMfrrBid: '/admin/data/mfrr/last',
    postBid: '/admin/data/mfrr/createBid',
    checkBidStatus: '/admin/data/mfrr/checkbid',
    createReverseBid: '/admin/data/mfrr/reverse',
    marketEnabledGroups: 'admin/data/mFRR/market-enabled-groups',
    forceActivation: '/admin/data/force-activation',
    instanceDetails: '/admin/data/intance-details',
    getLastFcrBid: '/admin/data/mfrr/last',
    totalCapacity: '/admin/data/bids/total-capacity',
};

export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};
export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.data}`, payload, { params }).then(callback).catch(error).finally(next);
};

const PostBid = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
const mfrrBidData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.data, { params }).then(callback).catch(error).finally(next);
};

const getLastMfrrBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastMfrrBid}`, { params }).then(callback).catch(error).finally(next);
};
const checkBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (_id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.data}?_id=${_id}`).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}`).then(callback).catch(error).finally(next);
};

const ForceActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.forceActivation}`, params).then(callback).catch(error).finally(next);
};

const GetInstanceDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceDetails}/${id}`).then(callback).catch(error).finally(next);
};

const CreateReverseBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createReverseBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetLastBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastFcrBid}`, { params }).then(callback).catch(error).finally(next);
};

const BidCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.totalCapacity}`, { params }).then(callback).catch(error).finally(next);
};

export const MfrrBidService = {
    mfrrBidData,
    Create,
    Delete,
    getLastMfrrBid,
    PostBid,
    checkBidStatus,
    GetMarketEnabledGroups,
    ForceActivation,
    GetInstanceDetails,
    CreateReverseBid,
    GetLastBid,
    BidCapacity,
};
