import React from 'react';
import { useLoader } from '../../../../../hooks/use-loader.hook';
import classes from '../../../../../styles/BidModal.module.css';
import bidClasses from '../../index.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { toast } from 'react-toastify';
import { FieldArray, Form, Formik } from 'formik';
import { DropdownComponent, Input, TextArea } from '../../../../../components/Inputs/Input';
import AddIcon from '../../../../../assets/add.svg';
import RemoveIcon from '../../../../../assets/remove.svg';
import { FcrBidService } from '../../../../../services/FcrBidService';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import { useState } from 'react';
import { BidRequestValidation } from '../../../../../validations/BidRequest/BidRequestvalidation';
import Dropdown from '../../../../../components/Inputs/Dropdown';
const getGroupOptions = (groups) => {
    const options = [];
    groups.forEach((g) => {
        options.push({ label: g.name, value: g._id });
    });
    return options;
};

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const getBiddingOptions = (groupMap, groupId) => {
    const biddingMarkets = groupMap[groupId]?.properties?.biddingMarkets || [];
    const options = [];
    biddingMarkets.forEach((m) => {
        options.push({ label: m, value: m });
    });
    return options;
};

const getInitialValues = (editRequest) => {
    const values = {
        slot1: editRequest.slot1 || '',
        slot2: editRequest.slot2 || '',
        slot3: editRequest.slot3 || '',
        slot4: editRequest.slot4 || '',
        slot5: editRequest.slot5 || '',
        slot6: editRequest.slot6 || '',
        slot1Price: editRequest.slot1Price || '',
        slot2Price: editRequest.slot2Price || '',
        slot3Price: editRequest.slot3Price || '',
        slot4Price: editRequest.slot4Price || '',
        slot5Price: editRequest.slot5Price || '',
        slot6Price: editRequest.slot6Price || '',
        groupId: editRequest.groupId || '',
        startDate: editRequest.startDate ? momentTimeFormater(editRequest.startDate).format('YYYY-MM-DD') : '',
        endDate: editRequest.endDate ? momentTimeFormater(editRequest.endDate).format('YYYY-MM-DD') : '',
        biddingMarket: editRequest.biddingMarket || '',
        excludedDays: editRequest.excludedDays
            ? editRequest.excludedDays.map((e) => ({
                  ...e,
                  date: momentTimeFormater(e.date).format('YYYY-MM-DD'),
              }))
            : [],
    };
    return values;
};

const RequestContent = ({ groups = [], setOpen, editRequest = {}, setRefresh }) => {
    const [startLoader, stopLoader] = useLoader();
    const initialValues = getInitialValues(editRequest);
    const groupOptions = getGroupOptions(groups);
    const groupHashMap = getGroupHashMap(groups);
    const [biddingMarketOptions, setBiddingMarketOptions] = useState(getBiddingOptions(groupHashMap, initialValues.groupId || groupOptions[0]?.value));
    const handleSubmit = (values) => {
        if (!editRequest._id) {
            FcrBidService.UpdateBidRequest(editRequest._id, values, startLoader, handleUpdateSuccess, handleError, stopLoader);
        }
    };

    const handleUpdateSuccess = ({}) => {
        toast.success('Bid request updated successfully!');
        setRefresh && setRefresh(Math.random() * 1000);
        setOpen && setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleinstanceChange = ({ value }) => {
        const options = getBiddingOptions(groupHashMap, value);
        setBiddingMarketOptions(options);
    };

    return (
        <div>
            <Typography content={`${editRequest._id ? 'Edit' : 'Create'} Request`} />
            <div className={classes.FormContainer}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={BidRequestValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div className={classes.Subtitle}>Capacity(Mw)</div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot1">
                                                Slot 1 <span className="required">*</span>
                                            </label>
                                            <Input name="slot1" id="slot1" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot2">
                                                Slot 2 <span className="required">*</span>
                                            </label>
                                            <Input name="slot2" id="slot2" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot3">
                                                Slot 3 <span className="required">*</span>
                                            </label>
                                            <Input name="slot3" id="slot3" />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot4">
                                                Slot 4 <span className="required">*</span>
                                            </label>
                                            <Input name="slot4" id="slot4" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot5">
                                                Slot 5 <span className="required">*</span>
                                            </label>
                                            <Input name="slot5" id="slot5" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot6">
                                                Slot 6 <span className="required">*</span>
                                            </label>
                                            <Input name="slot6" id="slot6" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Price(€)</div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot1Price">
                                                Slot 1 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot1Price" id="slot1Price" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot2Price">
                                                Slot 2 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot2Price" id="slot2Price" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot3Price">
                                                Slot 3 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot3Price" id="slot3Price" />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot4Price">
                                                Slot 4 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot4Price" id="slot4Price" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot5Price">
                                                Slot 5 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot5Price" id="slot5Price" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="slot6Price">
                                                Slot 6 Price <span className="required">*</span>
                                            </label>
                                            <Input name="slot6Price" id="slot6Price" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Config</div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label>Start Date</label>
                                        <div className="modal-date-picker">
                                            <Input name="startDate" id="stratdate" type="date" />
                                        </div>
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label>End Date</label>
                                        <div className="modal-date-picker">
                                            <Input name="endDate" id="enddate" type="date" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="groupId">Instance</label>
                                            <Dropdown
                                                name="groupId"
                                                options={groupOptions}
                                                defaultValue={groupOptions.length && groupOptions.filter((group) => group.value === values.groupId)[0]}
                                                onChange={handleinstanceChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl}>
                                    <label for="groupId">Bidding Market</label>
                                    <Dropdown
                                        name="biddingMarket"
                                        options={biddingMarketOptions}
                                        defaultValue={
                                            biddingMarketOptions.filter((group) => group.value === values.biddingMarket).length
                                                ? biddingMarketOptions.filter((group) => group.value === values.biddingMarket)[0]
                                                : biddingMarketOptions[0]
                                                  ? biddingMarketOptions[0]
                                                  : { label: 'None', value: '' }
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                {values.excludedDays.length ? (
                                    <>
                                        {' '}
                                        <div className={classes.Subtitle}>Excluded Days</div>
                                        <FieldArray name="excludedDays">
                                            {({ push, remove, form }) => {
                                                const { excludedDays } = form.values;
                                                return (
                                                    <div>
                                                        <div>
                                                            {excludedDays.map((e, index) => (
                                                                <div className={bidClasses.InputContainer}>
                                                                    <div className={bidClasses.FieldControl2}>
                                                                        <label for="groupId">Date</label>
                                                                        <Input name={`excludedDays[${index}].date`} type="date" />
                                                                    </div>
                                                                    <div className={bidClasses.FieldControl}>
                                                                        <label for="groupId">Reason</label>
                                                                        <TextArea
                                                                            name={`excludedDays[${index}].reason`}
                                                                            label="Reason"
                                                                            setFieldValue={props.setFieldValue}
                                                                            setFieldTouched={props.setFieldTouched}
                                                                            getFieldMeta={props.getFieldMeta}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className={bidClasses.AddInputActionWrapper}>
                                                            {excludedDays.length > 0 && (
                                                                <span onClick={() => remove(excludedDays.length - 1)}>
                                                                    <img src={RemoveIcon} className={classes.addInputActionbutton} />
                                                                </span>
                                                            )}
                                                            <span onClick={() => push({ date: '', reason: '' })}>
                                                                <img src={AddIcon} className={classes.addInputActionbutton} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </FieldArray>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <button type="submit" className="btn-primary" onClick={() => values.excludedDays.push({ date: '', reason: '' })}>
                                                Add Excluded Days
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default RequestContent;
