// Standard library imports
import React, { useContext, useState } from 'react';
// External library imports
import { get as LodashGet } from 'lodash';
// Internal module imports
import classes from '../Mobilepay.module.css';
import { LoaderContext } from '../../../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../../../constants';
import { AuthContext } from '../../../../../../context/AuthContext';
// import { LicenseService } from '../../../services/LicenseService';
import ChargeComponent from './Charge';

const Details = () => {
    // const date = new Date();
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const customerId = state.user._id;
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [date, setDate] = useState(new Date());
    const [license, setLicense] = useState([]);
    let licenseId = state?.user?.customerId?.license?.CFM?.id;
    console.log(state?.user);
    // useEffect(() => {
    //   if (licenseId)
    //     LicenseService.getLicenseDetails(
    //       licenseId,
    //       () => startLoader('getLicense'),
    //       handleGetLicenseSuccess,
    //       err => {
    //         console.log(err);
    //       },
    //       () => stopLoader('getLicense')
    //     );
    // }, [licenseId]);
    const handleGetLicenseSuccess = ({ data }) => {
        const licenseDetails = data.data;
        setLicense(licenseDetails);
    };
    return (
        <div className={classes.Details}>
            <ChargeComponent license={license} startLoader={startLoader} stopLoader={stopLoader} date={date} setDate={setDate} />
        </div>
    );
};

export default Details;
