import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { excludedDatesService } from '../../../services/excludedDatesService';
import DeleteIcon from '../../../assets/delete.png';
import eyeIcon from '../../../assets/eye.png';
import EditIcon from '../../../assets/edit.png';
import classes from '../../../styles/AllDevices.module.css';
import { useLoader } from '../../../hooks';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CustomerService } from '../../../services/CustomerService';
import CreateExcludeDays from './createExcludeDays';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import { UilEye } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

function ExcludeDates({ regulations, updateQueryParams, queryParamsData = {} }) {
    const [startLoader, stopLoader] = useLoader();
    const [excludeDates, setExcludeDates] = useState([]);
    const [excludeModal, setExcludeModal] = useState(queryParamsData.excludeModal ? queryParamsData.excludeModal : false);
    const [excludeData, setExcludeData] = useState({});
    const [customers, setCustomers] = useState([]);
    const [customersModal, setCustomersModal] = useState({
        status: false,
        customers: [],
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        excludeId: '',
    });
    const [filteredData, setfilteredData] = useState([]);

    useEffect(() => {
        updateQueryParams('excludeModal', excludeModal);
    }, [excludeModal]);

    useEffect(() => {
        getCustomers();
        getData();
    }, []);

    useEffect(() => {
        if (customersModal.status) {
            const data = [];
            customers.forEach((el) => {
                if (customersModal.customers.includes(el.value)) {
                    data.push({ customers: el.label });
                }
            });
            setfilteredData(data);
        }
    }, [customersModal]);

    const getData = () => {
        excludedDatesService.ReadAll(startLoader, handleFetchSucess, handleFetchError, stopLoader);
    };

    const getCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleFetchSuccess, handleFetchError, stopLoader);
    };

    const handleFetchSuccess = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
    };

    const handleFetchSucess = ({ data }) => {
        let newData = data?.data.map((item, index) => ({
            ...item,
            's.no': index + 1,
            formattedDate: item?.cetDate.split(',')[0],
            customersDetail: (
                <div>
                    <UilEye
                        size={'1.2vw'}
                        style={{ color: 'var(--color-primary)' }}
                        onClick={() => {
                            setCustomersModal({
                                status: true,
                                customers: item.customers,
                            });
                        }}
                    />
                </div>
            ),
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <CustomTooltip content={'Edit'}>
                            <UilPen
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setExcludeModal(true);
                                    setExcludeData(item);
                                }}
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <CustomTooltip content={'Delete'}>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, excludeId: item._id })} />
                        </CustomTooltip>
                    </div>
                </div>
            ),
        }));
        setExcludeDates(newData);
    };

    const deleteExcludeAggrement = (item) => {
        excludedDatesService.Delete(deleteModal.excludeId, startLoader, handledeleteSuccess, handleFetchError, stopLoader);
    };

    const handledeleteSuccess = (res) => {
        if (res) {
            setDeleteModal({ status: false, excludeId: '' });
            toast.success('Exclusion deleted successfully');
            getData();
        }
    };

    const handleFetchError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={deleteExcludeAggrement}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={excludeModal}
                setOpen={(status) => setExcludeModal(status)}
                onClose={() => {
                    setExcludeData({});
                    setExcludeModal(false);
                }}
            >
                <div style={{ minWidth: '40vw' }}>
                    <CreateExcludeDays customers={customers} editData={excludeData} setmodalControl={setExcludeModal} refreshData={getData} regulations={regulations} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={customersModal.status} setOpen={(status) => setCustomersModal({ ...customersModal, status })}>
                <div style={{ minWidth: '15vw' }}>
                    <Typography content="Excluded Customers" />
                    <Table head={['Customers']} keys={['customers']} data={filteredData} />
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Excluded Dates" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={0} />
                        </span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setExcludeModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Date', 'Customers', 'Action']} keys={['s.no', 'formattedDate', 'customersDetail', 'action']} data={excludeDates} />
            </div>
        </div>
    );
}

export default ExcludeDates;
