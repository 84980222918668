// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getbids: '/root/bidding/get-bids',
    getAllbids: '/root/bidding/get-all-bids',
    createBulkBid: '/root/bidding/create-bulk-bids',
    export: '/root/bidding/export',
    general: '/admin/data',
    bidRequest: '/admin/data/bid-request',
    maintenanceHours: '/admin/data/maintenance-hours',
    biddingTool: 'admin/data',
};

const GetBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getbids}`, { params }).then(callback).catch(error).finally(next);
};
const GetAllBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllbids}`, { params }).then(callback).catch(error).finally(next);
};

const ExportBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.export}`, { params }).then(callback).catch(error).finally(next);
};

const Delete = (market, _id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.general}/${market}/?_id=${_id}`).then(callback).catch(error).finally(next);
};

const PostBid = (market, id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.general}/${market}/createBid/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};

const CheckBidStatus = (market, id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.general}/${market}/checkbid/${id}`).then(callback).catch(error).finally(next);
};

const CreateBulkBids = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createBulkBid}`, payload).then(callback).catch(error).finally(next);
};

const GetBidRequestForADate = (params, groupId, market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}/${market}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetMaintenanceHoursForADate = (params, groupId, market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}/${market}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetBiddingToolData = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.biddingTool}/${market}/bidding-tool`).then(callback).catch(error).finally(next);
};

export const BiddingService = {
    GetBids,
    ExportBids,
    Delete,
    PostBid,
    CheckBidStatus,
    GetAllBids,
    CreateBulkBids,
    GetBidRequestForADate,
    GetMaintenanceHoursForADate,
    GetBiddingToolData,
};
