import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import classes from '../../../../styles/BidModal.module.css';
import { getJustNumbers } from '../../../../utils/stringHelper';
import { Input, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import { toast } from 'react-toastify';
import Typography from '../../../../components/Typography/Typography';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { CreateDMinusBundleBidValidation } from '../../../../validations/BundleBids/BundleBidValidation';
import bundleBidClasses from '../index.module.css';

const getInitialValues = (bid) => {
    let values = {
        price: [],
        capacity: {},
    };
    if (Object.keys(bid?.capacityDMinus1 || {}).length) {
        values = {
            capacity: bid?.capacityDMinus1,
            price: bid?.priceDMinus1,
        };
    } else if (bid?.dMinus2BidNotAvailable) {
        values = {
            capacity: bid?.capacity,
            price: bid?.price,
        };
    } else if (bid?.bidStatusDMinus2?.length) {
        const tempCapacity = {};
        const tempPrice = [];
        bid?.bidStatusDMinus2?.forEach((hourData, index) => {
            for (let key in bid.capacity) {
                if (!tempCapacity[key]) {
                    tempCapacity[key] = [];
                }
                if (hourData.price === 0) {
                    tempCapacity[key].push(bid?.capacityDMinus1?.[key]?.[index] || bid.capacity?.[key][index]);
                } else {
                    tempCapacity[key].push(null);
                }
            }
            if (hourData.price === 0) {
                tempPrice.push(bid?.price[index]);
            } else {
                tempPrice.push(null);
            }
        });
        values = {
            price: tempPrice,
            capacity: tempCapacity,
        };
    }
    return values;
};

const getPriceCapacity = (initialValues) => {
    return { capacity: initialValues.capacity, price: initialValues.price };
};

const DMinusOne = ({ bid, startLoader, stopLoader, setOpen, getBundleBids }) => {
    const [initialValues, setInitialValues] = useState(getInitialValues(bid));

    const [{ capacity, price }, setPriceCapacityValues] = useState(getPriceCapacity(initialValues));

    const [dMinus2BidNotAvailable, setDMinus2BidNotAvailable] = useState(bid?.dMinus2BidNotAvailable || false);
    const [instances, setInstances] = useState([]);

    const [instanceIds] = useState((bid?.capacity && Object.keys(bid.capacity)) || []);

    useEffect(() => {
        if (instanceIds.length) {
            getInstanceDetailsByIds();
        }
    }, []);

    const getInstanceDetailsByIds = () => {
        const params = {
            instanceIds,
        };
        BundleBiddingService.GetInstanceDetailsByIds(params, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleInstanceSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            name: item.name,
            instanceId: item._id,
        }));
        setInstances(temp);
    };

    const handleSubmit = (values) => {
        values.dMinus2BidNotAvailable = dMinus2BidNotAvailable;
        if (bid._id) {
            BundleBiddingService.UpdateDMinusOne(bid._id, values, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ res }) => {
        toast.success('Bid Updated!');
        getBundleBids();
        setOpen({ status: false, bid: {} });
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleDMinus2NotAvailableChange = async (e) => {
        setDMinus2BidNotAvailable(e.target.checked);
        if (e.target.checked) {
            BundleBiddingService.GetLastBid(bid._id, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    };

    const handleGetLastBidSuccess = ({ data }) => {
        const tempCapacity = {};
        data.data.map((item) => {
            if (item.lastBid) {
                tempCapacity[item._id] = item.lastBid.capacity;
            }
        });

        setInitialValues({
            ...initialValues,
            capacity: tempCapacity,
            price: bid.price || [],
        });

        setPriceCapacityValues({ capacity: tempCapacity, price: bid.price || [] });
    };

    return (
        <div>
            <Typography content={`${bid?.market} D-1 Bid ( ${momentTimeFormater(bid?.date).format('DD-MM-YYYY')} )`} size="16" />
            <div className={classes.FormContainer2}>
                {price?.filter((e) => e != null).length > 0 || dMinus2BidNotAvailable ? (
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateDMinusBundleBidValidation(instances)}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        {/*capacity  */}
                                        {instances?.map((el, i) => (
                                            <div key={i}>
                                                <div>
                                                    <div className={classes.Subtitle}>Capacity(Mw) - {el.name} </div>
                                                </div>

                                                <div className={bundleBidClasses.DMinusContainer}>
                                                    {capacity?.[el.instanceId]?.map((item, index) =>
                                                        item || item == 0 ? (
                                                            <div className={classes.InputContainer} key={index}>
                                                                <div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label>
                                                                            Slot {index + 1} / Hour {index}-{index + 1}{' '}
                                                                        </label>
                                                                        <Input name={`capacity[${el.instanceId}][${index}]`} id={index} type="number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {/*  */}
                                        <div>
                                            <div className={classes.Subtitle}>Price(€)</div>
                                            <div className={bundleBidClasses.DMinusContainer}>
                                                {price?.map((item, index) =>
                                                    item || item == 0 ? (
                                                        <div className={classes.InputContainer} key={index}>
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label>
                                                                        Slot {index + 1} / Hour {index}-{index + 1} Price
                                                                    </label>
                                                                    <Input name={`price[${index}]`} id={index} type="number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setOpen({ status: false, bid: {} })}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <span>Looks like result for D-2 bid are not announced yet.</span>
                        <div>OR</div>
                        <span>Result were in favour for all hours.</span>
                        <div>OR</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ToggleButtonWithState value={dMinus2BidNotAvailable} onChange={handleDMinus2NotAvailableChange} label="D-2 Not Available" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DMinusOne;
