// Standard library imports
import React, { useContext, useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import debounce from 'lodash.debounce';
// Internal module imports
import classes from '../../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../../components/Typography/Typography';
import Table from '../../../../../../components/Table/Table';
import ModalComponent from '../../../../../../components/ModalComponent/ModalComponent';
import DeatailsModal from './DeatailsModal';
import { LoaderContext } from '../../../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../../../constants';
import { CfmService } from '../../../../../../services/CfmService';
import CustomTooltip from '../../../../../../components/CustomToolTip/CustomTooltip';

function ChargingLogs() {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [logsData, setLogsData] = useState([]);
    const [chargerId, setChargerId] = useState('');
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(15);
    const [detailsData, setDetailsData] = useState({});
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [modal, setModal] = useState(false);

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    useEffect(() => {
        debouncedFetchData(chargerId);
    }, [chargerId, skip, limit]);

    const fetchData = (query) => {
        let params = {
            serialNumber: query,
            limit,
            skip,
        };

        CfmService.allChargersLogsWithSearch(
            params,
            () => startLoader('getAllChargersData'),
            handleSuccess,
            handleError,
            () => stopLoader('getAllChargersData')
        );
    };

    const debouncedFetchData = debounce((query) => {
        fetchData(query);
    }, 500);

    const handleSuccess = ({ data }) => {
        const processedData = data.data.data.map((item, index) => ({
            ...item,
            Action: (
                <CustomTooltip content={'Details'}>
                    <UilClipboardAlt
                        size={'1.2vw'}
                        style={{ color: 'var(--color-primary)', cursor: 'pointer' }}
                        onClick={() => {
                            setModal(true);
                            setDetailsData(item);
                        }}
                    />
                </CustomTooltip>
            ),
        }));
        setTotalCount(data.data.count);
        setLogsData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={modal} setOpen={setModal}>
                <DeatailsModal data={detailsData} setOpen={setModal} text="Details" />
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Charger Logs" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={logsData.length} />
                        </span>
                    </div>
                </div>
                <div>
                    <div className={'inputStyle2'}>
                        <input
                            placeholder="Search by serial number"
                            style={{ width: '10vw !important' }}
                            type="text"
                            name="chargerId"
                            id=""
                            value={chargerId}
                            onChange={(e) => {
                                setChargerId(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['Serial Number', 'Type', 'Timestamp', 'Action']}
                    keys={['chargerId', 'action', 'startTime', 'Action']}
                    data={logsData}
                    page={page}
                    Pagination={true}
                    limit={limit}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
}

export default ChargingLogs;
