import classes from './index.module.css';
import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import Bids from './Bids/Bids';
import BidRequestIndex from './BidRequests';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';
import TotalCapacity from './TotalCapacity';

const Bidding = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        customer: queryParams.get('customer') ? JSON.parse(queryParams.get('customer')) : '',
        license: queryParams.get('license') ? JSON.parse(queryParams.get('license')) : '',
        groups: queryParams.get('groups') ? JSON.parse(queryParams.get('groups')) : '',
        filter: queryParams.get('filter') ? JSON.parse(queryParams.get('filter')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        bidModal: queryParams.get('bidModal') && queryParams.get('bidModal') === 'true' ? Boolean(queryParams.get('bidModal')) : false,
        stateModal: queryParams.get('stateModal') && queryParams.get('stateModal') === 'true' ? Boolean(queryParams.get('stateModal')) : false,
        bidDate: queryParams.get('bidDate') ? new Date(queryParams.get('bidDate')) : '',
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
    });

    const [editDevice, setEditDevice] = useState({});
    const [prefillGroup, setPrefillGroup] = useState('');
    const [device, setDevice] = useState({
        name: '',
        uId: '',
    });

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    let tabs = [
        {
            name: 'FCR Bids',
            component: (
                <>
                    <Bids updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        {
            name: 'Bid Requests',
            component: (
                <>
                    <BidRequestIndex updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        {
            name: 'Total Capacity',
            component: <TotalCapacity queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={queryParamsData.tab ? queryParamsData.tab : editDevice.uId || prefillGroup ? 3 : 0}
                resetIndex={device.name}
                setEditDevice={setEditDevice}
                setPrefillGroup={setPrefillGroup}
                tabs={tabs}
                onChangeTab={(index) => {
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Bidding;
