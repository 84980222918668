// Standard library imports
import React, { useEffect, useState } from 'react';
// Extenal libary imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/BidModal.module.css';
import { Input } from '../../../../components/Inputs/Input';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { useLoader } from '../../../../hooks';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { CreateBundleBidValidation } from '../../../../validations/BundleBids/BundleBidValidation';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const CreateBundleBid = ({ market, instanceBundle, customerId, setOpen, getBundleBids }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(new Date());
    const [pairs, setPairs] = useState(instanceBundle.pairs || []);
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);
    const [alreadyCreatedBids, setAlreadyCreatedBids] = useState([]);
    const getDefaultCapacity = () => {
        let temp = {};
        instanceBundle?.pairs?.map((e) => {
            temp[e.instanceId] = Array(24).fill(0.9);
        });
        return temp;
    };

    const [initialValues, setInitialValues] = useState({
        price: Array(24).fill(14),
        capacity: getDefaultCapacity(),
        reserveBidIdentification: '',
    });

    useEffect(() => {
        BundleBiddingService.GetBidRequest(
            instanceBundle.value,
            { date: momentTimeFormater(date).format('YYYY-MM-DD'), market: market },
            startLoader,
            handleRequestBidSuccess,
            handleError,
            stopLoader
        );
    }, [date]);

    const handleRequestBidSuccess = ({ data }) => {
        const tempCapacity = {};
        const tempMaintenanceHours = {};
        let price = initialValues.price;
        let reserveBidIdentification = '';
        data?.data?.data.map((e) => {
            if (e.bidRequest.length) {
                tempCapacity[e.pairs.instanceId] = e.bidRequest[0].capacity;
            } else if (e.lastBid.length) {
                tempCapacity[e.pairs.instanceId] = e.lastBid[0].capacity;
            } else {
                tempCapacity[e.pairs.instanceId] = Array(24).fill(0.9);
            }
            if (e.maintenanceHours.length) {
                tempMaintenanceHours[e.pairs.instanceId] = e.maintenanceHours[0].hourSlots;
            } else {
                tempMaintenanceHours[e.pairs.instanceId] = [];
            }
        });
        if (data?.data?.bundleBid) {
            price = data?.data?.bundleBid.price;
            reserveBidIdentification = data?.data?.bundleBid.reserveBidIdentification;
        }
        setInitialValues({
            ...initialValues,
            capacity: tempCapacity,
            price,
            reserveBidIdentification,
        });
        setMaintenanceSlots(tempMaintenanceHours);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleSubmit = (val) => {
        const payload = {
            ...val,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            market,
            customerId: customerId,
            instanceBundleId: instanceBundle.value,
        };
        BundleBiddingService.CreateBundleBid(payload, startLoader, handleCreateBundleBidSuccess, handleError, stopLoader);
    };

    const handleCreateBundleBidSuccess = ({ data }) => {
        if (data.data?.status === 'bids already present') {
            setAlreadyCreatedBids(data.data.instanceIds);
            toast.error('Bids are already created.');
        } else {
            toast.success('Bundle Bids created successfully');
            setOpen(false);
            getBundleBids();
        }
    };

    return (
        <div>
            <div>
                <Typography content={`${market} Increased Bidding`} size="16" />
                <div className={classes.Subtitle} style={{ margin: 0 }}>
                    Red-bordered hour slots signify customer-requested maintenance
                    <span className="required">*</span>
                </div>
                <div className={classes.FormContainer2}>
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateBundleBidValidation(pairs)}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        {/*capacity  */}
                                        {pairs.map((el, i) => (
                                            <div key={i}>
                                                <div>
                                                    <div className={classes.Subtitle}>
                                                        Capacity(Mw) - {el.instanceName}{' '}
                                                        {alreadyCreatedBids.includes(el.instanceId) && <span className="required">Bid is already created.</span>}
                                                    </div>
                                                </div>

                                                {Array(6)
                                                    .fill(null)
                                                    .map((item, index) => (
                                                        <div className={classes.InputContainer}>
                                                            {Array(4)
                                                                .fill(null)
                                                                .map((subItem, subIndex) => (
                                                                    <div key={`${index + subIndex}-capacity`}>
                                                                        <div className={classes.FieldControl}>
                                                                            <label htmlFor={`capacity${index * 4 + subIndex + 1}`}>
                                                                                Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex}-{index * 4 + subIndex + 1}
                                                                                <span className="required">*</span>
                                                                            </label>
                                                                            <Input
                                                                                type="number"
                                                                                name={`capacity[${el.instanceId}][${index * 4 + subIndex}]`}
                                                                                id={`capacity${index * 4 + subIndex + 1}`}
                                                                                style={
                                                                                    maintenanceSlots?.[el.instanceId]?.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}

                                        {/*  */}
                                        <div>
                                            <div className={classes.Subtitle}>
                                                <span>Price(€)</span>
                                            </div>
                                            {Array(6)
                                                .fill(null)
                                                .map((item, index) => (
                                                    <div className={classes.InputContainer}>
                                                        {Array(4)
                                                            .fill(null)
                                                            .map((subItem, subIndex) => (
                                                                <div key={`${index + subIndex}-price`}>
                                                                    <div className={classes.FieldControl}>
                                                                        <label htmlFor={`price${index * 4 + subIndex + 1}`}>
                                                                            Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex}-{index * 4 + subIndex + 1} Price
                                                                            <span className="required">*</span>
                                                                        </label>
                                                                        <Input name={`price[${index * 4 + subIndex}]`} id={`price${index * 4 + subIndex + 1}`} type="number" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                ))}
                                        </div>

                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label>
                                                    Date <span className="required">*</span>
                                                </label>
                                                <div className="modal-date-picker">
                                                    <DatePicker date={date} setDate={setDate} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.FieldControl}>
                                                    <label htmlFor="reserveBidIdentification">
                                                        Reserve Bid Identification
                                                        <span className="required">*</span>
                                                    </label>
                                                    <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default CreateBundleBid;
