import React from 'react';
import classes from '../../../../styles/BidModal.module.css';
import { Form, Formik } from 'formik';
import { Input } from '../../../../components/Inputs/Input';
import { toast } from 'react-toastify';
import Typography from '../../../../components/Typography/Typography';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { useLoader } from '../../../../hooks';
import { ResultMessageValidator } from '../../../../validations/BundleBids/BundleBidValidation';

const ResultId = ({ bid, setModal, getBundleBids }) => {
    const [startLoader, stopLoader] = useLoader();

    const initialValues = {
        resultMessageId: bid?.resultMessageId ? bid.resultMessageId : '',
        ...(bid.market === 'FCR-D-INC' || bid.market === 'FCR-D-DEC'
            ? {
                  resultMessageIdDMinus1: bid?.resultMessageIdDMinus1 ? bid.resultMessageIdDMinus1 : '',
              }
            : {}),
        reserveBidIdentification: bid?.reserveBidIdentification ? bid.reserveBidIdentification : '',
    };

    const handleSubmit = (values) => {
        BundleBiddingService.AddResultId(bid._id, values, startLoader, handleSubmitSucess, handleError, stopLoader);
    };

    const handleSubmitSucess = ({ res }) => {
        toast.success('Result Message ID updated successfully');
        getBundleBids();
        setModal({ status: false, data: {} });
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <Typography content="Result Message ID" size="16" />
            <div className={classes.FormContainer} style={{ width: '20vw' }}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={ResultMessageValidator(bid?.dMinus2BidNotAvailable)}>
                    <Form>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Result Message ID {(bid?.market === 'FCR-D-INC' || bid?.market === 'FCR-D-DEC') && `(D-2)`}</label>
                            <Input name="resultMessageId" id="resultMessageId" type="number" />
                        </div>
                        {(bid?.market === 'FCR-D-INC' || bid?.market === 'FCR-D-DEC') && (
                            <div className={classes.FieldControl} style={{ width: '100%' }}>
                                <label for="resultMessageIdDMinus1">Result Message ID (D-1)</label>
                                <Input name="resultMessageIdDMinus1" id="resultMessageIdDMinus1" type="number" />
                            </div>
                        )}
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label for="resultMessageId">Reserve Bid Identification</label>
                            <Input name="reserveBidIdentification" id="reserveBidIdentification" type="text" />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="button" className="btn-secondary" onClick={() => setModal({ status: false, data: {} })}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ResultId;
