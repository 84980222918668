// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import classes from './index.module.css';
import TabsComponent from '../../../../components/Tabs/Tabs';
import Details from './Mobilepay/Details/Details';
import Chargers from './Chargers';

const CFM = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    let tabs = [
        {
            name: 'Chargers',
            component: (
                <>
                    <Chargers queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Mobile Pay',
            component: (
                <>
                    <Details />
                </>
            ),
            tabId: 2,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default CFM;
