import classes from '../index.module.css';
import React, { useState } from 'react';
import TabsComponent from '../../../../../components/Tabs/Tabs';
import AllChargers from './ChargingLogs/ChargingLogs';
import UnknownChargingLogs from './UnknownChargers/ChargingLogs';

const Chargers = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.nestedTab ? queryParamsData.nestedTab : 0);
    let tabs = [
        {
            name: 'Unknown Chargers',
            component: (
                <>
                    <UnknownChargingLogs />
                </>
            ),
            tabId: 1,
        },

        {
            name: 'All Chargers',
            component: (
                <>
                    <AllChargers />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('nestedTab', index);
                }}
            />
        </div>
    );
};

export default Chargers;
