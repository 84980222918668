import { HttpClient } from '../utils/httpClient';

const PATH = {
    getForecast: '/admin/forecast',
};

const GetForecast = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getForecast}`, { params }).then(callback).catch(error).finally(next);
};

export const ForecastService = {
    GetForecast,
};
