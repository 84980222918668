import { HttpClient } from '../utils/httpClient';

const PATH = {
    unknownChargers: '/root/data/chargers/unknown',
    allChargers: '/root/data/chargers/all',
};

const unknownChargers = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.unknownChargers}`, { params }).then(callback).catch(error).finally(next);
};

const allChargersLogsWithSearch = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.allChargers}`, {
        params,
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

export const CfmService = {
    unknownChargers,
    allChargersLogsWithSearch,
};
