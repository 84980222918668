import React, { useEffect, useState } from 'react';
import FilterBarClasses from '../../../styles/Filterbar.module.css';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { regulationsService } from '../../../services/regulationsService';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import moment from 'moment';
import classes from '../../../styles/Bids.module.css';
import { BiddingService } from '../../../services/BiddingService';
import Table from '../../../components/Table/Table';
import Typography from '../../../components/Typography/Typography';
import { UilCopy, UilInfoCircle, UilImport, UilBolt, UilTrash, UilCapture, UilSync, UilKeyboardShow, UilHistoryAlt } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CreateBid } from './ModalComponent/CreateBid';
import { CreateD1Bid } from './ModalComponent/CreateD1Bid';
import { ResultId } from './ModalComponent/ResultId';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { StatusData } from './ModalComponent/Status';
import { BidInfo } from './ModalComponent/BidInfo';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { momentTimeFormater } from '../../../utils/dateHelper';
import { AdminService } from '../../../services/AdminService';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import ExportBid from './ModalComponent/ExportBid';
import BulkBid from './ModalComponent/BulkBid';

const LIMIT = 15;

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

export const MainBidding = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [regulations, setRegulations] = useState([]);
    const [selectedRegulation, setSelectedRegulation] = useState({
        value: queryParamsData.regulation,
    });
    const [date, setDate] = useState(moment(queryParamsData.bidDate || undefined).toDate());
    const [admins, setAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({
        value: queryParamsData.admin,
    });
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({
        value: queryParamsData.customer,
    });
    const [bidsData, setBidsData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [createBid, setCreateBid] = useState({ status: false, meta: {} });
    const [createD1Bid, setCreateD1Bid] = useState({ status: false, meta: {} });
    const [resultId, setResultId] = useState({ status: false, meta: {} });
    const [deleteBid, setDeleteBId] = useState({ status: false, id: null });
    const [statusData, setStatusData] = useState({ status: false, meta: {} });
    const [infoData, setInfoData] = useState({ status: false, meta: {} });
    const [exportBid, setExportBid] = useState({ status: false });
    const [bulkBidModal, setBulkBidModal] = useState({ status: false });

    useEffect(() => {
        fetchRegulations();
        fetchAdmins();
    }, []);

    useEffect(() => {
        if (!selectedAdmin.value) return;
        if (selectedAdmin.value !== 'All') fetchAdminCustomers();
        else {
            const options = getCustomerOptions([]);
            setCustomers(options);
            setSelectedCustomer(options[0]);
        }
    }, [selectedAdmin.value]);

    useEffect(() => {
        if (selectedRegulation.value) {
            fetchData();
        }
    }, [skip, selectedCustomer.value, selectedRegulation.value, selectedAdmin.value]);

    const fetchAdmins = () => {
        AdminService.ReadAll(
            () => startLoader('get admin'),
            handleFetchSucess,
            handleError,
            () => stopLoader('get admin')
        );
    };

    const handleFetchSucess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getAdminOptions(data.data);
            setAdmins(options);
            if (!selectedAdmin.value) setSelectedAdmin(options[0]);
        }
    };

    const fetchAdminCustomers = () => {
        AdminService.GetMarketParticipatingCustomers(
            selectedAdmin.value,
            () => startLoader('get customer'),
            handleCustomersSuccess,
            handleError,
            () => stopLoader('get customer')
        );
    };

    const handleCustomersSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getCustomerOptions(data.data);
            setCustomers(options);
            if (!selectedCustomer.value) setSelectedCustomer(options[0]);
        }
    };

    const fetchData = () => {
        if (selectedAdmin.value && selectedCustomer.value && selectedRegulation.value) {
            const params = {
                limit: LIMIT,
                skip: skip,
                market: selectedRegulation.value,
                bidDate: moment(date).format('YYYY-MM-DD'),
                adminId: selectedAdmin.value,
                customerId: selectedCustomer.value,
            };

            BiddingService.GetBids(
                params,
                () => startLoader('get bids'),
                handleBidsSuccess,
                handleError,
                () => stopLoader('get bids')
            );
        }
    };

    const handleBidsSuccess = ({ data }) => {
        if (Array.isArray(data.data.data)) {
            const processedData = data.data.data.map((item) => {
                item.action = (
                    <div className={classes.ActionContainer}>
                        {!item.isBidPresent ? (
                            <CustomTooltip content={'Create Bid'}>
                                <div
                                    className={classes.Action}
                                    onClick={() => {
                                        setCreateBid({
                                            status: true,
                                            meta: {
                                                customerId: item.customerId,
                                                group: {
                                                    label: item.instance,
                                                    value: item.instanceId,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    <div>
                                        <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        ) : (
                            <>
                                <CustomTooltip content={'View'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setInfoData({ status: true, meta: { bid: item.bid } });
                                        }}
                                    >
                                        <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </CustomTooltip>

                                {!item.isReverseBidPresent ? (
                                    <>
                                        <CustomTooltip content={'Download'}>
                                            <div
                                                className={classes.Action}
                                                onClick={() => {
                                                    handleBidDataDownload(item.bid);
                                                }}
                                            >
                                                <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </CustomTooltip>
                                        {selectedRegulation.value === 'FCR' && (
                                            <CustomTooltip content={'Bid'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        handlePostBid(item.bid._id);
                                                    }}
                                                >
                                                    <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                        {['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value) && (
                                            <CustomTooltip content={'D-1 Bid'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        setCreateD1Bid({
                                                            status: true,
                                                            meta: {
                                                                bid: item.bid,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <UilSync size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                        <CustomTooltip content={'Fetch Status'}>
                                            <div
                                                className={classes.Action}
                                                onClick={() => {
                                                    handleFetchStatus(item.bid._id);
                                                }}
                                            >
                                                <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </CustomTooltip>
                                        {['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(selectedRegulation.value) && (
                                            <CustomTooltip content={'Result ID'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        setResultId({
                                                            status: true,
                                                            meta: {
                                                                bid: item.bid,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <UilKeyboardShow size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}

                                {!item.bid?.bidStatus.length || item.isReverseBidPresent ? (
                                    <CustomTooltip content={'Delete'}>
                                        <div
                                            className={classes.Action}
                                            onClick={() => {
                                                setDeleteBId({
                                                    status: true,
                                                    id: item.bid._id,
                                                });
                                            }}
                                        >
                                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </CustomTooltip>
                                ) : (
                                    <CustomTooltip content={'Reverse Bid'}>
                                        <div
                                            className={classes.Action}
                                            onClick={() => {
                                                setInfoData({ status: true, meta: { bid: item.bid, openReverseBid: true } });
                                            }}
                                        >
                                            <div>
                                                <UilHistoryAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </div>
                                    </CustomTooltip>
                                )}
                            </>
                        )}
                    </div>
                );
                if (!item.isBidPresent) {
                    item.style = { background: '#FFE3E3', color: '#E25657' };
                }
                if (item.isReverseBidPresent) {
                    item.style = { background: '#fef6e5', color: '#faa500' };
                }
                return item;
            });
            setBidsData(processedData);
        }
        setTotalCount(data.data.totalCount);
    };

    const fetchRegulations = () => {
        regulationsService.ReadAll(
            () => startLoader('load regulations'),
            handleRegulationSuccess,
            handleError,
            () => stopLoader('load regulations')
        );
    };

    const handleRegulationSuccess = ({ data }) => {
        if (Array.isArray(data.data) && data.data.length) {
            const processedReguations = data.data.map((r) => ({
                label: r.name,
                value: r.name,
            }));
            setRegulations(processedReguations);
            if (!selectedRegulation.value) setSelectedRegulation(processedReguations[0]);
        }
    };

    const handleDelete = () => {
        let market = selectedRegulation.value;
        if (market === 'FCR-D-DEC') market = 'FCR-D-MINUS';
        if (market === 'FCR-D-INC') market = 'FCR-D';

        BiddingService.Delete(
            String(market).toLowerCase(),
            deleteBid.id,
            () => startLoader('delete bid'),
            handleDeleteSuccess,
            handleError,
            () => stopLoader('delete bid')
        );
    };

    const handleDeleteSuccess = (res) => {
        fetchData();
        toast.success('Bid Deleted');
    };

    const handlePostBid = (id) => {
        let market = selectedRegulation.value;
        if (market === 'FCR-D-DEC') market = 'FCR-D-MINUS';
        if (market === 'FCR-D-INC') market = 'FCR-D';

        BiddingService.PostBid(
            String(market).toLowerCase(),
            id,
            {},
            {},
            () => startLoader('post bid'),
            handlePostSuccess,
            handleError,
            () => stopLoader('post bid')
        );
    };

    const handlePostSuccess = ({ data }) => {
        fetchData();
        setStatusData({ status: true, meta: { data: data.data } });
        toast.success('Bid Posted to External Service');
    };

    const handleFetchStatus = (id) => {
        let market = selectedRegulation.value;
        if (market === 'FCR-D-DEC') market = 'FCR-D-MINUS';
        if (market === 'FCR-D-INC') market = 'FCR-D';

        BiddingService.CheckBidStatus(
            String(market).toLowerCase(),
            id,
            () => startLoader('status bid'),
            handleStatusSuccess,
            handleError,
            () => stopLoader('status bid')
        );
    };

    const handleStatusSuccess = ({ data }) => {
        fetchData();
        setStatusData({ status: true, meta: { data: data.data } });
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleDownloadHourDataFCRD = (item) => {
        let totalDMinus2Price = 0;
        let totalDMinus1Price = 0;
        let totalPrice = 0;

        const data = Array(24)
            .fill(null)
            .map((i, index) => {
                const dMinus2price = item.bidStatusDMinus2[index]?.settlement;
                const dMinus1price = item.bidStatusDMinus1[index]?.settlement;
                const hourPrice = item.bidStatus[index]?.price;

                if (dMinus2price >= 0) totalDMinus2Price += dMinus2price;
                if (dMinus1price >= 0) totalDMinus1Price += dMinus1price;
                if (hourPrice >= 0) totalPrice += hourPrice;

                return {
                    hour: index,
                    capacityDMinus2: item.capacity[index] >= 0 && !item.dMinus2BidNotAvailable ? item.capacity[index] : '--',
                    priceDMinus2: item.price[index] >= 0 && !item.dMinus2BidNotAvailable ? item.price[index] : '--',
                    settlementDMinus2: dMinus2price >= 0 ? dMinus2price : '--',
                    capacityDMinus1: item.capacityDMinus1[index] >= 0 ? item.capacityDMinus1[index] : '--',
                    priceDMinus1: item.priceDMinus1[index] >= 0 ? item.priceDMinus1[index] : '--',
                    settlementDMinus1: dMinus1price >= 0 ? dMinus1price : '--',
                    hourcost: hourPrice >= 0 ? hourPrice : '--',
                };
            });

        data.push({
            hour: 'Total',
            capacityDMinus2: '',
            priceDMinus2: '',
            settlementDMinus2: totalDMinus2Price,
            capacityDMinus1: '',
            priceDMinus1: '',
            settlementDMinus1: totalDMinus1Price,
            hourcost: totalPrice,
        });

        DownloadAsExcel(data, `${selectedRegulation.value}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, [
            'Hour',
            'Capacity (MW) (D-2)',
            'Price (€) (D-2)',
            'Settlement (€) (D-2)',
            'Capacity (MW) (D-1)',
            'Pricem (€) (D-1)',
            'Selttlement (€) (D-1)',
            'Settlement (€) ( D-2 & D-1)',
        ]);
    };

    const handleDownloadHourData = (item) => {
        const data = item.capacity.map((i, index) => ({
            hour: index,
            capacity: i,
            price: item.price[index],
            hourcost: item?.bidStatus.length <= 0 ? '--' : index === 0 ? item?.bidStatus[index]?.price : (index + 1) % 4 === 0 ? item?.bidStatus[(index + 1) / 4]?.price : '--',
        }));
        DownloadAsExcel(data, `${selectedRegulation.value}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
    };

    const handleBidDataDownload = (bid) => {
        if (['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value)) {
            handleDownloadHourDataFCRD(bid);
        } else {
            handleDownloadHourData(bid);
        }
    };

    const setCreateBidHandler = () => {
        setCreateBid({ status: false, meta: { customerId: null, group: {} } });
    };

    const setCreateD1BidHandler = () => {
        setCreateD1Bid({ status: false, meta: { bid: {} } });
    };

    const setResultIdHandler = () => {
        setResultId({ status: false, meta: { bid: {} } });
    };

    const setDeleteBidHandler = () => {
        setDeleteBId({ status: false, id: null });
    };

    const setStatausDataHandler = () => {
        setStatusData({ status: false, meta: { data: [] } });
    };

    const setInfoDataHandler = () => {
        setInfoData({ status: false, meta: { bid: {}, openReverseBid: false } });
    };

    const setExportBidHandler = () => {
        setExportBid({ status: false });
    };

    return (
        <div>
            <div className={FilterBarClasses.ParentWrapper}>
                <div className={FilterBarClasses.Left}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Admin</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedAdmin}
                                options={admins}
                                onChange={(e) => {
                                    setSelectedAdmin(e);
                                    updateQueryParams('admin', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Customer</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedCustomer}
                                options={customers}
                                onChange={(e) => {
                                    setSelectedCustomer(e);
                                    updateQueryParams('customer', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Market</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedRegulation}
                                options={regulations}
                                onChange={(e) => {
                                    setSelectedRegulation(e);
                                    updateQueryParams('regulation', e.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={FilterBarClasses.Right}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Date</label>
                        <DatePicker
                            date={date}
                            setDate={(d) => {
                                setDate(d);
                                updateQueryParams('bidDate', d);
                            }}
                            showArrow={true}
                        />
                    </div>
                    <button
                        className="btn-primary"
                        style={{
                            marginTop: '0',
                            width: 'auto',
                        }}
                        onClick={fetchData}
                    >
                        Submit
                    </button>
                    <button
                        className="btn-primary"
                        style={{
                            marginTop: '0',
                            width: 'auto',
                        }}
                        onClick={() => setBulkBidModal({ status: true })}
                    >
                        Create Bulk Bid
                    </button>
                    {['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value) && (
                        <DownloadButton
                            size={'medium'}
                            onClick={() => {
                                setExportBid({ status: true });
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.Bids}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Master Bidding" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount} />
                            </span>
                        </div>
                    </div>
                </div>
                <Table
                    head={['Customer Name', 'License', 'Instance', 'Avg Capacity', 'Customer (€)', 'Partner (€)', 'SC (€)', 'Enablers (€)', 'Revenue (€)', 'Status', 'Action']}
                    keys={['customerName', 'license', 'instance', 'averageCapacity', 'customer', 'partner', 'sc', 'enablers', 'revenue', 'bid.status', 'action']}
                    data={bidsData}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
            <ModalComponent isOpen={bulkBidModal.status} setOpen={() => {}}>
                <BulkBid adminId={selectedAdmin.value} customerId={selectedCustomer.value} market={selectedRegulation.value} bidDate={date} setBulkBidModal={setBulkBidModal} />
            </ModalComponent>
            <ModalComponent isOpen={createBid.status} setOpen={setCreateBidHandler}>
                <CreateBid
                    bidDate={date}
                    customerId={createBid.meta.customerId}
                    fetchBids={fetchData}
                    group={createBid.meta.group}
                    market={selectedRegulation.value}
                    setOpen={setCreateBidHandler}
                />
            </ModalComponent>
            <ModalComponent isOpen={createD1Bid.status} setOpen={setCreateD1BidHandler}>
                <CreateD1Bid bid={createD1Bid.meta.bid} fetchBids={fetchData} market={selectedRegulation.value} setOpen={setCreateD1BidHandler} />
            </ModalComponent>
            <ModalComponent isOpen={resultId.status} setOpen={setResultIdHandler}>
                <ResultId bid={resultId.meta.bid} fetchBids={fetchData} market={selectedRegulation.value} setOpen={setResultIdHandler} />
            </ModalComponent>
            <ModalComponent isOpen={statusData.status} setOpen={setStatausDataHandler}>
                <StatusData data={statusData.meta.data} market={selectedRegulation.value} setOpen={setStatausDataHandler} />
            </ModalComponent>
            <ModalComponent isOpen={infoData.status} setOpen={setInfoDataHandler}>
                <BidInfo
                    bid={infoData.meta.bid}
                    openReverseBid={infoData.meta.openReverseBid}
                    fetchBids={fetchData}
                    market={selectedRegulation.value}
                    setOpen={setInfoDataHandler}
                />
            </ModalComponent>
            <ModalComponent isOpen={exportBid.status} setOpen={setExportBidHandler}>
                <ExportBid setOpen={setExportBidHandler} />
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteBid.status} setOpenDeleteModal={setDeleteBidHandler}></DeleteModal>
        </div>
    );
};
