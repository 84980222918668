import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';
const PATH = {
    data: '/admin/data/ffr',
    getFcrBid: '/ffr',
    getLastFcrBid: '/admin/data/ffr/last',
    postBid: '/admin/data/ffr/createBid',
    checkBidStatus: '/admin/data/ffr/checkbid',
    addResultId: '/admin/data/ffr/addResultId',
    createReverseBid: '/admin/data/ffr/reverse',
    marketEnabledGroups: 'admin/data/FFR/market-enabled-groups',
    bidRequest: '/admin/data/bid-request/FFR',
    forceActivation: '/admin/data/force-activation',
    instanceDetails: '/admin/data/intance-details',
    maintenanceHours: '/admin/data/maintenance-hours/FFR',
    deleteMaintenanceHours: '/admin/data/maintenance-hours',
    bidsExport: '/admin/data/bids/export',
    updateForceFactor: '/admin/data/force-factor',
    totalCapacity: '/admin/data/bids/total-capacity',
};
export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};
export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.data}`, payload, { params }).then(callback).catch(error).finally(next);
};

const PostBid = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
const FfrBidData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.data, { params }).then(callback).catch(error).finally(next);
};

const GetLastFfrBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastFcrBid}`, { params }).then(callback).catch(error).finally(next);
};
const CheckBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (_id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.data}?_id=${_id}`).then(callback).catch(error).finally(next);
};

const AddResultId = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.addResultId}/${id}`, data).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}`).then(callback).catch(error).finally(next);
};

const ForceActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.forceActivation}`, params).then(callback).catch(error).finally(next);
};

const GetInstanceDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceDetails}/${id}`).then(callback).catch(error).finally(next);
};

const GetBidRequestForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetBidRequests = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHours = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteMaintenanceHours = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteMaintenanceHours}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHoursForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const BidsExport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidsExport}`, { params }).then(callback).catch(error).finally(next);
};

const CreateReverseBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createReverseBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateForceFactor = (payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateForceFactor}`, payload).then(callback).catch(error).finally(next);
};

const BidCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.totalCapacity}`, { params }).then(callback).catch(error).finally(next);
};

export const FfrBidService = {
    FfrBidData,
    Create,
    Delete,
    GetLastFfrBid,
    PostBid,
    CheckBidStatus,
    AddResultId,
    GetMarketEnabledGroups,
    GetBidRequestForADate,
    GetBidRequests,
    ForceActivation,
    GetInstanceDetails,
    GetMaintenanceHours,
    DeleteMaintenanceHours,
    GetMaintenanceHoursForADate,
    BidsExport,
    CreateReverseBid,
    UpdateForceFactor,
    BidCapacity,
};
