import React from 'react';
import { useLoader } from '../../../../hooks';
import BidContentFCRD from '../../../Bidding/FCR-D/Bids/ModalContent/BidContent';
import BidContentFCRDDEC from '../../../Bidding/FCR-DMinus/Bids/ModalContent/BidContent';
import BidContentFFR from '../../../Bidding/FFR/Bids/ModalContent/BidContent';
import BidContentFCR from '../../../Bidding/FCR/Bids/ModalContent/BidContent';

export const CreateBid = ({ market, bidDate, customerId, fetchBids, group, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const renderComponent = () => {
        switch (market) {
            case 'FCR-D-INC':
                return (
                    <BidContentFCRD
                        bidDate={bidDate}
                        customerId={customerId}
                        fetchBids={fetchBids}
                        group={group}
                        setOpen={setOpen}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        updateQueryParams={() => {}}
                    />
                );
            case 'FCR-D-DEC':
                return (
                    <BidContentFCRDDEC
                        bidDate={bidDate}
                        customerId={customerId}
                        fetchBids={fetchBids}
                        group={group}
                        setOpen={setOpen}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        updateQueryParams={() => {}}
                    />
                );
            case 'FFR':
                return (
                    <BidContentFFR
                        bidDate={bidDate}
                        customerId={customerId}
                        fetchBids={fetchBids}
                        group={group}
                        setOpen={setOpen}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        updateQueryParams={() => {}}
                    />
                );

            case 'FCR':
                return (
                    <BidContentFCR
                        bidDate={bidDate}
                        customerId={customerId}
                        fetchBids={fetchBids}
                        group={group}
                        setOpen={setOpen}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        updateQueryParams={() => {}}
                    />
                );

            default:
                return <>Bid Content Not Found</>;
        }
    };
    return <div>{renderComponent()}</div>;
};
