// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilInfoCircle, UilPen, UilEuro, UilBan } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../../../../components/Table/Table';
import classes from '../../../../../../styles/Alerts.module.css';
import myclasses from '../../../../../../styles/Overview.module.css';
import Typography from '../../../../../../components/Typography/Typography';
import ModalComponent from '../../../../../../components/ModalComponent/ModalComponent';
import ChargerModel from './ChargerModel';
import { MobilepayService } from '../../../../../../services/mobilepayService';
import DeleteModal from '../../../../../../components/DeleteModal/DeleteModal';
import EditModal from './EditModal';
import CustomTooltip from '../../../../../../components/CustomToolTip/CustomTooltip';

const ChargeComponent = ({ license, startLoader, stopLoader }) => {
    const options = license.groups
        ? license.groups.map((group) => {
              return { label: group.name, value: group._id };
          })
        : [{ label: '', value: '' }];
    const [detailsModal, setDetailsModal] = useState(false);
    const [chargerId, setChargerId] = useState(null);
    const [chargerData, setChargerData] = useState(null);
    const [refresh, setRefresh] = useState(options[0]);
    const [data, setData] = useState([]);
    const [transactionData, setTransactionData] = useState({});
    const [transactionModal, setTransactionModal] = useState(false);
    const [cancelnModal, setCancelModal] = useState({ status: false, id: '' });
    const [editModal, setEditModal] = useState({ status: false, data: '' });
    const [captureAmountModal, setCaptureAmountModal] = useState({
        status: false,
        amount: '',
        id: '',
    });

    useEffect(() => {
        fetchAllTransactionData(chargerId);
    }, [chargerId, refresh]);

    const fetchAllTransactionData = (id = null) => {
        let params = {};
        if (id) {
            params.id = id;
        }
        MobilepayService.ReadAll(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const handleGetDataSuccess = ({ data }) => {
        let processedData = [];
        data.data.map((item) => {
            processedData.push({
                ...item,
                properties: {
                    ...item?.properties,
                    transactionId: (
                        <>
                            <div className={item?.properties?.transactionId ? 'link' : ''}>{item?.properties?.transactionId || '--'}</div>
                        </>
                    ),
                },
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CustomTooltip content={'Info'}>
                            <UilInfoCircle
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setDetailsModal(true);
                                    let preprocessObject = {
                                        ...item,
                                        properties: {
                                            ...item?.properties,
                                            loyaltyIds: '--',
                                            restrictions: '--',
                                        },
                                    };
                                    setChargerData(preprocessObject);
                                }}
                            />
                        </CustomTooltip>
                        <CustomTooltip content={'Cancel'}>
                            <UilBan
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setCancelModal({
                                        status: true,
                                        id: item?.properties?.paymentId,
                                    })
                                }
                            />
                        </CustomTooltip>
                        <CustomTooltip content={'Capture Amount'}>
                            <UilEuro
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setCaptureAmountModal({
                                        status: true,
                                        amount: '',
                                        id: item?.properties?.paymentId,
                                    })
                                }
                            />
                        </CustomTooltip>
                        <CustomTooltip content={'Edit'}>
                            <UilPen
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setEditModal({
                                        status: true,
                                        data: item,
                                    })
                                }
                            />
                        </CustomTooltip>
                    </div>
                ),
            });
        });

        setData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleCancel = () => {
        const params = {
            id: cancelnModal.id,
        };
        MobilepayService.CancelPayment(params, startLoader, handleCancelSuccess, handleError, stopLoader);
    };

    const handleCancelSuccess = (res) => {
        if (res) {
            setCancelModal({ status: false, id: '' });
            toast.success('Payment cancelled successfully!');
        }
    };

    const handleUpdate = () => {
        MobilepayService.CapturePayment({ paymentId: captureAmountModal.id }, { amount: captureAmountModal.amount }, startLoader, handleUpdateSuccess, handleError, stopLoader);
    };
    const handleUpdateSuccess = (res) => {
        if (res) {
            setCaptureAmountModal({ amount: '', id: '', status: false });
            toast.success('Transaction captured sucessfully');
        }
    };

    return (
        <div>
            <ModalComponent isOpen={detailsModal} setOpen={setDetailsModal}>
                <ChargerModel setOpen={setDetailsModal} data={chargerData} text="Mobile Pay Transaction Details" />
            </ModalComponent>
            <ModalComponent isOpen={captureAmountModal.status} setOpen={(status) => setCaptureAmountModal({ ...captureAmountModal, status })}>
                <div>
                    <Typography content={'Capture Amount'} />
                    <div className={classes.FieldControl}>
                        <label for="amount">Amount</label>
                        <div className={'inputStyle2'}>
                            <input
                                placeholder="Amount"
                                style={{ width: '10vw !important' }}
                                type="text"
                                name="chargerId"
                                id=""
                                value={chargerId}
                                onChange={(e) => {
                                    setCaptureAmountModal({
                                        ...captureAmountModal,
                                        amount: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="submit" className="btn-primary" onClick={handleUpdate}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
            <DeleteModal
                deletefunction={handleCancel}
                opendeleteModal={cancelnModal.status}
                setOpenDeleteModal={(status) => setCancelModal({ ...cancelnModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={transactionModal} setOpen={setTransactionModal}>
                <ChargerModel
                    setOpen={setTransactionModal}
                    data={{
                        properties: {
                            ...transactionData,
                        },
                    }}
                    text="Transaction Details"
                />
            </ModalComponent>
            <ModalComponent isOpen={editModal.status} setOpen={(status) => setEditModal({ ...editModal, status })}>
                <EditModal editData={editModal.data} setCreateModal={setEditModal} setRefresh={setRefresh} />
            </ModalComponent>

            <div className={myclasses.Header}>
                <div>
                    <Typography content={'Mobile Pay Transactions'} />
                </div>
                <div className={classes.ButtonsContainer}>
                    <div className={'inputStyle2'}>
                        <input
                            placeholder="Search by charger ID"
                            style={{ width: '10vw !important' }}
                            type="text"
                            name="chargerId"
                            id=""
                            value={chargerId}
                            onChange={(e) => {
                                setChargerId(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Table
                head={['Charger ID', 'Status', 'Amount', 'Transaction ID', 'Transaction Request', 'Transaction Status', 'Captured Amount', 'Created At', 'Action']}
                keys={[
                    'properties.chargerId',
                    'properties.status',
                    'properties.amount',
                    'properties.transactionId',
                    'properties.transactionRequest',
                    'properties.transactionStatus',
                    'properties.capturedAmount',
                    'createdAt',
                    'action',
                ]}
                data={data}
            />
        </div>
    );
};

export default ChargeComponent;
