import classes from '../../../../../styles/BidModal.module.css';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Input } from '../../../../../components/Inputs/Input';
import Typography from '../../../../../components/Typography/Typography';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { CreateFfrBidValidation } from '../../../../../validations/Frequency/CreateBidValidation';
import { FfrBidService } from '../../../../../services/FfrBidService';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { momentTimeFormater } from '../../../../../utils/dateHelper';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const getSlotAndPriceInitials = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = '0.9';
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = '14';
    });
    return slotAndPrice;
};

const BidContent = ({ setOpen, startLoader, stopLoader, fetchBids, customerId, bidDate, group, updateQueryParams }) => {
    const [initialValues, setInitialValues] = useState({
        slotAndPrice: getSlotAndPriceInitials(),
        groupId: group.label,
        reserveBidIdentification: '',
    });
    const [date, setDate] = useState(bidDate ? new Date(moment(bidDate).format('YYYY-MM-DD')) : new Date());
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);

    useEffect(() => {
        updateQueryParams('bidDate', date);
    }, [date]);

    useEffect(() => {
        if (customerId) {
            FfrBidService.GetLastFfrBid({ customerId, groupId: group.value }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    }, []);

    useEffect(() => {
        if (customerId) {
            fetchMaintenanceHourForADate();
        }
    }, [date]);

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity, date, price, reserveBidIdentification } = data.data;
        if (capacity?.length && price?.length) {
            const slotAndPrice = {};
            capacity.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}`] = item;
            });
            price.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}Price`] = item;
            });
            setInitialValues({
                ...initialValues,
                slotAndPrice,
                reserveBidIdentification,
            });
        } else {
            setInitialValues({
                slotAndPrice: getSlotAndPriceInitials(),
                groupId: group.label,
                reserveBidIdentification: '',
            });
        }
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours() + 25);
        if (!bidDate) {
            setDate(tempDate);
        }
    };

    const handleSubmit = (values) => {
        let params = {
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            customerId,
        };
        values.groupId = group.value;
        let payload = { fcrBid: values };
        FfrBidService.Create(params, payload, startLoader, (res) => handleCreateSuccess(res), handleError, stopLoader);
    };

    const handleCreateSuccess = (res) => {
        toast.success('Bid Created!');
        const currentDate = moment();
        if (group?.approvalValidity?.endDate) {
            if (moment(group?.approvalValidity?.endDate).diff(currentDate, 'days') <= 30) {
                toast.warn(`Approval expires on ${momentTimeFormater(group.approvalValidity.endDate).format('YYYY-MM-DD')}`);
            }
        }
        fetchBids();
        setOpen(false);
    };

    const GetBidRequestForADate = () => {
        FfrBidService.GetBidRequestForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            startLoader,
            handlebidRequestSuccess,
            handleError,
            stopLoader
        );
    };

    const fetchMaintenanceHourForADate = () => {
        FfrBidService.GetMaintenanceHoursForADate(
            {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            },
            group.value,
            () => startLoader('maintenance'),
            handleMaintenanceSuccess,
            handleError,
            () => stopLoader('maintenance')
        );
    };

    const handleMaintenanceSuccess = ({ data }) => {
        if (data.data?.hourSlots) setMaintenanceSlots(data.data?.hourSlots);
        else setMaintenanceSlots([]);
    };

    const handlebidRequestSuccess = ({ data }) => {
        if (data.data) {
            const { price = [], capacity = [] } = data.data;
            const slotAndPrice = {};
            price.forEach((val, index) => (slotAndPrice[`slot${index + 1}Price`] = val));
            capacity.forEach((val, index) => (slotAndPrice[`slot${index + 1}`] = val));

            setInitialValues({
                ...initialValues,
                slotAndPrice,
            });
        } else {
            toast.error('No request found');
        }
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div>
            <Typography content="FFR Bidding" size="16" />
            <div className={classes.Subtitle} style={{ margin: 0 }}>
                Red-bordered hour slots signify customer-requested maintenance
                <span className="required">*</span>
            </div>
            <div className={classes.FormContainer2}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateFfrBidValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={classes.Subtitle}>Capacity(Mw)</div>
                                    <div>
                                        <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                            Fill from bid request
                                        </button>
                                    </div>
                                </div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}`}>
                                                                Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex}-{index * 4 + subIndex + 1}
                                                                <span className="required">*</span>
                                                            </label>
                                                            <Input
                                                                name={`slotAndPrice.slot${index * 4 + subIndex + 1}`}
                                                                id={`slot${index * 4 + subIndex + 1}`}
                                                                style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Price(€)</div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}Price`}>
                                                                Slot {index * 4 + subIndex + 1} / Hour {index * 4 + subIndex + 1} Price
                                                                <span className="required">*</span>
                                                            </label>
                                                            <Input
                                                                name={`slotAndPrice.slot${index * 4 + subIndex + 1}Price`}
                                                                id={`slot${index * 4 + subIndex + 1}Price`}
                                                                style={maintenanceSlots.includes(index * 4 + subIndex + 1) ? maintenanceSlotCss : {}}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label>
                                            Date <span className="required">*</span>
                                        </label>
                                        <div className="modal-date-picker">
                                            <DatePicker date={date} setDate={setDate} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="reserveBidIdentification">
                                                Reserve Bid Identification
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="groupId">Group</label>
                                            <Input name="groupId" id="groupId" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default BidContent;
