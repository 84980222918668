import classes from '../../../../../styles/AllDevices.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { toast } from 'react-toastify';
import { ToggleButtonWithState } from '../../../../../components/Inputs/Input';
import { metaDataServiceForRoot } from '../../../../../services/metaDataServiceForRoot';
import { useLoader } from '../../../../../hooks';
import { CamelCaseToTextCase } from '../../../../../utils/stringHelper';

function MonitoringAlerts() {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        metaDataServiceForRoot.ReadAll(startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const handleFetchSuccess = ({ data }) => {
        const { properties, _id } = data?.data;
        let tempData = Object.keys(properties?.alert || {}).map((item, i) => ({
            's.no': i + 1,
            alertType: item === 'cfm' || item === 'fcr' ? String(item).toUpperCase() : CamelCaseToTextCase(item),
            action: (
                <div className={classes.FlexAlignCenter}>
                    <ToggleButtonWithState
                        value={properties?.alert[item]}
                        // label={item}
                        name={item}
                        onChange={(e) => handleChange(e.target.checked, item, _id)}
                    />
                </div>
            ),
        }));
        setData(tempData);
    };

    const handleChange = (event, item, id) => {
        let payLoad = {
            alert: {
                [item]: event,
            },
        };
        metaDataServiceForRoot.Update(id, payLoad, startLoader, handleUpdateSuccess, handleError, stopLoader);
    };

    const handleUpdateSuccess = (res) => {
        if (res) {
            toast.success('Status updated!');
            getData();
        }
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };
    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="Monitoring Alerts" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length || 0} />
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Alert Type', 'Action']} keys={['s.no', 'alertType', 'action']} data={data} />
            </div>
        </div>
    );
}

export default MonitoringAlerts;
