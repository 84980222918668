// Standard library imports
import React, { useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// Internal module imports
import Checkbox from '../../../../../components/Inputs/Checkbox/Checkbox';
import { FfrBidService } from '../../../../../services/FfrBidService';
import { useLoader } from '../../../../../hooks';
import { TextArea } from '../../../../../components/Inputs/Input';

// CSS Imports
import classes from '../../../../../styles/CapacityContent.module.css';

const HourlyCost = ({ data, setOpen, fetchBids }) => {
    const [selectReverseBid, setSelectReverseBid] = useState(data?.openReverseBid || false);
    const [startLoader, stopLoader] = useLoader();

    let reverseBid = {};

    const getCheckedBid = (value, productName) => {
        reverseBid = { ...reverseBid, [productName]: value };
    };

    const handleSubmit = (values) => {
        FfrBidService.CreateReverseBid(data?.bidId, { reverseRequest: reverseBid, reverseReason: values.reverseReason }, startLoader, handleSubmitSuccess, handleError, stopLoader);
    };

    const handleSubmitSuccess = () => {
        toast.success('Bid Created!');
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <Formik initialValues={{ reverseReason: data?.reverseReason || '' }} onSubmit={handleSubmit}>
            {({ errors, touched, values, isValidating, ...props }) => (
                <Form>
                    <div className={`${classes.CapacityContent} ${classes.BidInfoModal}`}>
                        <div className={classes.Title}>
                            {data?.bidStatus?.length ? (
                                <div className={classes.ReverseBidBtn}>
                                    {!selectReverseBid ? (
                                        <div>
                                            <button type="button" className="btn-primary" onClick={() => setSelectReverseBid(true)} style={{ marginTop: '0' }}>
                                                Reverse bid
                                            </button>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '0.5vw' }}>
                                            <button type="button" className="btn-primary" onClick={() => setSelectReverseBid(false)} style={{ marginTop: '0' }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }}>
                                                Submit
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={classes.Content}>
                            <div className={classes.ContainerStart}>
                                {[0, 1, 2].map((n) => (
                                    <table>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Price</th>
                                        </tr>
                                        {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                            key <= 23 ? (
                                                <tr>
                                                    <td style={{ display: 'flex', gap: '0.5vw' }}>
                                                        {selectReverseBid && <Checkbox onChange={(e) => getCheckedBid(e, data?.bidStatus?.[key]?.productName)} />}
                                                        {data?.bidStatus?.[key]?.productName}
                                                    </td>
                                                    <td>{data?.bidStatus?.[key]?.price}</td>
                                                </tr>
                                            ) : null
                                        )}
                                    </table>
                                ))}
                            </div>
                        </div>
                        {data?.reverseBid || selectReverseBid ? (
                            <div className={classes.FieldControl2}>
                                <label>Reason</label>
                                <TextArea
                                    name={`reverseReason`}
                                    setFieldValue={props.setFieldValue}
                                    setFieldTouched={props.setFieldTouched}
                                    getFieldMeta={props.getFieldMeta}
                                    disabled={selectReverseBid ? false : true}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default HourlyCost;
