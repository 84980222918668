// Standard library imports
import React from 'react';
// Css imports
import classes from '../../index.module.css';

// Internal module imports
import HourlyCost from './HourlyCost';
import PriceContent from './Price';
import CapacityContent from './Capacity';
import TabsComponent from '../../../../../components/Tabs/Tabs';

const BiddingInfoContent = ({ hourlyModalData, priceModalData, capacityModalData, setOpen, fetchBids }) => {
    let tabs = [
        {
            name: 'Settlement',
            component: (
                <>
                    <HourlyCost data={hourlyModalData} setOpen={setOpen} fetchBids={fetchBids} />
                </>
            ),
        },
        {
            name: 'Bidding Price',
            component: (
                <>
                    <PriceContent data={priceModalData} />
                </>
            ),
        },
        {
            name: 'Bidding Capacity',
            component: (
                <>
                    <CapacityContent capacity={capacityModalData} />
                </>
            ),
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'} style={{ display: 'flex' }}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default BiddingInfoContent;
