import React, { useEffect, useState } from 'react';
import classes from '../../../../styles/CapacityContent.module.css';
import { useLoader } from '../../../../hooks';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { toast } from 'react-toastify';
import bundleClasses from '../index.module.css';
import Typography from '../../../../components/Typography/Typography';
import { truncateNumber } from '../../../../utils/numberHelper';

const CapacityContent = ({ capacity = {} }) => {
    const [startLoader, stopLoader] = useLoader();
    const [instanceIds, setInstanceIds] = useState(Object.keys(capacity));
    const [data, setData] = useState([]);
    const [overallCapacity, setOverallCapacity] = useState([]);

    useEffect(() => {
        if (instanceIds.length) {
            getInstanceDetailsByIds();
            getSumCapacity();
        }
    }, []);

    const getSumCapacity = () => {
        const data = Array(24)
            .fill(null)
            .map((i, index) => {
                let sum = 0;
                for (let key in capacity) {
                    sum += Number(capacity[key]?.[index]) || 0;
                }
                return truncateNumber(sum, 3);
            });
        setOverallCapacity(data);
    };

    const getInstanceDetailsByIds = () => {
        const params = {
            instanceIds,
        };
        BundleBiddingService.GetInstanceDetailsByIds(params, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleInstanceSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            name: item.name,
            capacity: capacity[item._id],
        }));
        setData(temp);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={`${classes.CapacityContent} ${bundleClasses.CapacityContainer}`}>
            <div>
                <div style={{ marginLeft: '1.5vw', marginTop: '2vh' }}>
                    <Typography content={'Overall Capacity'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Capacity</th>
                                </tr>
                                {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{overallCapacity[key]}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>
            {data.map((item, index) => (
                <div key={index}>
                    <div style={{ marginLeft: '1.5vw', marginTop: '2vh' }}>
                        <Typography content={item.name} size={14} />
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.ContainerStart}>
                            {[0, 1, 2].map((n) => (
                                <table>
                                    <tr>
                                        <th>Slot</th>
                                        <th>Hour</th>
                                        <th>Capacity</th>
                                    </tr>
                                    {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                        key <= 23 ? (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>
                                                    {key}-{key + 1}
                                                </td>
                                                <td>{item.capacity[key]}</td>
                                            </tr>
                                        ) : null
                                    )}
                                </table>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CapacityContent;
