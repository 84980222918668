import React from 'react';
import { Form, Formik } from 'formik';
import Typography from '../../../../../components/Typography/Typography';
import classes from '../../../../../styles/BidModal.module.css';
import { getJustNumbers } from '../../../../../utils/stringHelper';
import { Input, ToggleButtonWithState } from '../../../../../components/Inputs/Input';
import { FcrDDMinusOneBidValidation } from '../../../../../validations/Frequency/CreateBidValidation';
import { FcrDBidService } from '../../../../../services/FcrDBidService';
import { toast } from 'react-toastify';
import { useState } from 'react';

const getInitialValues = (bid) => {
    const values = {};
    if (bid.capacityDMinus1?.length) {
        Array(24)
            .fill(null)
            .forEach((i, index) => {
                values[`slot${index + 1}`] = bid?.capacityDMinus1[index] || '';
                values[`slot${index + 1}Price`] = bid?.priceDMinus1[index] || '';
            });
    } else if (bid.dMinus2BidNotAvailable) {
        Array(24)
            .fill(null)
            .forEach((i, index) => {
                values[`slot${index + 1}`] = bid?.capacity[index] || '';
                values[`slot${index + 1}Price`] = bid?.price[index] || '';
            });
    } else if (bid.bidStatusDMinus2) {
        bid.bidStatusDMinus2.forEach((hourData, index) => {
            if (hourData.price === 0) {
                values[`slot${index + 1}`] = bid?.capacityDMinus1[index] || bid.capacity[index];
                values[`slot${index + 1}Price`] = bid?.price[index] || '';
            }
        });
    }
    return values;
};

const getPriceCapacity = (initialValues) => {
    const capacity = [];
    const price = [];
    Object.keys(initialValues).forEach((key) => {
        const slot = getJustNumbers(key);
        if (key.includes('Price')) price.push({ slot, key });
        else capacity.push({ slot, key });
    });
    return { capacity, price };
};

const DMinusOne = ({ bid, startLoader, stopLoader, setOpen, fetchBids }) => {
    const [initialValues, setInitialValues] = useState(getInitialValues(bid));
    const [{ capacity, price }, setPriceCapacityValues] = useState(getPriceCapacity(initialValues));
    const [dMinus2BidNotAvailable, setDMinus2BidNotAvailable] = useState(bid.dMinus2BidNotAvailable || false);

    const handleSubmit = (values) => {
        values.dMinus2BidNotAvailable = dMinus2BidNotAvailable;
        if (bid._id) {
            FcrDBidService.UpdateDMinusOne(bid._id, values, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ res }) => {
        toast.success('Bid Updated!');
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleDMinus2NotAvailableChange = async (e) => {
        setDMinus2BidNotAvailable(e.target.checked);
        if (e.target.checked) {
            FcrDBidService.GetLastBid({ customerId: bid.customerId, groupId: bid.instanceId }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    };

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity: lastCapacity, price: lastPrice } = data.data;
        const curInitialValues = {};
        const curPrice = [];
        const curCapacity = [];
        Array(24)
            .fill(null)
            .forEach((i, index) => {
                const capKey = `slot${index + 1}`;
                const priceKey = `slot${index + 1}Price`;
                curInitialValues[capKey] = lastCapacity[index] || '';
                curInitialValues[priceKey] = lastPrice[index] || '';
                curPrice.push({ slot: index + 1, key: priceKey });
                curCapacity.push({ slot: index + 1, key: capKey });
            });
        setInitialValues(curInitialValues);
        setPriceCapacityValues({ capacity: curCapacity, price: curPrice });
    };

    return (
        <div>
            <Typography content={`FCR-D-INC D-1 Bid ( ${bid.cetDate} )`} size="16" />
            <div className={classes.FormContainer2}>
                {price.length > 0 || dMinus2BidNotAvailable ? (
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={FcrDDMinusOneBidValidation(initialValues)}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div>
                                    <div className={classes.Subtitle}>Capacity(Mw)</div>
                                    {Array(Math.ceil(capacity.length / 4))
                                        .fill(null)
                                        .map((item, index) => (
                                            <div className={classes.InputContainer} key={index}>
                                                {capacity.slice(index * 4, index * 4 + 4).map((subItem, subIndex) => (
                                                    <div key={subIndex}>
                                                        <div className={classes.FieldControl}>
                                                            <label htmlFor={subItem.key}>Slot {subItem.slot}</label>
                                                            <Input name={subItem.key} id={subItem.key} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                </div>
                                <div>
                                    <div className={classes.Subtitle}>Price(€)</div>
                                    {Array(Math.ceil(price.length / 4))
                                        .fill(null)
                                        .map((item, index) => (
                                            <div className={classes.InputContainer} key={index}>
                                                {price.slice(index * 4, index * 4 + 4).map((subItem, subIndex) => (
                                                    <div key={subIndex}>
                                                        <div className={classes.FieldControl}>
                                                            <label htmlFor={subItem.key}>Slot {subItem.slot} Price</label>
                                                            <Input name={subItem.key} id={subItem.key} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <span>Looks like result for D-2 bid are not announced yet.</span>
                        <div>OR</div>
                        <span>Result were in favour for all hours.</span>
                        <div>OR</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ToggleButtonWithState value={dMinus2BidNotAvailable} onChange={handleDMinus2NotAvailableChange} label="D-2 Not Available" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DMinusOne;
