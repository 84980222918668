// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/apikey',
    read_all: '/admin/apikey',
    read: '/admin/apikey',
    update: '/admin/apikey',
    delete: '/admin/apikey',
    getApiKeyForCustomer: '/admin/apikey',
    getAllApiKeyForCustomer: '/admin/apikey/customer',
    root_create: '/root/apikey',
    root_read_all: '/root/apikey',
    root_read: '/root/apikey',
    root_update: '/root/apikey',
    root_delete: '/root/apikey',
};

//Post call for creating a apikey
const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

//Hit for getting all apikey info from API
const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

//Patch request for updating a apikey (id as a param)
const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

//Delete request for deleting a apikey based on id
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

//get apikey based on customer
const GetApiKeyForCustomer = (customerId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getApiKeyForCustomer}/${customerId}`).then(callback).catch(error).finally(next);
};

//Post call for creating a apikey
const RootCreate = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.root_create, payload).then(callback).catch(error).finally(next);
};

//Hit for getting all apikey info from API
const RootReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.root_read_all).then(callback).catch(error).finally(next);
};

//Patch request for updating a apikey (id as a param)
const RootUpdate = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.root_update}/${id}`, payload).then(callback).catch(error).finally(next);
};

//Delete request for deleting a apikey based on id
const RootDelete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.root_delete}/${id}`).then(callback).catch(error).finally(next);
};

//get all apikey based on customer
const GetAllApiKeyForCustomer = (customerId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllApiKeyForCustomer}/${customerId}`).then(callback).catch(error).finally(next);
};

export const ApikeyService = {
    Create,
    ReadAll,
    Update,
    Delete,
    GetApiKeyForCustomer,
    RootCreate,
    RootReadAll,
    RootUpdate,
    RootDelete,
    GetAllApiKeyForCustomer,
};
